const conferenceTable = {
  state: {
    dates           : [
      '2023-08-24',
      '2023-08-25',
      '2023-08-26',
      '2023-08-27',
    ],
    times           : [],
    chatHidden      : true,
    chatNotification: null,
  },

  mutations: {
    SET_TIMES(state, times) {
      state.times = times
    },
    SET_CHAT_HIDDEN(state, value) {
      state.chatHidden = value
    },
    SET_CHAT_NOTIFICATION(state, profileId) {
      state.chatNotification = profileId
    },
  },

  actions: {
    setTimes({commit, state}) {
      if (state.times.length > 0) {
        return
      }

      let time = 9
      let times = []

      for (let i = 1; i <= 19; i++) {
        const timeStr = String(time).padStart(2, '0')

        if (i % 2 === 0) {
          times.push(`${timeStr}:30`)
          time++
        }
        else {
          times.push(`${timeStr}:00`)
        }
      }

      commit('SET_TIMES', times)
    },

    setChatHidden({commit}, value) {
      commit('SET_CHAT_HIDDEN', value)
    },

    sendChatNotification({commit}, profileId) {
      commit('SET_CHAT_NOTIFICATION', profileId)
    }
  }
};

export default conferenceTable;
