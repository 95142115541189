import {request} from "@/helpers/request";

const controllerPath = 'registration';

export function saveExhibitor(data) {
  return request.post(`/${controllerPath}/save-exhibitor`, data);
}

export function innExists(inn, kpp) {
  return request.post(`/${controllerPath}/inn-exists`, {inn, kpp});
}

export function alfaContactEmailExists(email) {
  return request.post(`/${controllerPath}/alfa-contact-email-exists`, {email});
}

export function createAccountForPaidVisitor(data) {
  return request.post(`/${controllerPath}/create-account-for-paid-visitor`, data);
}