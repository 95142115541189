const data = {};

export function setValue(moduleId, appId, settingId, value) {
	if (!data[moduleId]) {
		data[moduleId] = {};
	}
	if (!data[moduleId][appId]) {
		data[moduleId][appId] = {};
	}

	data[moduleId][appId][settingId] = value;
}

export function getValue(moduleId, appId, settingId) {
	try {
		return data[moduleId][appId][settingId];
	}
	catch (e) {
		console.error("SETTINGS: запрос значения настройки до их загрузки", {moduleId, appId, settingId});
		return undefined;
	}
}

export function setAll(newData) {

	for (const app_id in newData) {
		if (!newData.hasOwnProperty(app_id)) {
			continue;
		}
		const appSettings = newData[app_id];
		for (const module_id in appSettings) {
			if (!appSettings.hasOwnProperty(module_id)) {
				continue;
			}
			const moduleSettings = appSettings[module_id];
			for (const setting_id in moduleSettings) {
				if (!moduleSettings.hasOwnProperty(setting_id)) {
					continue;
				}
				const value = typedValue(moduleSettings[setting_id]);

				setValue(module_id, app_id, setting_id, value);
//				data[module_id][app_id][setting_id] = value;
			}
		}
	}
}


/**
 *
 * @param setting
 * @returns {null|boolean|number|string|*}
 */
function typedValue(setting) {
	if (setting.value === null) {
		return null;
	}

	// Приведение к типу.
	// Делается на фронтенде для совместимости со старой системой типов
	switch(setting.type) {
		case 'integer':
		case 'float':
			return +setting.value;
		case 'boolean':
			return Boolean(+setting.value);
		default:
			return setting.value;
	}
}