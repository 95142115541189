 import Vue from 'vue';

/**
 * Поиск элемента корзина в переданных элементах
 *
 * @param getters
 * @param items     - массив элементов, где будут искать
 * @param item      - искомый элемент
 * @param childItem - если указан для сборных групп, то будет осуществляться поиск с учетом дочернего. Если для орг групп, то будет поиск и вывод дочернего с учетом главного item
 * @param withIndex - вывод с индексом позиции
 * @param noCreate  - не создавать объект, если элемент не найден
 * @returns {{item, index: number}|*|boolean|{childItem, item, childItemIndex: boolean, itemIndex}}
 */
const searchItem = (getters, { items, item, childItem, noCreate, forContract }) => {
	let findItem,
	    findChildItem,
	    findIndex,
	    findChildIndex = false;

	Vue._.each(items, (arrayItem, index) => {
		if (item.id) {
			if (item.id === arrayItem.id) {
				findItem  = arrayItem;
				findIndex = index;

				return false; // break each
			}
		}
		else {
			arrayItem.social_category_id = !arrayItem.social_category_id ? null : arrayItem.social_category_id;

			switch (true) {
				case item.module_id === getters.constants['Global::MODULE__COMMODITY']:
					if (
						arrayItem.module_id == item.module_id &&
						arrayItem.commodity_id == item.commodity_id &&
						arrayItem.warehouse_id == item.warehouse_id
					) {
						if (forContract && arrayItem.contract_id != item.contract_id) {
							return true; // continue each
						}

						findItem  = arrayItem;
						findIndex = index;

						return false;
					}
					break;
				case item.module_id === getters.constants['Global::MODULE__EVENT']:
					if (
						arrayItem.module_id == item.module_id &&
						arrayItem.event_id == item.event_id &&
						arrayItem.social_category_id == item.social_category_id &&
						arrayItem.date == item.date &&
						arrayItem.time == item.time &&
						arrayItem.hall_scheme_seat_id == item.hall_scheme_seat_id &&
						arrayItem.hall_scheme_sector_id == item.hall_scheme_sector_id
					) {
						if (forContract && arrayItem.contract_id != item.contract_id) {
							return true; // continue each
						}

						findItem  = arrayItem;
						findIndex = index;

						return false;
					}
					break;
				case item.module_id === getters.constants['Global::MODULE__SERVICE']:
					if (
						arrayItem.module_id == item.module_id &&
						arrayItem.service_id == item.service_id &&
						arrayItem.social_category_id == item.social_category_id &&
						arrayItem.date == item.date &&
						arrayItem.time == item.time
					) {

						if (forContract && arrayItem.contract_id != item.contract_id) {
							return true; // continue each
						}

						findItem  = arrayItem;
						findIndex = index;

						return false; // break each
					}
					break;
				case item.module_id === getters.constants['Global::MODULE__EXCURSION_DESK']:
					if (
						arrayItem.excursion_id == item.excursion_id &&
						arrayItem.excursion_journal_id == item.excursion_journal_id &&
						arrayItem.excursion_type == item.excursion_type &&
						arrayItem.excursion_age_category_id == item.excursion_age_category_id &&
						arrayItem.excursion_language_id == item.excursion_language_id &&
						arrayItem.date == item.date &&
						arrayItem.time == item.time
					) {
						if (forContract && arrayItem.contract_id != item.contract_id) {
							return true; // continue each
						}

						if (item.excursion_type === getters.constants['ExcursionJournal::TYPE__ORGANIZED']) {
							findItem  = arrayItem;
							findIndex = index;

							return false; // break each
						}

						if (arrayItem.social_category_id != item.social_category_id) {
							return true; // continue each
						}

						if (!childItem && (!arrayItem.child_items || !arrayItem.child_items.length)) {
							findItem  = arrayItem;
							findIndex = index;

							return false; // break each
						}

						if (
							childItem &&
							arrayItem.child_items &&
							arrayItem.child_items.length &&
							arrayItem.child_items[0] &&
							+childItem.event_id === +arrayItem.child_items[0].event_id &&
							childItem.module_id === arrayItem.child_items[0].module_id &&
							childItem.social_category_id == arrayItem.child_items[0].social_category_id
						) {
							findItem       = arrayItem;
							findIndex      = index;
							findChildItem  = arrayItem.child_items[0];
							findChildIndex = 0;

							return false; // break each
						}
					}
					break;

				case item.module_id === getters.constants['Global::MODULE__ABONEMENT']:
					if (
						arrayItem.module_id === item.module_id &&
						arrayItem.abonement_id == item.abonement_id &&
						arrayItem.social_category_id == item.social_category_id
					) {
						if (forContract && arrayItem.contract_id != item.contract_id) {
							return true; // continue each
						}

						findItem  = arrayItem;
						findIndex = index;

						return false; // break each
					}
					break;
			}
		}
	});

	if (findItem) {
		findItem['is_new'] = false;
		if (findChildItem) {
			findChildItem['is_new'] = false;
		}

		if (!Vue._.isEmpty(childItem) && !findChildItem) {
			let fItem = searchItem(getters, { items : findItem.child_items, item : childItem, noCreate });
			if (fItem) {
				findChildItem  = fItem.item;
				findChildIndex = fItem.itemIndex;
			}
		}

		return {
			item           : findItem,
			itemIndex      : findIndex,
			childItem      : findChildItem,
			childItemIndex : findChildIndex
		};
	}

	if (noCreate) {
		return false;
	}

	findItem = createItem(getters, item, forContract);
	if (!Vue._.isEmpty(childItem) && !findChildItem) {
		findChildItem = createItem(getters, childItem, forContract);
	}

	return {
		item           : findItem,
		itemIndex      : -1,
		childItem      : findChildItem,
		childItemIndex : findChildIndex
	};
};

const createItem = (getters, item, forContract) => {
	let createItem = {};

	switch (true) {
		case item.module_id === getters.constants['Global::MODULE__COMMODITY']:
			createItem = {
				module_id               : item.module_id,
				commodity_id            : item.commodity_id,
				warehouse_id            : item.warehouse_id,
				price                   : item.price ? +item.price : 0,
				original_price          : item.original_price ? +item.original_price : 0,
				website_url             : item.website_url || null,
				title                   : item.title || null,
				date_booking_expiration : item.date_booking_expiration || null,
				count                   : 0
			};

			break;
		case item.module_id === getters.constants['Global::MODULE__EVENT']:
			createItem = {
				module_id               : item.module_id,
				parent_id               : item.parent_id,
				event_id                : item.event_id,
				social_category_id      : item.social_category_id || null,
				social_category_title   : item.social_category_title || null,
				date                    : item.date || null,
				time                    : item.time || null,
				price                   : item.price ? +item.price : 0,
				original_price          : item.original_price ? +item.original_price : 0,
				location_title          : item.location_title || null,
				website_url             : item.website_url || null,
				hall_title              : item.hall_title || null,
				title                   : item.title || null,
				hall_scheme_seat_id     : item.hall_scheme_seat_id || null,
				hall_scheme_sector_id   : item.hall_scheme_sector_id || null,
				area_title              : item.area_title || null,
				row_title               : item.row_title || null,
				seat_title              : item.seat_title || null,
				name                    : item.name || null,
				phone                   : item.phone || '',
				comment                 : item.comment || null,
				date_booking_expiration : item.date_booking_expiration || null,
				language_id             : item.language_id || null,
				country_id              : item.country_id || null,
				count                   : 0,
				person_count            : 0
			};

			break;
		case item.module_id === getters.constants['Global::MODULE__SERVICE']:
			createItem = {
				module_id               : item.module_id,
				parent_id               : item.parent_id || null,
				service_id              : item.service_id,
				social_category_id      : item.social_category_id || null,
				social_category_title   : item.social_category_title || null,
				date                    : item.date || null,
				time                    : item.time || null,
				price                   : item.price ? +item.price : 0,
				original_price          : item.original_price ? +item.original_price : 0,
				location_title          : item.location_title || null,
				website_url             : item.website_url || null,
				hall_title              : item.hall_title || null,
				title                   : item.title || null,
				name                    : item.name || null,
				phone                   : item.phone || '',
				comment                 : item.comment || null,
				date_booking_expiration : item.date_booking_expiration || null,
				language_id             : item.language_id || null,
				country_id              : item.country_id || null,
				count                   : 0,
				person_count            : 0
			};
			break;
		case item.module_id === getters.constants['Global::MODULE__EXCURSION_DESK']:
			createItem = {
				module_id                    : item.module_id,
				excursion_id                 : item.excursion_id,
				excursion_journal_id         : item.excursion_journal_id,
				excursion_type               : item.excursion_type,
				excursion_age_category_id    : item.excursion_age_category_id,
				excursion_age_category_title : item.excursion_age_category_title || null,
				excursion_language_id        : item.excursion_language_id,
				excursion_language_title     : item.excursion_language_title,
				social_category_id           : item.social_category_id || null,
				social_category_title        : item.social_category_title || null,
				date                         : item.date,
				time                         : item.time,
				price                        : item.price ? +item.price : 0,
				original_price               : item.original_price ? +item.original_price : 0,
				website_url                  : item.website_url || null,
				location_title               : item.location_title || null,
				hall_title                   : item.hall_title || null,
				title                        : item.title || null,
				name                         : item.name || null,
				phone                        : item.phone || '',
				comment                      : item.comment || null,
				date_booking_expiration      : item.date_booking_expiration || null,
				language_id                  : item.language_id || null,
				country_id                   : item.country_id || null,
				count                        : 0,
				person_count                 : 0,
				child_items                  : []
			};

			break;
		case item.module_id === getters.constants['Global::MODULE__ABONEMENT']:
			createItem = {
				module_id               : item.module_id,
				abonement_id            : item.abonement_id,
				social_category_id      : item.social_category_id || null,
				social_category_title   : item.social_category_title || null,
				price                   : item.price ? +item.price : 0,
				original_price          : item.original_price ? +item.original_price : 0,
				location_title          : item.location_title || null,
				website_url             : item.website_url || null,
				hall_title              : item.hall_title || null,
				title                   : item.title || null,
				name                    : item.name || null,
				phone                   : item.phone || '',
				comment                 : item.comment || null,
				date_booking_expiration : item.date_booking_expiration || null,
				language_id             : item.language_id || null,
				country_id              : item.country_id || null,
				count                   : 0,
				person_count            : 0
			};
			break;
		default:
			return {};
	}

	if (forContract) {
		createItem['contract_id'] = item.contract_id;
	}

	createItem['is_new'] = true;

	return createItem;
};

export default searchItem;