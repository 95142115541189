import {request} from "@/helpers/request";

const controllerPath = 'registration-person';

export function savePerson(data) {
  return request.post(`/${controllerPath}/save-person`, data);
}

export function checkPromoCode(promoCode) {
  return request.post(`/${controllerPath}/check-promo-code`, {promoCode});
}