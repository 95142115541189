import * as API from '@/helpers/request';

const controllerPath = 'country';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data, undefined, 'order');
}

export function getItem(data) {
  return API.getItem(controllerPath, data);
}