import * as API from '@/helpers/request';

const controllerPath = 'site/product-category';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export function getItem(data) {
  return API.getItem(controllerPath, data);
}

export function getOrderBlockList(locale) {
  return API.request.post(controllerPath + '/order-block-list', {locale});
}

export function getAdsOrderBlockList(locale) {
  return API.request.post(controllerPath + '/ads-order-block-list', {locale});
}

export function getPaidVisitorOrderBlockList(locale) {
  return API.request.post(controllerPath + '/paid-visitor-order-block-list', {locale});
}
