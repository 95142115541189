import lodash from 'lodash';

export default {
	install(Vue) {
		Vue._ = lodash;
		Object.defineProperties(Vue.prototype, {
			_ : {
				get() {
					return lodash;
				},
			},
		});
	}
};
