import * as API          from '@/helpers/request';
import { AxiosResponse } from 'axios';

/**
 * Путь к контроллеру
 *
 * @type {string}
 */
let controllerPath = 'user-log';

/**
 * Список всего лога
 *
 * @param filter
 * @param perPage
 * @param page
 * @param sort
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getItems(filter, perPage, page, sort) {
	return API.getItems(controllerPath, filter, perPage, page, sort);
}

/**
 * Список всех контекстов действий
 *
 * @returns {Promise<Response<T>>}
 */
export function getContextList() {
	return API.request.post(`/${controllerPath}/context-list`);
}

/**
 * Список всех контекстов действий
 *
 * @returns {Promise<Response<T>>}
 */
export function getActionList() {
	return API.request.post(`/${controllerPath}/action-list`);
}