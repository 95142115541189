import VueRouter from 'vue-router';
import qs from 'qs';
import {setAuth} from "Global/helpers/localStorage";
import store from "@/store";
import {i18n} from "@/main";

const routes = [
  {
    path     : '/login',
    name     : 'login',
    component: () => import('@/views/login/index')
  },
  {
    path     : '/logout',
    name     : 'logout',
    component: () => {
      if (confirm(i18n.t('logoutConfirm'))) {
        store.dispatch('logout').then(() => {
          location.href = '/login';
        });
      }
    }
  },
  {
    path     : '*',
    component: () => import('@/views/layout/Main'),
    redirect : '/404',
    children : [
      {
        path     : '/404',
        component: () => import('@/views/error/404'),
        name     : 'notFound',
        meta     : {title: 'pageNotFound'}
      }
    ]
  },
  {
    path     : '/person-registration/:category',
    name     : 'personRegistration',
    component: () => import('@/views/registrationPerson/form'),
    meta     : {title: 'registration'}
  },
  {
    path     : '/registration',
    name     : 'registration',
    component: () => import('@/views/registrationForm/form'),
    meta     : {organizationType: 'exhibitor', title: 'exhibitorRegistrationTitle'}
  },
  {
    path     : '/registration-co-exhibitor',
    name     : 'registrationCoExhibitor',
    component: () => import('@/views/registrationForm/form'),
    meta     : {organizationType: 'coExhibitor', title: 'coExhibitorRegistrationTitle'}
  },
  {
    path     : '/registration-absentee-exhibitor',
    name     : 'registrationAbsenteeExhibitor',
    component: () => import('@/views/registrationForm/form'),
    meta     : {organizationType: 'absenteeExhibitor', title: 'absenteeExhibitorRegistrationTitle'}
  },
  {
    path     : '/registration-developer',
    name     : 'registrationDeveloper',
    component: () => import('@/views/registrationForm/form'),
    meta     : {organizationType: 'developer', title: 'developerRegistrationTitle'}
  },
  {
    path     : '/participation-in-business-program',
    name     : 'registrationParticipantB',
    component: () => import('@/views/registrationForm/form'),
    meta     : {organizationType: 'participantB', title: 'participantBRegistrationTitle'}
  },
  {
    path     : '/public-catalog',
    name     : 'publicCatalog',
    component: () => import('@/views/catalog/index'),
  },
  {
    path     : '/buy/:category',
    name     : 'buyOnlineForm',
    component: () => import('@/views/buyOnline/form.vue'),
  },
  {
    path     : '/',
    component: () => import('@/views/layout/Main'),
    redirect : '/orders/index'
  }
];
routes.push({
  path     : '/unsubscribe-error',
  name     : 'unsubscribeError',
  component: () => import('@/views/unsubscribe/error.vue'),
});
routes.push({
  path     : '/unsubscribe-success',
  name     : 'unsubscribeSuccess',
  component: () => import('@/views/unsubscribe/success.vue'),
});


routes.push({
  path     : '/public-business-program',
  component: () => import('@/views/layout/BusinessProgram.vue'),
  children : [
    {
      path     : '',
      name     : 'publicBusinessProgramEvents',
      component: () => import('@/views/businessProgram/index.vue'),
    },
    {
      path     : 'presentations',
      name     : 'publicBusinessProgramEventPresentations',
      component: () => import('@/views/businessProgram/eventPresentations.vue'),
    },
    {
      path     : ':id',
      name     : 'publicBusinessProgramEventDetails',
      component: () => import('@/views/businessProgram/eventDetails.vue'),
    },
  ]
});


routes.push({
  path     : '/buy-badge',
  component: () => import('@/views/layout/BuyOnline.vue'),
  redirect : '/buy-badge/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/buyOnline/index.vue'),
      name     : 'buyOnline',
    },
  ]
});
routes.push({
  path     : '/buy-badge-for-business-days',
  component: () => import('@/views/layout/BuyOnline.vue'),
  redirect : '/buy-badge-for-business-days/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/buyOnline/businessDays.vue'),
      name     : 'buyOnlineBusinessDays',
      meta     : {title: 'buyOnline.businessDaysTitle'}
    },
    {
      path     : 'checkout/:type',
      component: () => import('@/views/buyOnline/businessDaysCheckout.vue'),
      name     : 'buyOnlineBusinessDaysCheckout',
      meta     : {title: 'buyOnline.businessDaysTitle'}
    },
  ]
});
routes.push({
  path     : '/buy-badge-for-open-road-days',
  component: () => import('@/views/layout/BuyOnline.vue'),
  redirect : '/buy-badge-for-open-road-days/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/buyOnline/openRoadDaysIndex.vue'),
      name     : 'buyOnlineOpenRoadDaysIndex',
      meta     : {title: 'buyOnline.openRoadDaysTitle', dynamic: true}
    },
    {
      path     : 'with-dynamic',
      component: () => import('@/views/buyOnline/openRoadDays.vue'),
      name     : 'buyOnlineOpenRoadDays',
      meta     : {title: 'buyOnline.openRoadDaysTitle', dynamic: true}
    },
    {
      path     : 'without-dynamic',
      component: () => import('@/views/buyOnline/openRoadDays.vue'),
      name     : 'buyOnlineOpenRoadDays',
      meta     : {title: 'buyOnline.openRoadDaysTitle', dynamic: false}
    },
    // {
    //   path     : 'checkout/:type',
    //   component: () => import('@/views/buyOnline/openRoadDaysCheckout.vue'),
    //   name     : 'buyOnlineOpenRoadDaysCheckout',
    //   meta     : {title: 'buyOnline.openRoadDaysTitle'}
    // },
  ]
});
routes.push({
  path     : '/demo-bank',
  component: () => import('@/views/layout/BuyOnline.vue'),
  redirect : '/demo-bank/payment',
  children : [
    {
      path     : 'payment/:orderId',
      component: () => import('@/views/demoBank/payment.vue'),
      name     : 'demoBankPayment',
      meta     : {title: 'Демо интернет-эквайринг'}
    }
  ]
});

routes.push({
  path     : '/payment',
  component: () => import('@/views/layout/BuyOnline.vue'),
  children : [
    {
      path     : 'success',
      component: () => import('@/views/payment/success.vue'),
      name     : 'paymentSuccess',
      meta     : {title: 'buyOnline.paymentSuccess'}
    },
    {
      path     : 'failed',
      component: () => import('@/views/payment/failed.vue'),
      name     : 'paymentFailed',
      meta     : {title: 'buyOnline.paymentFailed'}
    },
  ]
});

routes.push({
  path     : '/catalog-info',
  component: () => import('@/views/layout/Main'),
  redirect : '/catalog-info/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/catalogInfo/form'),
      name     : 'catalogInfoForm',
      meta     : {title: 'pageCatalogInfo'}
    }
  ]
});
routes.push({
  path     : '/catalog',
  component: () => import('@/views/layout/Main'),
  redirect : '/catalog/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/catalog/index'),
      name     : 'catalogIndex',
      meta     : {title: 'pageCatalog'}
    }
  ]
});
routes.push({
  path     : '/payer-company',
  component: () => import('@/views/layout/Main'),
  redirect : '/payer-company/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/payerCompany/form'),
      name     : 'payerCompanyForm',
      meta     : {title: 'pagePayerDetails'}
    }
  ]
});
routes.push({
  path     : '/contact',
  component: () => import('@/views/layout/Main'),
  redirect : '/contact/details',
  children : [
    {
      path     : 'details',
      component: () => import('@/views/contactDetails/form'),
      name     : 'contactDetailsForm',
      meta     : {title: 'pageMyDetails'}
    }
  ]
});
routes.push({
  path     : '/new-order',
  component: () => import('@/views/layout/Main'),
  redirect : '/new-order/area',
  children : [
    {
      path     : 'area',
      component: () => import('@/views/order/newArea'),
      name     : 'newAreaForm',
      meta     : {title: 'pageSpaceOrdering'}
    },
    {
      path     : 'application',
      component: () => import('@/views/order/newApplication.vue'),
      name     : 'newApplicationForm',
      meta     : {title: 'pageAdditionalServicesOrdering'}
    },
    {
      path     : 'ads',
      component: () => import('@/views/order/newAds.vue'),
      name     : 'newAdsForm',
      meta     : {title: 'pageAdsOrdering'}
    },
    {
      path     : 'paid-visitor',
      component: () => import('@/views/order/newPaidVisitor.vue'),
      name     : 'newPaidVisitorForm',
      meta     : {title: 'pagePaidVisitorOrdering'}
    }
  ]
});
routes.push({
  path     : '/orders',
  component: () => import('@/views/layout/Main'),
  redirect : '/orders/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/orders/index'),
      name     : 'ordersIndex',
      meta     : {title: 'pageMyOrders'}
    }
  ]
});
routes.push({
  path     : '/documents',
  component: () => import('@/views/layout/Main'),
  redirect : '/documents/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/documents/index'),
      name     : 'documentsIndex',
      meta     : {title: 'pageDocuments'}
    }
  ]
});
routes.push({
  path     : '/invite-co-exhibitor',
  component: () => import('@/views/layout/Main'),
  redirect : '/invite-co-exhibitor/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/sendInvitation/form'),
      name     : 'inviteCoExhibitorForm',
      meta     : {
        title           : 'pageInvite',
        inputDescription: 'inviteCoExhibitorInputDescription',
        invitationType  : 'coExhibitor',
      }
    }
  ]
});
routes.push({
  path     : '/invite-developer',
  component: () => import('@/views/layout/Main'),
  redirect : '/invite-developer/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/sendInvitation/form'),
      name     : 'inviteDeveloperForm',
      meta     : {
        title           : 'pageInvite',
        inputDescription: 'inviteDeveloperInputDescription',
        invitationType  : 'developer',
      }
    }
  ]
});
routes.push({
  path     : '/co-exhibitors',
  component: () => import('@/views/layout/Main'),
  redirect : '/co-exhibitors/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/coExhibitors/index'),
      name     : 'coExhibitorsIndex',
      meta     : {title: 'pageCoExhibitors'}
    }
  ]
});
routes.push({
  path     : '/logistics',
  component: () => import('@/views/layout/Main'),
  redirect : '/logistics/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/logistics/index'),
      name     : 'logisticsIndex',
      meta     : {title: 'pageLogistics'}
    }
  ]
});
routes.push({
  path     : '/conditions',
  component: () => import('@/views/layout/Main'),
  redirect : '/conditions/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/conditions/index'),
      name     : 'conditionsIndex',
      meta     : {title: 'pageConditions'}
    }
  ]
});
routes.push({
  path     : '/power-of-attorney-sample',
  component: () => import('@/views/layout/Main'),
  redirect : '/power-of-attorney-sample/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/powerOfAttorneySample/index'),
      name     : 'powerOfAttorneySampleIndex',
      meta     : {title: 'pagePowerOfAttorneySample'}
    }
  ]
});
routes.push({
  path     : '/letter-for-import-export',
  component: () => import('@/views/layout/Main'),
  redirect : '/letter-for-import-export/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/letterForImportExport/index'),
      name     : 'letterForImportExportIndex',
      meta     : {title: 'pageLetterForImportExport'}
    }
  ]
});
routes.push({
  path     : '/participants-and-badges',
  component: () => import('@/views/layout/Main'),
  redirect : '/participants-and-badges/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/participantsAndBadges/index.vue'),
      name     : 'participantsAndBadgesIndex',
      meta     : {title: 'pageParticipantsAndBadges'}
    },
    {
      path     : 'create',
      component: () => import('@/views/participantsAndBadges/form.vue'),
      name     : 'participantsAndBadgesCreate',
      meta     : {title: 'pageParticipantsAndBadgesCreate', parent: ['participantsAndBadgesIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/participantsAndBadges/form.vue'),
      name     : 'participantsAndBadgesUpdate',
      meta     : {
        title: 'pageParticipantsAndBadgesUpdate', parent: ['participantsAndBadgesIndex'], titleParam: ['email']
      }
    }
  ]
});
routes.push({
  path     : '/stand-visitors',
  component: () => import('@/views/layout/Main'),
  redirect : '/stand-visitors/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/standVisitors/index.vue'),
      name     : 'standVisitorsIndex',
      meta     : {title: 'pageStandVisitors'}
    },
    {
      path     : 'create',
      component: () => import('@/views/standVisitors/form.vue'),
      name     : 'standVisitorsCreate',
      meta     : {title: 'pageStandVisitorsCreate', parent: ['standVisitorsIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/standVisitors/form.vue'),
      name     : 'standVisitorsUpdate',
      meta     : {
        title: 'pageStandVisitorsUpdate', parent: ['standVisitorsIndex'], titleParam: ['email']
      }
    }
  ]
});
routes.push({
  path     : '/mounting-passes',
  component: () => import('@/views/layout/Main'),
  redirect : '/mounting-passes/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/mountingPass/index.vue'),
      name     : 'mountingPassIndex',
      meta     : {title: 'pageMountingPass'}
    },
    {
      path     : 'create',
      component: () => import('@/views/mountingPass/form.vue'),
      name     : 'mountingPassCreate',
      meta     : {title: 'pageMountingPassCreate', parent: ['mountingPassIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/mountingPass/form.vue'),
      name     : 'mountingPassUpdate',
      meta     : {
        title: 'pageMountingPassUpdate', parent: ['mountingPassIndex'], titleParam: ['email']
      }
    }
  ]
});
routes.push({
  path     : '/promoters',
  component: () => import('@/views/layout/Main'),
  redirect : '/promoters/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/promoter/index.vue'),
      name     : 'promoterIndex',
      meta     : {title: 'pagePromoter'}
    },
    {
      path     : 'create',
      component: () => import('@/views/promoter/form.vue'),
      name     : 'promoterCreate',
      meta     : {title: 'pagePromoterCreate', parent: ['promoterIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/promoter/form.vue'),
      name     : 'promoterUpdate',
      meta     : {
        title: 'pagePromoterUpdate', parent: ['promoterIndex'], titleParam: ['email']
      }
    }
  ]
});
routes.push({
  path     : '/business-program',
  component: () => import('@/views/layout/Main'),
  redirect : '/business-program/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/layout/BusinessProgram.vue'),
      children : [
        {
          path     : '',
          component: () => import('@/views/businessProgram/index'),
          name     : 'businessProgramIndex',
          meta     : {title: 'pageBusinessProgram'}
        },
      ]
    },
    {
      path     : 'event-details',
      component: () => import('@/views/layout/BusinessProgram.vue'),
      children : [

        {
          path     : ':id',
          component: () => import('@/views/businessProgram/eventDetails.vue'),
          name     : 'businessProgramEventDetails',
          meta     : {title: 'pageBusinessProgram'}
        },
      ]
    },
  ]
});
routes.push({
  path     : '/exhibitors-guide',
  component: () => import('@/views/layout/Main'),
  redirect : '/exhibitors-guide/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/exhibitorsGuide/index'),
      name     : 'exhibitorsGuideIndex',
      meta     : {title: 'pageExhibitorsGuide'}
    }
  ]
});
routes.push({
  path     : '/account',
  component: () => import('@/views/layout/Main'),
  redirect : '/account/edit',
  children : [
    {
      path     : 'edit',
      component: () => import('@/views/account/form.vue'),
      name     : 'accountEdit',
      meta     : {title: 'pageAccountEdit'}
    },
  ]
});
routes.push({
  path     : '/conference-table',
  component: () => import('@/views/layout/Main'),
  redirect : '/conference-table/profile',
  children : [
    {
      path     : 'profile',
      component: () => import('@/views/confProfile/index.vue'),
      name     : 'confProfileIndex',
      redirect : '/conference-table/profile/view',
      children : [
        {
          path     : 'view',
          component: () => import('@/views/confProfile/view.vue'),
          name     : 'confProfileView',
          meta     : {title: 'pageProfileView'}
        },
        {
          path     : 'edit',
          component: () => import('@/views/confProfile/edit.vue'),
          name     : 'confProfileEditForm',
          meta     : {title: 'pageProfileEdit'}
        },
        {
          path     : 'calendar',
          component: () => import('@/views/confProfile/calendar.vue'),
          name     : 'confProfileCalendarManagement',
          meta     : {title: 'pageProfileCalendarManagement'}
        },
        {
          path     : 'notification',
          component: () => import('@/views/confProfile/notification.vue'),
          name     : 'confProfileNotificationManagement',
          meta     : {title: 'pageProfileNotificationManagement'}
        },
      ]
    },
    {
      path     : 'recommended-contacts',
      component: () => import('@/views/confContacts/list.vue'),
      name     : 'confRecommendedContactsIndex',
      meta     : {
        title             : 'pageRecommendedContacts',
        controlButtonsType: 'recommended',
        showCountryFilter : true,
        showSectionFilter : true,
      }
    },
    {
      path     : 'favorite-contacts',
      component: () => import('@/views/confContacts/list.vue'),
      name     : 'confFavoriteContactsIndex',
      meta     : {
        title             : 'pageFavoriteContacts',
        controlButtonsType: 'favorites'
      }
    },
    {
      path     : 'rejected-contacts',
      component: () => import('@/views/confContacts/list.vue'),
      name     : 'confRejectedContactsIndex',
      meta     : {
        title             : 'pageRejectedContacts',
        controlButtonsType: 'rejected'
      }
    },
    {
      path     : 'meeting-calendar',
      component: () => import('@/views/confMeetingCalendar/index.vue'),
      name     : 'confMeetingCalendarIndex',
      meta     : {title: 'pageMeetingCalendar'}
    },
    {
      path     : 'instruction',
      component: () => import('@/views/confInstruction/index.vue'),
      name     : 'confInstructionIndex',
      meta     : {title: 'pageInstruction'}
    },
  ]
});
routes.push({
  path     : '/paid-visitor-badge',
  component: () => import('@/views/layout/Main'),
  redirect : '/paid-visitor-badge/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/paidVisitorBadge/index'),
      name     : 'paidVisitorBadgeIndex',
      meta     : {title: 'pagePaidVisitorBadge'}
    },
    {
      path     : 'create',
      component: () => import('@/views/paidVisitorBadge/form.vue'),
      name     : 'paidVisitorBadgeCreate',
      meta     : {title: 'pagePaidVisitorBadgeCreate', parent: ['paidVisitorBadgeIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/paidVisitorBadge/form.vue'),
      name     : 'paidVisitorBadgeUpdate',
      meta     : {
        title: 'pagePaidVisitorBadgeUpdate', parent: ['paidVisitorBadgeIndex'], titleParam: ['email']
      }
    }
  ]
});

const Router = new VueRouter({
  mode                : 'history',
  routes,
  linkExactActiveClass: 'active',
  parseQuery(query) {
    let params;
    try {
      params = qs.parse(query, {skipNulls: true, format: 'RFC1738'});
    }
    catch (e) {

    }
    return params;
  },
  stringifyQuery(query) {
    let result;

    try {
      result = qs.stringify(query, {skipNulls: true, format: 'RFC1738'});
    }
    catch (e) {

    }

    return result ? ('?' + result) : '';
  }
});

Router.beforeEach((to, from, next) => {
  const store = Router.app.$store;

  if (to.path != from.path) {
    if (!to.meta || !to.meta.disableLoader) {
      store.commit('SHOW_LOADER');
    }
  }

  // Вход в ЛК с переданными реквизитами в адресной строке
  if (to.query.token && to.query.user) {
    setAuth(1, to.query.user, to.query.token);
    store.dispatch('auth')
  }

  const publicPaths = [
    '/login',
    // длинная анкета регистрации
    '/registration',
    '/registration-co-exhibitor',
    '/registration-absentee-exhibitor',
    '/registration-developer',
    '/participation-in-business-program',
    // регистрация персон
    '/person-registration/organizer',
    '/person-registration/security',
    '/person-registration/staff',
    '/person-registration/developer-a',
    '/person-registration/free-dod-visitor',
    '/person-registration/press-b',
    '/person-registration/participant-a',
    '/person-registration/participant-c',
    '/person-registration/participant-d',
    '/person-registration/participant-e',
    '/person-registration/participant-f',
    '/person-registration/russian-railways',
    '/person-registration/cfto',
    '/person-registration/mzdr',
  ];

  const noLoginPublicPaths = [
    '/public-catalog',
    '/public-business-program',
    '/buy-badge',
    '/demo-bank',
    '/payment',
  ];

  if (_.findIndex(noLoginPublicPaths, (p) => to.path.includes(p)) !== -1) {
    next();
  }
  else if (_.indexOf(publicPaths, to.path) === -1 && (!store.getters.token || !store.getters.user)) {
    store.dispatch('setReferrer', to.path);
    next({path: '/login'});
  }
  else if (to.path === '/' || (_.indexOf(publicPaths, to.path) !== -1 && store.getters.token && store.getters.user)) {
    if (store.getters.referrer) {
      store.dispatch('clearReferrer');
      next({path: store.getters.referrer});
    }
    else {
      next({path: ''});
    }
  }
  else {
    next();
  }

  let title = Router.app.$t('personalAccount');
  document.title = to?.meta?.title ? Router.app.$t(to?.meta?.title) + ` | ${title}` : title
});

Router.beforeResolve((to, from, next) => {
  const store = Router.app.$store;
  // если форма не менялась - то сразу идем дальше
  if (!store.getters.formIsChanged) {
    next();
    return;
  }

  Router.app.$bvModal.msgBoxConfirm('Вы хотите уйти? У вас есть несохранённые изменения!', {
    title            : 'Уход со страницы',
    titleTag         : 'div',
    titleClass       : 'h5',
    buttonSize       : 'sm',
    okVariant        : 'warning',
    headerBgVariant  : 'warning',
    headerTextVariant: 'light',
    bodyClass        : 'text-center',
    cancelTitle      : 'Отменить',
    okTitle          : 'Подтвердить',
    centered         : true
  })
    .then(result => {
      if (result) {
        store.dispatch('setAdminFormChanged', false);
        next();
      }
      else {
        next(false);
        Router.app.$store.commit('HIDE_LOADER');
      }
    });
});

export default Router;
