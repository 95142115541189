import * as common from '@/api/common';
import {getAuth, getLocalStorageParam, setLocalStorageParam} from 'Global/helpers/localStorage';
import {setAll as setAllSettings} from 'Global/helpers/settings/SettingsStore';
import Vue from 'vue';
import store from "@/store";

const app = {
  state    : {
    sidebar          : {
      opened: true,
      menu  : []
    },
    organization     : null,
    contact          : null,
    formIsChanged    : false,
    language         : getLocalStorageParam('language', 'ru'),
    referrer         : false,
    lastUrl          : '/',
    constants        : [],
    showLoader       : true,
    showGlobalScroll : true,
    breadcrumbVisible: true,
    footerVisible    : true,
    cashierTab       : null,
    isMobile         : false,
  },
  mutations: {
    TOGGLE_SIDEBAR      : state => {
      state.sidebar.opened = !state.sidebar.opened;
    },
    SHOW_BREADCRUMB     : state => {
      state.breadcrumbVisible = true;
    },
    HIDE_BREADCRUMB     : state => {
      state.breadcrumbVisible = false;
    },
    SHOW_FOOTER         : state => {
      state.footerVisible = true;
    },
    HIDE_FOOTER         : state => {
      state.footerVisible = false;
    },
    CLOSE_SIDEBAR       : (state) => {
      setLocalStorageParam('sidebarStatus', '1');
      state.sidebar.opened = false;
    },
    SET_LANGUAGE        : (state, language) => {
      state.language = language;
      setLocalStorageParam('language', language);
    },
    SET_REFERRER        : (state, referrer) => {
      state.referrer = referrer;
    },
    SET_SIDEBAR_MENU    : (state, menu) => {
      state.sidebar.menu = menu;
    },
    SET_ORGANIZATION    : (state, organization) => {
      state.organization = organization;
    },
    SET_CONTACT         : (state, contact) => {
      state.contact = contact;
    },
    SET_SETTINGS        : (state, data) => {
      // Запись настроек в новое API
      setAllSettings(data);
    },
    SET_CONSTANTS       : (state, data) => {
      state.constants = data;
    },
    SET_ADMIN_FORM_STATE: (state, data) => {
      /**
       * @see vue/apps/admin/src/components/AdminForm/index.vue, methods : { getItem() {......}
       **/
      state.formIsChanged = data;
    },
    SHOW_LOADER         : (state) => {
      state.showLoader = true;
    },
    HIDE_LOADER         : (state) => {
      state.showLoader = false;
    },
    SHOW_GLOBAL_SCROLL  : (state) => {
      state.showGlobalScroll = true;
    },
    HIDE_GLOBAL_SCROLL  : (state) => {
      state.showGlobalScroll = false;
    },
    SET_CASHIER_TAB     : (state, data) => {
      state.cashierTab = data;
    },
    SET_IS_MOBILE       : (state, data) => {
      state.isMobile = data
    },
  },
  actions  : {
    /**
     * Переключение состояния бокового меню
     *
     * @param commit
     * @param state
     */
    toggleSideBar({commit, state}) {
      commit('TOGGLE_SIDEBAR');
    },
    /**
     * Закрытие бокового меню
     *
     * @param commit
     */
    closeSideBar({commit}) {
      commit('CLOSE_SIDEBAR');
    },
    /**
     * Установка языка
     *
     * @param commit
     * @param language
     */
    setLanguage({commit}, language) {
      commit('SET_LANGUAGE', language);
    },
    /**
     * Установка ссылки для последующего перехода по ней, например, после авторизации
     *
     * @param commit
     * @param referrer
     */
    setReferrer({commit}, referrer) {
      commit('SET_REFERRER', referrer);
    },
    /**
     * Установка состояние формы
     *
     * @param commit
     * @param state
     */
    setAdminFormChanged({commit}, state) {
      commit('SET_ADMIN_FORM_STATE', state);
    },
    /**
     * Отчистка ссылки перехода
     *
     * @param commit
     */
    clearReferrer({commit}) {
      commit('SET_REFERRER', false);
    },
    /**
     * Получения массива бокового меню
     *
     * @param commit
     * @param state
     * @param organizationType
     * @returns {Promise<any>}
     */
    getSidebarMenu({commit, state}, {organizationType}) {
      return new Promise((resolve, reject) => {
        if (state.sidebar.menu && state.sidebar.menu.length > 0) {
          resolve();
          return;
        }

        common.getSidebarMenu(organizationType)
          .then(data => {
            if (store.state.app.isMobile) {
              if (Array.isArray(data)) {
                data.push({
                  "title": "logout",
                  "api"  : "/logout",
                  "route": "/logout",
                  "icon" : "arrow-right-from-bracket"
                })
              }
              else {
                data[9999] = {
                  "title": "logout",
                  "api"  : "/logout",
                  "route": "/logout",
                  "icon" : "arrow-right-from-bracket"
                }
              }
            }

            commit('SET_SIDEBAR_MENU', data);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    mobileAndTabletsCheck({commit}) {
      let check = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);

      commit('SET_IS_MOBILE', check)
    },
    /**
     * Получения информации об организации
     *
     * @param commit
     * @param state
     * @returns {Promise<any>}
     */
    getOrganization({commit, state}) {
      return new Promise((resolve, reject) => {
        // if (state.organization) {
        //   resolve();
        //   return;
        // }

        common.getOrganization()
          .then(data => {
            commit('SET_ORGANIZATION', data);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * Получения информации об организации
     *
     * @param commit
     * @param state
     * @returns {Promise<any>}
     */
    getContact({commit, state}) {
      return new Promise((resolve, reject) => {
        // if (state.organization) {
        //   resolve();
        //   return;
        // }

        common.getContact()
          .then(data => {
            commit('SET_CONTACT', data);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * Получения массива бокового меню
     *
     * @param commit
     * @param state
     * @returns {Promise<any>}
     */
    async getSettings({commit, state}) {
      return new Promise((resolve, reject) => {
        common.getSettings()
          .then(data => {
            commit('SET_SETTINGS', data);
            this._vm.$GlobalBus.$emit('settingsLoad', data);

            resolve();
          })
          .catch(error => {
            this._vm.$GlobalBus.$emit('settingsLoad', {});

            reject(error);
          });
      });
    },
    /**
     * Получения массива бокового меню
     *
     * @param commit
     * @param state
     * @returns {Promise<any>}
     */
    async getConstants({commit, state}) {
      return new Promise((resolve, reject) => {
        common.getConstants()
          .then(data => {
            commit('SET_CONSTANTS', data);
            this._vm.$GlobalBus.$emit('constantsLoad', data);

            resolve();
          })
          .catch(error => {
            this._vm.$GlobalBus.$emit('constantsLoad', {});

            reject(error);
          });
      });
    },

    openCashierTab({commit, state}) {
      if (!state.cashierTab || state.cashierTab.closed) {
        const cashierUrl = Vue.prototype.$settings.ALL.cashier.cashier_url;
        if (!cashierUrl) {
          console.error('Не установлена ссылка кассы в настройке cashier_url');
          return;
        }

        const authInfo = getAuth();
        const cashierId = Vue.prototype.$settings.ALL.cashier.cashier_shiftless_default_sales_channel_id;
        commit('SET_CASHIER_TAB', window.open(`${cashierUrl}?username=${authInfo.user}&token=${authInfo.token}&id=${cashierId}&without_work_shift=1`, '_blank'));
      }
      else {
        state.cashierTab.focus();
      }
    },

    setIsMobile({commit}, value) {
      commit('SET_IS_MOBILE', value);
    },
  }
};

export default app;
