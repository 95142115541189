import * as API from '@/helpers/request';
import {downloadFile} from "@/helpers/request";

const controllerPath = 'site/business-program';

export function getSchedule() {
  return API.request.post(`${controllerPath}/get-schedule`);
}

export function getAllSpeakers() {
  return API.request.post(`${controllerPath}/get-all-speakers`);
}

export function getVenues() {
  return API.request.post(`${controllerPath}/get-venues`);
}

export function getEventsByDate(date) {
  return API.request.post(`${controllerPath}/get-events-by-date/${date}`);
}

export function getEventDetails(id) {
  return API.request.post(`${controllerPath}/get-event-details/${id}`);
}


// uses
export function getDates() {
  return API.request.post(`${controllerPath}/get-dates`);
}

export function getPrograms() {
  return API.request.post(`${controllerPath}/get-programs`);
}

export function getThemes() {
  return API.request.post(`${controllerPath}/get-themes`);
}

export function getEventsBy(filters) {
  return API.request.post(`${controllerPath}/get-events-by`, {filters});
}

export function getPersonPhoto(type, id) {
  return API.request.post(`${controllerPath}/get-person-photo/${id}`, {type});
}

export function downloadPdf(locale) {
  return downloadFile(
    `${controllerPath}/download-pdf`,
    {locale},
    'application/pdf',
    `business-program.pdf`
  );
}

export function getPresentations() {
  return API.request.post(`${controllerPath}/get-presentations`);
}