import * as organization from "@/api/organization";

const badge = {
  state    : {
    quotas               : {
      areaQuota        : 0,
      railQuota        : 0,
      delegatedInQuotas: {
        quantity_participant: 0,
        quantity_visitor    : 0,
      },
      purchasedQuota   : 0,
    },
    quotasParticipantUsed: 0,
    quotasVisitorUsed    : 0,
    quotasDelegated      : {
      quantity_participant: 0,
      quantity_visitor    : 0,
    },
    canBuyQuota          : false,
    hasQuotaParticipant  : false,
    hasQuotaVisitor      : false,
  },
  mutations: {
    SET_QUOTAS: (state, data) => {
      state.quotas = data.quotas
      state.quotasParticipantUsed = data.quotasParticipantUsed
      state.quotasVisitorUsed = data.quotasVisitorUsed
      state.quotasDelegated = data.quotasDelegated
      state.canBuyQuota = data.canBuyQuota
      state.hasQuotaParticipant = data.hasQuotaParticipant
      state.hasQuotaVisitor = data.hasQuotaVisitor
    },
  },
  actions  : {
    getBadgeQuotas({commit}) {
      return new Promise((resolve, reject) => {
        organization.getBadgeQuotas()
          .then(data => {
            commit('SET_QUOTAS', data);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};

export default badge;
