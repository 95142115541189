import * as API from '@/helpers/request';
import {request} from '@/helpers/request';
import _ from "lodash";

const controllerPath = 'order-item';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  try {
    let orderItem = await API.getItem(controllerPath, data)

    orderItem.order = orderItem.order.id
    orderItem.product = orderItem.product.id
    orderItem.contact = orderItem.contact.id

    return orderItem
  }
  catch (e) {
    console.log(e)
  }
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(data) {
  return API.deleteItem(controllerPath, data);
}