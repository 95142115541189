import * as API from '@/helpers/request';

const controllerPath = 'site/product';

export function getItemsByOrderBlock(orderBlock) {
  return API.request.post(`${controllerPath}/get-by-order-block`, {orderBlock});
}

export function getBadgeProductItem() {
  return API.request.post(`${controllerPath}/get-badge-product-item`);
}

