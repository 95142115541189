import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
// Модули
import app from './modules/app';
import user from './modules/user';
import schemeEditor from './modules/schemeEditor';
import errorLog from './modules/errorLog';
import cart from 'Global/store/cart';
import registrationForm from './modules/registrationForm';
import organization from './modules/organization';
import badge from './modules/badge';
import conferenceTable from './modules/conferenceTable.js';
// Тесты
import initVuexTestMethods from 'Global/store/tests';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    errorLog,
    schemeEditor,
    cart,
    registrationForm,
    organization,
    badge,
    conferenceTable,
  },
  getters
});

initVuexTestMethods(store);

export default store;
