import {getLocalStorageParam, removeLocalStorageParam, setLocalStorageParam} from 'Global/helpers/localStorage';

const registrationForm = {
  state: {
    step     : 1,
    locale   : 'ru',
    type     : 'exhibitor',
    parent_id: null,
    form     : {
      inn                    : '',
      kpp                    : '',
      name_rus               : '',
      name_eng               : '',
      opf_rus                : '',
      opf_eng                : '',
      phone                  : '',
      fax                    : '',
      site                   : '',
      email                  : '',
      payer_company          : {
        full_name              : '',
        bank_name              : '',
        checking_account       : '',
        corresp_account        : '',
        bank_bic               : '',
        boss_position          : '',
        boss_surname           : '',
        boss_name              : '',
        boss_middle_name       : '',
        boss_basis_of_authority: ''
      },
      legal_address_country  : null,
      legal_address_zip      : '',
      legal_address_rus      : '',
      legal_address_eng      : '',
      postal_address_country : null,
      postal_address_zip     : '',
      postal_address_rus     : '',
      postal_address_eng     : '',
      exhibitor              : {
        description : '',
        phone       : '',
        fax         : '',
        site        : '',
        email       : '',
        address_type: '',
        country     : '',
        zip         : '',
        address_rus : '',
        logo        : '',
        sections    : [],
      },
      alfa_contact           : {
        surname    : '',
        name       : '',
        middle_name: '',
        position   : '',
        phone      : '',
        email      : '',
      },
      personal_data_agreement: false
    }
  },

  mutations: {
    SET_FORM_PARAM(state, {key, value}) {
      state.form[key] = value
    },
    SET_STEP(state, step) {
      state.step = step
    },
    SET_LOCALE(state, locale) {
      state.locale = locale
    },
    SET_TYPE(state, type) {
      state.type = type
    },
    SET_PARENT_ID(state, parent_id) {
      state.parent_id = parent_id
    },
    SET_DEFAULT_STATE(state) {
      state.step = 1
      state.locale = 'ru'
      state.type = 'exhibitor'
      state.parent_id = null
      state.form = {
        inn                    : '',
        kpp                    : '',
        name_rus               : '',
        name_eng               : '',
        opf_rus                : '',
        opf_eng                : '',
        phone                  : '',
        fax                    : '',
        site                   : '',
        email                  : '',
        payer_company          : {
          full_name              : '',
          bank_name              : '',
          checking_account       : '',
          corresp_account        : '',
          bank_bic               : '',
          boss_position          : '',
          boss_surname           : '',
          boss_name              : '',
          boss_middle_name       : '',
          boss_basis_of_authority: ''
        },
        legal_address_country  : null,
        legal_address_zip      : '',
        legal_address_rus      : '',
        legal_address_eng      : '',
        postal_address_country : null,
        postal_address_zip     : '',
        postal_address_rus     : '',
        postal_address_eng     : '',
        exhibitor              : {
          description : '',
          phone       : '',
          fax         : '',
          site        : '',
          email       : '',
          address_type: '',
          country     : '',
          zip         : '',
          address_rus : '',
          logo        : '',
          sections    : [],
        },
        alfa_contact           : {
          surname    : '',
          name       : '',
          middle_name: '',
          position   : '',
          phone      : '',
          email      : '',
        },
        personal_data_agreement: false
      }
    }
  },

  actions: {
    setRegistrationFormStep({commit, state}, step) {
      commit('SET_STEP', step)

      setLocalStorageParam('registrationFormStep', state.step)
    },
    loadRegistrationFormStep({commit, state}, step) {
      const savedStep = getLocalStorageParam('registrationFormStep')

      if (savedStep) {
        commit('SET_STEP', savedStep)
      }
    },
    setRegistrationFormParam({commit, state}, {key, value}) {
      commit('SET_FORM_PARAM', {key, value})

      setLocalStorageParam('registrationForm', state)
    },
    loadRegistrationForm({commit, state}) {
      const savedForm = getLocalStorageParam('registrationForm')

      if (savedForm && savedForm.form) {
        _.forEach(state.form, (value, key) => {
          commit('SET_FORM_PARAM', {key, value: savedForm.form[key]})
        })
      }
    },
    clearRegistrationForm({commit}) {
      removeLocalStorageParam('registrationFormStep')
      removeLocalStorageParam('registrationForm')
      commit('SET_DEFAULT_STATE')
    }
  }
};

export default registrationForm;
