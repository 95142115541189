export const messages = {
  ru: {
    exhibitionName                       : 'PRO//Движение.Экспо 2023',
    loginPageMainTitleDates              : '24-27 августа 2023',
    loginPageMainTitle                   : 'PRO<span style="color: #be331a;">//</span>Движение.Экспо',
    loginPageBgDescription               : 'Фото предоставлено Музеем железных дорог России',
    redirecting                          : 'Переадресация ...',
    redirectingToMainSiteText            : 'Происходит переадресация на основной сайт выставки https://www.railwayexpo.ru/',
    currentPassword                      : 'Текущий пароль',
    newPassword                          : 'Новый пароль',
    repeatPassword                       : 'Повторите новый пароль',
    wrongCurrentPassword                 : 'Введен неверный текущий пароль',
    newAndOldPasswordIdentical           : 'Новый и старый пароль идентичны. Новый пароль должен отличаться.',
    newLoginDescription                  : 'Этот email будет использоваться для входа в личный кабинет. В случае изменения обязательно запишите новый email.',
    emailChangeWarning                   : 'ВНИМАНИЕ! Вы собираетесь изменить email! После изменения вы выйдите из Личного кабинета автоматически и переадресованы на страницу входа. Затем используйте ваш новый email для входа.',
    presentations                        : 'Презентации',
    advertising                          : 'Реклама',
    error                                : 'Ошибка',
    delete                               : 'Удалить',
    deletion                             : 'Удаление',
    confirmDeletion                      : 'Подтвердите удаление',
    noProfilesFound                      : 'Не найдено ни одного профиля',
    addToFavorites                       : 'Добавить в избранное',
    removeFromFavorites                  : 'Удалить из избранного',
    removeFromFavoritesConfirmation      : 'Вы действительно хотите удалить из избранного {profileName}',
    addToRejected                        : 'Отклонить',
    removeFromRejected                   : 'Восстановить',
    blockContact                         : 'Заблокировать контакт',
    unblockContact                       : 'Разблокировать контакт',
    meetingRequest                       : 'Запрос на встречу',
    selectFreeSlotFirst                  : 'Сначала выберите хотя бы один временной слот',
    meetingRequestDescription            : 'Выберите дату и время встречи и нажмите кнопку "Отправить"',
    chatRequest                          : 'Запрос на чат',
    chatSendConfirmation                 : 'Создать чат с {profileName}?',
    blockContactConfirmation             : 'Вы действительно хотите заблокировать контакт {profileName}?',
    unblockContactConfirmation           : 'Вы действительно хотите разблокировать контакт {profileName}?',
    fullNameSearch                       : 'Поиск по ФИО',
    forbidden                            : 'Доступ запрещен',
    orderIsEmpty                         : 'Невозможно создать пустой заказ',
    contractInvoiceSalesDisabled         : 'Продажи бейджей по счет-договорам закрыты',
    salesDisabled                        : 'Продажи закрыты',
    pay                                  : 'Оплатить',
    retryPayment                         : 'Попробовать оплатить еще раз',
    paymentError                         : 'Ошибка оплаты',
    searchFavorites                      : 'Показать избранные',
    upload                               : 'Загрузить',
    download                             : 'Скачать',
    clearFilters                         : 'Очистить фильтры',
    search                               : 'Поиск',
    sortBy                               : 'Сортировать по',
    fromAtoZ                             : 'От А до Я',
    fromZtoA                             : 'От Я до А',
    file                                 : 'Файл',
    cancel                               : 'Отмена',
    close                                : 'Закрыть',
    success                              : 'Успех',
    type                                 : 'Тип',
    number                               : 'Номер',
    date                                 : 'Дата',
    time                                 : 'Время',
    sum                                  : 'Сумма',
    title                                : 'Название',
    description                          : 'Описание',
    status                               : 'Статус',
    dataIsLoading                        : 'Данные загружаются...',
    noDataAvailable                      : 'Данные отсутствуют',
    operator                             : 'оператор',
    exhibitor                            : 'экспонент',
    coExhibitor                          : 'Соэкспонент',
    absentee                             : 'Заочник',
    developer                            : 'Застройщик',
    participantB                         : 'Участник деловой программы',
    hasErrorsInForm                      : 'В форме есть ошибки',
    documentSuccessfullyUploaded         : 'Документ успешно загружен',
    documentUploadModalTitle             : 'Загрузка документа',
    loggedInAs                           : 'Вы вошли как',
    logIn                                : 'Войти',
    logout                               : 'Выйти',
    logoutConfirm                        : 'Вы действительно хотите выйти из системы?',
    save                                 : 'Сохранить',
    filePlaceholder                      : 'Выберите файл...',
    fileDropPlaceholder                  : 'Перетащите файл сюда...',
    send                                 : 'Отправить',
    successfullySent                     : 'Успешно отправлено',
    sendError                            : 'Произошла ошибка во время отправки. Попробуйте позже или обратитесь в техническую поддержку.',
    back                                 : 'Назад',
    backToSite                           : 'Вернуться на сайт выставки',
    desktop                              : 'Рабочий стол',
    country                              : 'Страна',
    zip                                  : 'Почтовый индекс',
    inn                                  : 'ИНН',
    count                                : 'Количество',
    countChildren                        : '+ дети до 14 лет за 0 руб',
    create                               : 'Создать',
    created                              : 'Создан',
    createVisitorAug24                   : 'Создать Посетителя на 24 августа',
    createVisitorAug25                   : 'Создать Посетителя на 25 августа',
    createVisitorAug24_27                : 'Создать Посетителя на 24-27 августа',
    section                              : 'Раздел выставки',
    dataUpdated                          : 'Данные обновлены',
    allRightsReserved                    : 'Все права защищены',
    personalAccount                      : 'Личный кабинет',
    user                                 : 'Пользователь',
    password                             : 'Пароль',
    stand                                : 'Стенд',
    fio                                  : 'ФИО',
    position                             : 'Должность',
    phoneNumber                          : 'Номер телефона',
    registrar                            : 'Регистратор',
    member                               : 'Участник',
    members                              : 'Участники',
    organizationName                     : 'Название организации',
    organizationNameForBadge             : 'Название организации для бейджа',
    photo                                : 'Фотография',
    badge                                : 'Бейдж',
    badgeQuota                           : 'Квота бейджей',
    delegateBadges                       : 'Делегировать бейджи',
    delegate                             : 'Делегировать',
    delegated                            : 'Делегировано',
    buyBadges                            : 'Купить бейджи',
    buy                                  : 'Купить',
    purchased                            : 'Куплено',
    confirmOrder                         : 'Подтвердить заказ',
    pdfPrevPage                          : 'Предыдущая',
    pdfNextPage                          : 'Следующая',
    print                                : 'Печать',
    commonError                          : 'Произошла ошибка при выполнении действия. Попробуйте повторить действие '
                                           + 'через некоторое время или обратитесь в техническую поддержку.',
    limitExceeded                        : 'Превышен лимит',
    organizationNotFound                 : 'Organization not found',
    noBadgesToDelegate                   : 'У вас нет бейджей, которые можно делегировать',
    noSpecifiedNumberOfBadges            : 'У вас нет указанного количества бейджей, которые можно делегировать',
    accessDenied                         : 'Доступ запрещен',
    used                                 : 'Использовано',
    quantity                             : 'Количество',
    registration                         : 'Регистрация',
    speakers                             : 'Спикеры',
    moderator                            : 'Модератор',
    moderators                           : 'Модераторы',
    clickToViewDetails                   : 'Кликните чтобы посмотреть подробнее',
    noEventsFound                        : 'Не найдено ни одного мероприятия',
    noSpeakersFound                      : 'Не найдено ни одного спикера',
    noPhoto                              : 'Нет фото',
    noEventsOnSpecifiedDate              : 'Нет мероприятий в указанную дату',
    eventNoLongerAvailable               : 'Выбранное мероприятие более не доступно',
    listOfEvents                         : 'К списку мероприятий',
    program                              : 'Программа',
    partOfTheName                        : 'Часть имени',
    lastName                             : 'Фамилия',
    firstName                            : 'Имя',
    middleName                           : 'Отчество',
    surname                              : 'Фамилия',
    name                                 : 'Имя',
    email                                : 'Email',
    phone                                : 'Телефон',
    company                              : 'Компания',
    thisWillReflectedOnBadge             : '(это будет отражено на бейдже)',
    sendBadgeToThisEmail                 : '(на эту почту мы вышлем бейдж)',
    sendBadgesToThisEmail                : '(на эту почту мы вышлем бейджи)',
    locationAtExhibition                 : 'Местоположение на выставке',
    interestInExhibition                 : 'Интерес к выставке',
    positionInCompany                    : 'Укажите Вашу должность в компании/организации',
    companyFunctional                    : 'Укажите Ваш функционал в компании/организации',
    purposeOfVisiting                    : 'Укажите Вашу цель посещения выставки',
    visitedExhibitionBefore              : 'Посещали ли Вы выставку ранее',
    youShouldSaveProfileFirst            : 'У вас еще нет профиля. Вы должны сохранить данные в редактировании профиля, чтобы создать его.',
    exhibitorDetails                     : 'Детали экспонента',
    startMakingBusinessContacts          : 'Начните устанавливать деловые контакты',
    startShowingInterests                : 'Начните проявлять "интересы", чтобы построить связи',
    confProfileCalendarDescription       : 'Выберите время, когда вы недоступны для встреч на мероприятии.<br>'
                                           + 'Все временные слоты показаны по времени <b>Europe/Moscow</b>',
    markWholeDayAs                       : 'Отметить весь день, как',
    busy                                 : 'Занят',
    free                                 : 'Свободен',
    available                            : 'Доступен',
    category                             : 'Категория',
    notAvailable                         : 'Не доступен',
    allowAppointments                    : 'Разрешить назначать мне встречи',
    allowSendMessages                    : 'Разрешить отправлять мне сообщения',
    allowChat                            : 'Разрешить отправлять мне чат',
    active                               : 'Включен',
    notActive                            : 'Выключен',
    noAvailableSlots                     : 'Нет свободных слотов',
    showDeletedAndPast                   : 'Показать удаленные и прошедшие',
    new                                  : 'Новый',
    approved                             : 'Подтвержден',
    rejected                             : 'Отклонен',
    approve                              : 'Принять',
    decline                              : 'Отклонить',
    change                               : 'Изменить',
    meeting                              : 'Встреча',
    changeMeetingStatus                  : 'Изменение статуса встречи',
    confirmChangeMeetingStatus           : 'Вы уверены, что хотите изменить статус встречи на "{status}"',
    notification                         : 'Уведомление',
    hideChat                             : 'Свернуть чат',
    showChat                             : 'Развернуть чат',
    byDay                                : 'По дням',
    byProgram                            : 'По программам',
    byTheme                              : 'По темам',
    broadcast                            : 'Трансляция',
    profileInvitesYouToChat              : 'Вас пригласили в чат с {profile}',
    toEventsList                         : 'К списку мероприятий',
    downloadBusinessProgram              : 'Скачать программу',
    selectAnOption                       : 'Выберите значение',
    fillPayerDetailsFirstText            : 'Сначала заполните реквизиты компании-плательщика, чтобы создать заказ',
    buyPromoterQuotasFirst               : 'Чтобы создать промоутера, сначала оплатите аккредитацию нужного количества',
    accountCreatedSuccessfully           : 'Аккаунт успешно создан',
    accountCreatedSuccessfullyDescription: '<p>Спасибо за регистрацию. Ваши данные успешно сохранены. На указанный Вами адрес электронной почты придет'
                                           + ' инструкция о дальнейших действиях.</p>'
                                           + '<p>Если Вы не получили письмо, рекомендуем проверить папку "Нежелательная почта" или обратиться к оператору'
                                           + ' Выставки <a href="mailto:ovr@rotexpo.moscow">ovr@rotexpo.moscow</a></p>',
    pageNotFound                         : 'Страница не найдена',
    pageCatalogInfo                      : 'Инфо для каталога',
    pageCatalog                          : 'Каталог',
    pagePayerDetails                     : 'Реквизиты плательщика',
    pageMyDetails                        : 'Мои данные',
    pageSpaceOrdering                    : 'Заказ площади',
    pageMyOrders                         : 'Мои заказы',
    pageDocuments                        : 'Документы',
    pageLogistics                        : 'Логистика',
    pageAdditionalServicesOrdering       : 'Заказ дополнительных услуг',
    pageAdsOrdering                      : 'Рекламные возможности',
    pagePaidVisitorOrdering              : 'Заказ бейджей посетителя',
    pageConditions                       : 'Общие условия участия',
    pageBusinessProgram                  : 'Деловая программа',
    pageCoExhibitors                     : 'Соэкспоненты',
    pageParticipantsAndBadges            : 'Мои участники + бейджи',
    pageParticipantsAndBadgesCreate      : 'Создание участника',
    pageParticipantsAndBadgesUpdate      : 'Изменение участника',
    pageMountingPass                     : 'Монтажный пропуск',
    pageMountingPassCreate               : 'Создание монтажного пропуска',
    pageMountingPassUpdate               : 'Изменение монтажного пропуска',
    pagePromoter                         : 'Промоутеры',
    pagePromoterCreate                   : 'Создание промоутера',
    pagePromoterUpdate                   : 'Изменение промоутера',
    pageStandVisitors                    : 'Посетители стенда',
    pageStandVisitorsCreate              : 'Создание посетителя стенда',
    pageStandVisitorsUpdate              : 'Изменение посетителя стенда',
    pageInvite                           : 'Отправить приглашение',
    pageInviteDescription                : 'На указанный ниже email будет отправлена ссылка для регистрации приглашаемого Вами человека',
    pageExhibitorsGuide                  : 'Справочник экспонента',
    pageConferenceTable                  : 'Стол переговоров',
    pageProfile                          : 'Профиль',
    pageProfileView                      : 'Просмотр профиля',
    pageProfileEdit                      : 'Изменение профиля',
    pageProfileCalendarManagement        : 'Управление календарем',
    pageProfileNotificationManagement    : 'Управление уведомлениями',
    pageProfileEditAccount               : 'Редактирование учетной записи',
    pageRecommendedContacts              : 'Рекомендованные контакты',
    pageFavoriteContacts                 : 'Избранные контакты',
    pageRejectedContacts                 : 'Отклоненные контакты',
    pageMeetingCalendar                  : 'Календарь встреч',
    pageInstruction                      : 'Инструкция',
    pagePaidVisitorBadge                 : 'Бейджи',
    pagePaidVisitorBadgeCreate           : 'Создание посетителя',
    pagePaidVisitorBadgeUpdate           : 'Изменение посетителя',
    pageAccountEdit                      : 'Изменение учетной записи',
    pagePowerOfAttorneySample            : 'Образец доверенности',
    pageLetterForImportExport            : 'Письмо на ввоз/вывоз',
    confProfileView                      : 'Просмотреть профиль',
    confProfileEditForm                  : 'Редактировать профиль',
    confProfileCalendarManagement        : 'Управление календарем',
    confProfileNotificationManagement    : 'Управление уведомлениями',
    inviteCoExhibitorInputDescription    : 'СОЭКСПОНЕНТ - участник выставки, размещающий свои товари и(или) услуги на вашем стенде, привлекая для этого собственный персонал',
    inviteDeveloperInputDescription      : 'ЗАСТРОЙЩИК - компания, которая будет сооружать стенд',
    sidebarMenu                          : {
      exhibitor                 : 'ЭКСПОНЕНТ',
      exhibition                : 'ВЫСТАВКА',
      serviceOrders             : 'ЗАКАЗЫ УСЛУГ',
      contacts                  : 'КОНТАКТЫ',
      participant               : 'УЧАСТНИК',
      catalogInfo               : 'Инфо для каталога',
      catalog                   : 'Каталог',
      placeAnOrder              : 'Разместить заказ',
      spaceOrdering             : 'Заказ площади',
      createdOrders             : 'Созданные заказы',
      myDetails                 : 'Мои данные',
      payerDetails              : 'Реквизиты плательщика',
      myOrders                  : 'Мои заказы',
      inviteCoExhibitor         : 'Пригласить соэкспонента',
      inviteDeveloper           : 'Пригласить застройщика',
      documents                 : 'Документы',
      coExhibitors              : 'Соэкспоненты',
      conditionsOfParticipation : 'Общие условия участия',
      logistics                 : 'Логистика',
      additionalServicesOrdering: 'Заказ дополнительных услуг',
      adsOrdering               : 'Рекламные возможности',
      participantsAndBadges     : 'Мои участники + бейджи',
      mountingPasses            : 'Монтажный пропуск',
      promoters                 : 'Промоутеры',
      standVisitors             : 'Посетители стенда',
      businessProgram           : 'Деловая программа',
      exhibitorsGuide           : 'Справочник экспонента',
      conferenceTable           : 'СТОЛ ПЕРЕГОВОРОВ',
      profile                   : 'Профиль',
      recommendedContacts       : 'Рекомендованные контакты',
      favoriteContacts          : 'Избранные контакты',
      rejectedContacts          : 'Отклоненные контакты',
      meetingCalendar           : 'Календарь встреч',
      instruction               : 'Инструкция',
      badges                    : 'Бейджи',
      accountEdit               : 'Изменение учетной записи',
      powerOfAttorneySample     : 'Образец доверенности',
      letterForImportExport     : 'Письмо на ввоз/вывоз',
    },
    buyOnline                            : {
      paymentByBankCard                : 'Оплата банковской картой',
      paymentByInvoice                 : 'Оплата по счет-договору',
      businessDaysTitle                : 'Бейдж на деловые дни',
      businessDaysDescription          : '<p>Стоимость бейджа «Посетитель» составляет:</p>'
                                         + '<p>24 или 25 августа:</p>'
                                         + '<ul>'
                                         + '<li>на сайте – 3 000 рублей</li>'
                                         + '<li>в кассе выставки – 5 000 рублей</li>'
                                         + '</ul>'
                                         + '<p>24-27 августа:</p>'
                                         + '<ul>'
                                         + '<li>на сайте – 7 000 рублей</li>'
                                         + '<li>в кассе выставки – 12 000 рублей</li>'
                                         + '</ul>'
                                         + '<p><b>Внимание! Посещение выставки 24 и 25 августа лицами младше 18 лет запрещено.</b></p>'
                                         + '<p>Укажите способ приобретения бейджа:</p>',
      checkoutDescription              : 'Заполните форму и нажмите кнопку "Оплатить" в интересующей вас категории бейджа',
      bpcBankResult                    : 'Результат оплаты заказа',
      paymentSuccess                   : 'Заказ успешно оплачен',
      paymentSuccessText               : 'Заказ №{orderId} успешно оплачен',
      paymentSuccessDescription        : '<p><b>Спасибо за покупку!</b></p> '
                                         + '<p>Ваш бейдж отправлен на указанную Вами электронную почту.</p> '
                                         + '<p>Если Вы не получили письмо, рекомендуем проверить папку «Нежелательная почта» или обратиться к оператору Выставки'
                                         + '  <a href="mailto:ovr@rotexpo.moscow">ovr@rotexpo.moscow</a></p>',
      paymentFailed                    : 'Оплата не удалась',
      paymentFailedText                : 'Оплата заказа №{orderId} не удалась',
      checkoutInvoiceText              : '<p>Вам будет создан Личный кабинет, в котором необходимо указать реквизиты компании-плательщика, сделать заказ и'
                                         + ' указать даты посещения, произвести оплату и заполнить данные на посетителя для формирования бейджа.</p>'
                                         + '<p>Обратите внимание! Категория «Посетитель» не предусматривает посещение мероприятий деловой программы'
                                         + ' выставки!</p>'
                                         + '<p>При возникновении вопросов обращайтесь к оператору Выставки <a href="mailto:ovr@rotexpo.moscow">ovr@rotexpo.moscow</a>'
                                         + '</p>',
      createAccount                    : 'Создать аккаунт',
      openRoadDaysTitle                : 'Бейдж на Дни Открытых Дорог',
      personalDataAgreementError       : 'Для продолжения покупки вы должны принять условия обработки персональных данных',
      personalDataAgreementErrorAccount: 'Для продолжения создания личного кабинета вы должны принять условия обработки персональных данных',
    },
    payerDetails                         : {
      description                          : 'Информация из этого раздела будет использована при формировании договоров, счетов и других '
                                             + 'документов. Будьте внимательны при заполнении.',
      blockBasicInfo                       : 'Основная информация',
      blockLegalAddress                    : 'Юридический адрес',
      blockPostalAddress                   : 'Почтовый адрес',
      blockBankDetails                     : 'Банковские реквизиты',
      blockSigner                          : 'Подписант (в родительном падеже)',
      payerCompanyFullName                 : 'Название плательщика с указанием организационно-правовой формы',
      addressLabel                         : 'Адрес (Район, город, улица, дом)',
      addressDescription                   : 'Адрес в формате "Район, город, улица, дом". Например: Москва, ул. Ленинские горы, д.1',
      postalAddressEqualsLegalAddress      : 'Почтовый адрес совпадает с юридическим',
      innLabel                             : 'ИНН компании-плательщика',
      innDescription                       : 'Идентификационный номер налогоплательщика',
      kppLabel                             : 'КПП компании-плательщика',
      kppDescription                       : 'Если участник не из РФ, ставим 0',
      payerCompanyCheckingAccountLabel     : 'Расчетный счет',
      payerCompanyBankNameLabel            : 'Наименование банка',
      payerCompanyBankBicLabel             : 'БИК банка',
      payerCompanyCorrespAccountLabel      : 'Корреспондентский счет банка',
      emailLabel                           : 'Email для договора',
      phoneLabel                           : 'Телефон',
      phoneDescription                     : 'Номер в международном формате, начиная с символа +. Например: +79998887766. '
                                             + 'После корректного ввода формат номера будет установлен автоматически.',
      payerCompanyBossSurnameLabel         : 'Фамилия (родительный падеж)',
      payerCompanyBossNameLabel            : 'Имя (родительный падеж)',
      payerCompanyBossMiddleNameLabel      : 'Отчество (родительный падеж)',
      payerCompanyBossPositionLabel        : 'Должность (родительный падеж)',
      payerCompanyBossBasisOfAuthorityLabel: 'Действующий на основании (родительный падеж)',
    },
    spaceOrdering                        : {
      expositionPlace                             : 'Место экспозиции',
      closeAreaType                               : 'Тип площади',
      areaSize                                    : 'Размер площади',
      areaBuildingType                            : 'Тип застройки',
      orderConfirmation                           : 'Подтверждение заказа',
      nextStep                                    : 'Следующий',
      prevStep                                    : 'Предыдущий',
      submitAndReturnToFirstStep                  : 'Принять и выбрать еще одно место экспозиции',
      submit                                      : 'Подтвердить заказ',
      areaSizeMustBeNumberError                   : 'Размер площади должен быть целым, положительным числом',
      areaSizeMin12Error                          : 'Минимальный размер площади 12 кв.м.',
      areaSizeNumberMin9Error                     : 'Размер площади должен быть целым, положительным числом, больше или равен 9',
      areaSizeRailStepError                       : 'Значение с шагом 1 м.',
      unconfirmedOrderTitle                       : 'У вас есть неподтвержденный заказ',
      unconfirmedOrderMessage                     : 'Вы можете подтвердить его или удалить, затем начать создание нового.',
      unconfirmedOrderOkMessage                   : 'Подтвердить заказ и начать создание нового',
      unconfirmedOrderCancelMessage               : 'Удалить заказ и начать создание нового',
      orderSuccessfullyCreated                    : 'Заказ успешно создан',
      orderCreationError                          : 'Не удалось завершать заказ площади. Пожалуйста, попробуйте позже или обратитесь '
                                                    + 'в техническую поддержку.',
      selectExpositionPlaceLabel                  : 'Укажите, где будет размещаться Ваша экспозиция',
      selectCloseAreaTypeLabel                    : 'Укажите тип площади',
      selectAreaSizeRailDescription               : 'Укажите размер рельсового полотна в п.м.',
      selectAreaSizePavilionDescription           : 'Укажите размер площади стенда в кв.м.',
      selectAreaSizePavilionMinSizeDescription    : 'Минимальный размер площади {minSize} кв.м.',
      selectAreaBuildingTypeLabel                 : 'Укажите тип застройки',
      selectAreaBuildingTypeIndependentDescription: 'Обязательно сообщите Выставочному оператору название Вашей компании-застройщика',
      selectAreaBuildingTypeExclusiveDescription  : 'В зависимости от комплектации стенда',
      orderConfirmationTotalSumLabel              : 'Итого с учетом НДС',
      orderConfirmationTotalSumVatLabel           : 'В том числе НДС 20%',
      orderConfirmationServiceNameColumn          : 'Название услуги',
      orderConfirmationRentalPriceColumn          : 'Стоимость аренды 1 кв.м./п.м.',
      orderConfirmationQuantityColumn             : 'Кол-во кв.м./п.м.',
      orderConfirmationCostColumn                 : 'Стоимость, в т.ч. НДС 20%',
    },
    additionalServiceOrdering            : {
      extraMountingTimeDescription      : 'Обратите внимание! При заказе дополнительного времени монтажа/демонтажа необходимо заказать дополнительный пропуск на автомобиль, действующий в период раннего  монтажа и позднего демонтажа.',
      cantCreateOrderWarning            : 'Вы не можете создать заказ на доп. услуги. Пока еще нет ни одной оплаченной площади.',
      selectStandLabel                  : 'Выберите ранее забронированный стенд',
      orderConfirmationServiceNameColumn: 'Название',
      orderConfirmationRentalPriceColumn: 'Цена',
      orderConfirmationQuantityColumn   : 'Кол-во',
      orderConfirmationCostColumn       : 'Стоимость, в т.ч. НДС 20%',
    },
    myDetails                            : {
      description        : 'Данные пользователя',
      blockBasicInfo     : 'Основная информация',
      blockContactDetails: 'Контактные данные',
      surnameLabel       : 'Фамилия',
      nameLabel          : 'Имя',
      middleNameLabel    : 'Отчество',
      positionLabel      : 'Должность',
      emailLabel         : 'Email',
      phoneLabel         : 'Телефон',
      phoneDescription   : 'Номер в международном формате, начиная с символа +. Например: +79998887766. '
                           + 'После корректного ввода формат номера будет установлен автоматически.',
    },
    documents                            : {
      uploadSignedScan: 'Загрузить подписанный скан',
    },
    // ##################
    catalogInfo          : {
      description                          : 'Информация из этого раздела пойдет в электронный каталог на сайт и в печатное издание каталога',
      yourStands                           : 'Ваши стенды',
      blockBasicInfo                       : 'Основная информация',
      logoLabel                            : 'Логотип',
      logoPlaceholder                      : 'Выберите файл...',
      logoDropPlaceholder                  : 'Перетащите файл сюда...',
      opfEngLabel                          : 'Организационно-правовая форма компании на английском языке',
      opfEngDescription                    : 'Аббревиатура, без названия компании. Например: JSC, LLC, GmbH',
      nameEngLabel                         : 'Компания-экспонент на английском языке',
      nameEngDescription                   : 'Заполняется на английском языке без указания организационно-правовой формы',
      descriptionEngLabel                  : 'Описание деятельности компании на английском языке ({length})',
      sectionsLabel                        : 'Тематические разделы каталога',
      blockContactDetails                  : 'Контактные данные',
      phoneLabel                           : 'Телефон',
      phoneDescription                     : 'Номер в международном формате, начиная с символа +. Например: +79998887766. После корректного ввода формат номера будет установлен автоматически.',
      faxLabel                             : 'Факс',
      faxDescription                       : 'Номер в международном формате, начиная с символа +. Например: +79998887766. После корректного ввода формат номера будет установлен автоматически.',
      emailLabel                           : 'Email для каталога',
      siteLabel                            : 'Сайт',
      addressEngLabel                      : 'Адрес (Район, город, улица, дом) на английском языке',
      addressEngDescription                : 'Адрес в формате "Район, город, улица, дом". Например: Moscow, Leninskie Gory St., 1',
      blockPresentationVideo               : 'Презентационный ролик о компании',
      youtubeUrlLabel                      : 'Ссылка на ролик',
      youtubeUrlDescription                : 'Полный путь (url) ролика. Ссылки с youtube.com и rutube.ru будут отображены в виде встраиваемого плеера. Ссылки на другие ресурсы отображаются как обычные ссылки. ',
      blockPresentationMaterials           : 'Презентационные материалы',
      blockPresentationMaterialsDescription: 'Вы можете загрузить не более 5 изображений',
      slideNameLabel                       : 'Название слайда на английском языке',
      slideDescriptionLabel                : 'Краткое описание на английском языке',
      slideFileContentLabel                : 'Изображение',
      slideFileContentDescription          : '.png, .jpg, .jpeg, .gif размером не больше 1Мб и высотой не более 200px для корректного отображения в каталоге',
      saveSlide                            : 'Сохранить слайд',
    },
    catalog              : {
      placementAtTheExhibition: 'Размещение на выставке',
      noExhibitorsYet         : 'В каталоге пока еще нет ни одного экспонента',
      presentationMaterials   : 'Презентационные материалы',
      presentationVideo       : 'Презентационный ролик о компании',
    },
    participantsAndBadges: {
      createParticipant         : 'Создать',
      badgeOrganizationNameLabel: 'Название организации для бейджа',
      photoLabel                : 'Фотография',
      wrongImageDimensions      : 'Фото должно быть квадратом со сторонами больше или равными 600 на 600 пикселей',
      newParticipantDescription : '<p>Нажмите на "Выберите файл..." и загрузите новое фото</p><ul>'
                                  + '<li>фото обязательно должно быть цветным</li>'
                                  + '<li>фото должно быть не старее 6 месяцев, в идеале - максимально свежим</li>'
                                  + '<li>фото должно отображать вашу текущую внешность</li>'
                                  + '<li>форма фото - прямоугольная, 3х4, минимальное разрешение - 450x600 пикселей</li>'
                                  + '<li>изображение лица должно занимать 70-80%</li>'
                                  + '<li>расширение - JPEG, размер - не более 2МБ</li>'
                                  + '<li>взгляд должен смотреть четко в камеру, выражение лица естественное и нейтральное, глаза открыты</li>'
                                  + '<li>запрещены сканы фотографий с других документов</li>'
                                  + '<li>запрещены любые пост обработки (сглаживания и фильтры)</li></ul>',
    },
    standVisitors        : {
      createStandVisitor        : 'Создать посетителя стенда',
      badgeOrganizationNameLabel: 'Название организации для бейджа',
      photoLabel                : 'Фотография',
      wrongImageDimensions      : 'Фото должно быть квадратом со сторонами больше или равными 600 на 600 пикселей',
      newStandVisitorDescription: '<p>Нажмите на "Выберите файл..." и загрузите новое фото</p><ul>'
                                  + '<li>фото обязательно должно быть цветным</li>'
                                  + '<li>фото должно быть не старее 6 месяцев, в идеале - максимально свежим</li>'
                                  + '<li>фото должно отображать вашу текущую внешность</li>'
                                  + '<li>форма фото - прямоугольная, 3х4, минимальное разрешение - 450x600 пикселей</li>'
                                  + '<li>изображение лица должно занимать 70-80%</li>'
                                  + '<li>расширение - JPEG, размер - не более 2МБ</li>'
                                  + '<li>взгляд должен смотреть четко в камеру, выражение лица естественное и нейтральное, глаза открыты</li>'
                                  + '<li>запрещены сканы фотографий с других документов</li>'
                                  + '<li>запрещены любые пост обработки (сглаживания и фильтры)</li></ul>',
    },
    // ##################
    login                             : {
      wrongLoginOrPassword: 'Неправильный логин или пароль',
    },
    organizationHasNoQuotas           : 'У вас не осталось квот',
    validations                       : {
      enterUsername            : 'Введите имя пользователя',
      enterPassword            : 'Введите пароль',
      required                 : 'Обязательное поле',
      tooShort                 : 'Значение должно быть не менее {min} символов',
      tooLong                  : 'Значение должно быть не более {max} символов',
      email                    : 'Значение должно быть корректным email адресом',
      url                      : 'Значение должно быть корректным url',
      phone                    : 'Введите корректный номер',
      maxLengthWithoutSpaces   : 'Описание должно быть не больше 1000 символов, без учета пробелов',
      kppEqualsZeroOrLengthNine: 'КПП должен состоять из 9 цифр или равен 0, если организация не из РФ',
      innLength10or12          : 'ИНН РФ должен состоять из 10 (юрлицо) или 12 (ИП, физлицо) цифр',
      emailValidator           : 'Email может содержать только латинские буквы, цифры и символы "@","-","_" и точку.',
      fileTooBig               : 'Файл слишком большой. Максимальный размер {max}',
      emailExistsError         : 'Указанный email уже существует. Выберите другой.',
      sameAsPassword           : 'Пароли должны совпадать.',
    },
    personRegistrationTitle           : {
      default           : 'Регистрация участников',
      organizer         : 'Регистрация в категории "Организатор"',
      'developer-a'     : 'Регистрация в категории "Застройщик А"',
      security          : 'Регистрация в категории "Безопасность"',
      staff             : 'Регистрация в категории "Персонал"',
      'press-b'         : 'Регистрация СМИ',
      'free-dod-visitor': 'Регистрация в категории "Бесплатный посетитель ДОД"',
      'russian-railways': 'Анкета посетителя',
      'cfto'            : 'Регистрация участников конференции "Наука 1520 ВНИИЖТ"',
      'mzdr'            : 'Анкета регистрации сотрудников Музея железных дорог России',
    },
    exhibitorRegistrationTitle        : 'Анкета регистрации участника выставки',
    coExhibitorRegistrationTitle      : 'Анкета регистрации соэкспонента',
    absenteeExhibitorRegistrationTitle: 'Анкета регистрации заочного участника выставки',
    developerRegistrationTitle        : 'Анкета регистрации Застройщика',
    participantBRegistrationTitle     : 'Участие в деловой программе',
    registrationForm                  : {
      commonError                      : 'Не удалось завершить регистрацию. Пожалуйста, проверьте данные в форме и попробуйте зарегистрироваться снова. Если ошибка повторится, обратитесь в техническую поддержку.',
      mainTitle                        : 'Регистрация',
      step2title                       : 'Данные для договора',
      step3title1                      : 'Банковские реквизиты',
      step3title2                      : 'Руководитель компании плательщика (в родительном падеже)',
      step4title1                      : 'Юридический адрес',
      step4title2                      : 'Почтовый адрес',
      step5title                       : 'Информация для электронного каталога',
      step6title                       : 'Разделы участия в выставке',
      step7title                       : 'Информация о контактном лице',
      nextStepBtn                      : 'Далее',
      prevStepBtn                      : 'Назад',
      registerBtn                      : 'Зарегистрировать',
      innLabel                         : 'ИНН',
      innPlaceholder                   : 'Идентификационный номер налогоплательщика',
      innChecking                      : 'Проверка ИНН ...',
      innExistsError                   : 'Компания с таким ИНН уже зарегистрирована в базе. Пожалуйста, свяжитесь с оператором выставки для уточнения данных.',
      innCheckError                    : 'Не удалось проверить ИНН',
      nameLabel                        : 'Название Вашей компании',
      namePlaceholder                  : 'Название Вашей компании',
      nameDescription                  : 'Заполняется на русском языке без указания организационно-правовой формы',
      opfLabel                         : 'Организационно-правовая форма компании',
      opfPlaceholder                   : 'Организационно-правовая форма компании',
      opfDescription                   : 'Аббревиатура, без названия компании. Например: ООО, ФГБНУ, ИП глава КФХ',
      phoneLabel                       : 'Телефон компании',
      phoneDescription                 : 'Номер в международном формате, начиная с символа +. Например: +79998887766. После корректного ввода формат номера будет установлен автоматически.',
      faxLabel                         : 'Факс компании',
      faxDescription                   : 'Номер в международном формате, начиная с символа +. Например: +79998887766. После корректного ввода формат номера будет установлен автоматически.',
      siteLabel                        : 'Сайт компании',
      sitePlaceholder                  : 'Сайт компании',
      siteDescription                  : 'Укажите корректный URL. Например: http://example.com',
      emailLabel                       : 'Email компании',
      emailPlaceholder                 : 'Email компании',
      emailDescription                 : 'Укажите корректный Email. Например: user@example.com',
      payerCompany                     : {
        fullNameLabel                  : 'Полное название компании-плательщика',
        fullNamePlaceholder            : 'Полное название компании-плательщика',
        fullNameDescription            : 'Полное название компании-плательщика',
        bankNameLabel                  : 'Наименование банка',
        bankNamePlaceholder            : 'Наименование банка',
        bankNameDescription            : 'Наименование банка',
        checkingAccountLabel           : 'Расчетный счет',
        checkingAccountPlaceholder     : 'Расчетный счет',
        checkingAccountDescription     : 'Расчетный счет',
        correspAccountLabel            : 'Корр. счет банка',
        correspAccountPlaceholder      : 'Корр. счет банка',
        correspAccountDescription      : 'Корр. счет банка',
        bankBicLabel                   : 'БИК банка',
        bankBicPlaceholder             : 'БИК банка',
        bankBicDescription             : 'БИК банка',
        bossPositionLabel              : 'Должность',
        bossPositionPlaceholder        : 'Должность',
        bossPositionDescription        : 'Должность',
        bossSurnameLabel               : 'Фамилия',
        bossSurnamePlaceholder         : 'Фамилия',
        bossSurnameDescription         : 'Фамилия',
        bossNameLabel                  : 'Имя',
        bossNamePlaceholder            : 'Имя',
        bossNameDescription            : 'Имя',
        bossMiddleNameLabel            : 'Отчество',
        bossMiddleNamePlaceholder      : 'Отчество',
        bossMiddleNameDescription      : 'Отчество',
        bossBasisOfAuthorityLabel      : 'Действует на основании',
        bossBasisOfAuthorityPlaceholder: 'Действует на основании',
        bossBasisOfAuthorityDescription: 'Действует на основании',
      },
      addressCountryLabel              : 'Страна',
      addressCountryPlaceholder        : 'Страна',
      addressZipLabel                  : 'Индекс',
      addressZipPlaceholder            : 'Индекс',
      addressLabel                     : 'Район, населенный пункт, улица, дом',
      addressPlaceholder               : 'Район, населенный пункт, улица, дом',
      postalAddressSameAsLegal         : 'Почтовый адрес совпадает с юридическим',
      exhibitor                        : {
        descriptionLabel      : 'Описание деятельности, остаток символов',
        descriptionPlaceholder: 'Описание деятельности',
        descriptionDescription: 'Описание должно быть не больше 1000 символов, без учета пробелов',
        useAddressCheckbox    : 'Для размещения в каталоге использовать следующий адрес',
        selectedAddress       : 'Выбранный адрес',
        logoLabel             : 'Логотип',
        logoPlaceholder       : 'Перетащите логотип или щелкните здесь (*.png,*.jpg < 1Мб)',
      },
      alfaContact                      : {
        positionLabel        : 'Должность',
        positionPlaceholder  : 'Должность',
        positionDescription  : 'Должность',
        surnameLabel         : 'Фамилия',
        surnamePlaceholder   : 'Фамилия',
        surnameDescription   : 'Фамилия',
        nameLabel            : 'Имя',
        namePlaceholder      : 'Имя',
        nameDescription      : 'Имя',
        middleNameLabel      : 'Отчество',
        middleNamePlaceholder: 'Отчество',
        middleNameDescription: 'Отчество',
        phoneLabel           : 'Мобильный телефон',
        emailLabel           : 'E-mail',
        emailPlaceholder     : 'Email, который будет служить логином в личный кабинет'
      },
      alfaContactEmailChecking         : 'Проверка Email ...',
      alfaContactEmailExistsError      : 'Контакт с таким Email уже зарегистрирован в базе. Пожалуйста, выберите другой Email.',
      alfaContactEmailCheckError       : 'Не удалось проверить Email',
      alfaContactHasErrors             : 'Указаны некорректные данные Регистратора',
      exhibitorHasErrors               : 'Указаны некорректные данные Экспонента',
      organizationHasErrors            : 'Указаны некорректные данные Организации',
      personalDataAgreement            : 'Согласен на обработку персональных данных',
      personalDataAgreementExtended    : 'Согласен на обработку персональных данных. Настоящим подтверждаю, что мной получено предварительное согласие от Участника(ов) на обработку их персональных данных и я являюсь его(их) полномочным представителем субъекта(ов) персональных данных.',
      personalDataConditions           : 'Условия обработки персональных данных',
      shouldAcceptPersonalDataAgreement: 'Для продолжения регистрации, Вы должны принять условия обработки персональных данных',
      successfullyRegistrationTitle    : 'Регистрация прошла успешно',
      successfullyRegistrationBody     : '<p>Спасибо за регистрацию. Ваши данные успешно сохранены. На указанный Вами адрес электронной почты придет'
                                         + '        инструкция о дальнейших действиях.</p>'
                                         + '      <p>Если Вы не получили письмо, рекомендуем проверить папку "Нежелательная почта" или обратиться к оператору'
                                         + '        Выставки info@railwayexpo.ru</p>',
    },
    checkingPromoCode                 : 'Проверка промокода...',
    promoCodeNotFound                 : 'Введен неверный промокод',
    promoCodeAlreadyUsed              : 'Указанный промокод уже использован',
    registrationPersonForm            : {
      successfullyRegistrationTitle        : 'Регистрация прошла успешно',
      successfullyRegistrationBody         : '<p>Спасибо за регистрацию. На указанный Вами адрес электронной почты придет'
                                             + ' инструкция о дальнейших действиях.</p>'
                                             + '<p>Если Вы не получили письмо, рекомендуем проверить папку "Нежелательная почта" или обратиться к оператору'
                                             + ' Выставки ovr@rotexpo.moscow</p>',
      successfullyRegistrationWithBadgeBody: '<p>Спасибо за регистрацию. Бейджи отправлены на указанные адреса электронной почты.</p>'
                                             + '<p>Если Вы не получили письмо, рекомендуем проверить папку "Нежелательная почта" или обратиться к оператору'
                                             + ' Выставки ovr@rotexpo.moscow</p>',
      successfullyRegistrationCfto         : '<p>Спасибо за регистрацию. Бейдж отправлен на указанный Вами адрес электронной почты.</p>'
                                             + '<p>Если Вы не получили письмо, рекомендуем проверить папку "Нежелательная почта" или обратиться к оператору'
                                             + ' Выставки ovr@rotexpo.moscow</p>',
      mediaAccreditationButton             : 'Аккредитация СМИ',
      mediaAccreditationText               : '<p>Порядок аккредитации:</p>'
                                             + '<ul><li>Чтобы подать заявку на аккредитацию вашего СМИ, необходимо заполнить регистрационную форму.</li>'
                                             + '<li>Регистрационная форма заполняется на каждого участника отдельно.</li>'
                                             + '<li>После подтверждения вашей аккредитации Вам будет направлено отдельное письмо с бейджем категории «Пресса».'
                                             + '</li></ul>'
                                             + '<p>Обращаем Ваше внимание, что подтверждением прохождения аккредитации является получение бейджа на указанную Вами почту.</p>'
                                             + '<p>ПО ВОПРОСАМ АККРЕДИТАЦИИ И УСЛОВИЯМ СОТРУДНИЧЕСТВА ОБРАЩАЙТЕСЬ:<br>'
                                             + 'Анна Кофман<br>'
                                             + 'Менеджер по маркетингу и PR<br>'
                                             + 'Тел: +7 (495) 909-08-18 доб. 71-786<br>'
                                             + 'E-mail: a.kofman@gudok.ru</p>',
      pressGreetingsText                   : '<p>Уважаемые коллеги!</p>'
                                             + '<p>Благодарим Вас за интерес, проявленный к Международному салону пространства '
                                             + '1520 PRO//Движение.ЭКСПО. Заявки на аккредитацию принимаются до 18.08.2023г.</p>',
      promoCodePlaceholder                 : 'Промокод',
      addContact                           : 'Добавить участника',
      memberEmailDescription               : 'На указанный адрес будет отправлен персональный бейдж',
      memberPhotoDescription               : '<ul><li>Размер фотографии 45 х 35 мм.</li>'
                                             + '<li>Размер лица должен занимать не менее 80%</li>'
                                             + '<li>Снимок должен быть на равномерном светлом фоне</li>'
                                             + '<li>Снимок должен быть произведен в  анфас</li>'
                                             + '<li>Дата фотографии не должна превышать 6 месяцев</li></ul>',
    },
    personalDataAgreement             : '<p><b>Согласие на обработку персональных данных</b></p>'
                                        + '        <p>Настоящим я, далее – «Субъект Персональных Данных», в соответствии со статьей 9 Федерального закона от 27'
                                        + '          июля 2006 года № 152-ФЗ «О персональных данных» в целях регистрации Субъекта Персональных Данных на сайте'
                                        + '          http://railwayexpo.ru/ (далее – Сайт) с последующим направлением Субъекту Персональных Данных почтовых'
                                        + '          сообщений от АО «Издательский дом «Гудок», а также с целью подтверждения личности Субъекта Персональных Данных'
                                        + '          даю свое согласие АО «Издательский дом «Гудок» (ИНН 7701660262, адрес: 105066, Россия, Москва, ул. Старая'
                                        + '          Басманная д. 38/2 стр.3) (далее – Общество), и ООО «РОТЕКС» (7704787852, адрес: 115035, г. Москва, ул.'
                                        + '          Садовническая, д. 71. стр. 1. пом.7, эт. 4), которое Обществом, привлеченно для организации соответствующего'
                                        + '          мероприятия и которому поручена обработка персональных данных, а также аффилированным лицам Общества, во'
                                        + '          исполнение требований Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных» (с изменениями и'
                                        + '          дополнениями) на обработку следующих моих персональных данных: фамилия, имя, отчество; дата и место рождения,'
                                        + '          гражданство, образование, адрес, должность, контактные данные (телефон, факс, электронная почта, почтовый'
                                        + '          адрес), указанных при регистрации путем заполнения веб-формы на Сайте, направляемой (заполненной) с'
                                        + '          использованием Сайта.</p>'
                                        + '        <p>Даю согласие на автоматизированную, а также без использования средств автоматизации обработку Обществом моих'
                                        + '          персональных данных, то есть на совершение, в том числе, следующих действий: сбор, систематизацию, накопление,'
                                        + '          уточнение, обновление, изменение, использование, запись, извлечение, удаление, передачу, в том числе'
                                        + '          трансграничную, обезличивание, блокирование, уничтожение, хранение, при этом общее описание вышеуказанных'
                                        + '          способов обработки данных приведено в Федеральном законе от 27.07.2006 № 152-ФЗ «О персональных данных», а'
                                        + '          также на передачу такой информации третьим лицам, в случаях, установленных нормативными документами'
                                        + '          компетентных органов и законодательством.</p>'
                                        + '        <p>Даю согласие на использование моих биометрических персональных данных в виде фотографии в целях оформления'
                                        + '          допуска к месту проведения выставки Международный железнодорожный салон пространства 1520'
                                        + '          «PRO//Движение.ЭКСПО», и проводимых в его рамках деловых и иных мероприятий.</p>'
                                        + '        <p>Датой выдачи согласия на обработку персональных данных Субъекта Персональных Данных является дата отправки'
                                        + '          регистрационной веб-формы на Сайте. Настоящее согласие на обработку персональных данных действует до'
                                        + '          достижения целей обработки персональных данных, но не менее 1 (одного) года с момента регистрации на Cайте,'
                                        + '          или до дня его отзыва.</p>'
                                        + '        <p>АО «Издательский дом «Гудок» принимает необходимые правовые, организационные и технические меры или'
                                        + '          обеспечивает их принятие для защиты персональных данных от неправомерного или случайного доступа к ним,'
                                        + '          уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а'
                                        + '          также от иных неправомерных действий в отношении персональных данных, а также принимает на себя обязательство'
                                        + '          по обеспечению конфиденциальности персональных данных Субъекта Персональных Данных.</p>'
                                        + '        <p>Я ознакомлен(а), что согласие на обработку персональных данных может быть отозвано мною на основании'
                                        + '          письменного заявления в произвольной форме, направленного Обществу.</p>',
  },
  en: {
    exhibitionName                       : 'PRO//Motion.Expo 2023',
    loginPageMainTitleDates              : 'August 24-27, 2023',
    loginPageMainTitle                   : 'PRO<span style="color: #be331a;">//</span>Motion.Expo',
    loginPageBgDescription               : 'Photo provided by the Museum of Russian Railways',
    redirecting                          : 'Redirecting ...',
    redirectingToMainSiteText            : 'There is a redirect to the main exhibition website https://www.railwayexpo.ru/',
    currentPassword                      : 'Current password',
    newPassword                          : 'New password',
    repeatPassword                       : 'Repeat new password',
    wrongCurrentPassword                 : 'Wrong current password entered',
    newAndOldPasswordIdentical           : 'The new and old password are identical. The new password must be different.',
    newLoginDescription                  : 'This email will be used to log into your personal account. In case of change, be sure to write down a new email.',
    emailChangeWarning                   : 'ATTENTION!!! You suggest changing your email address! After the change, you are automatically transferred from your Personal Account and redirected to the login page. Victory your new login email address.',
    presentations                        : 'Presentations',
    advertising                          : 'Advertising',
    error                                : 'Error',
    delete                               : 'Delete',
    deletion                             : 'Deletion',
    confirmDeletion                      : 'Confirm deletion',
    noProfilesFound                      : 'No profile found',
    addToFavorites                       : 'Add to favorites',
    removeFromFavorites                  : 'Remove from favorites',
    removeFromFavoritesConfirmation      : 'Are you sure you want to remove from favorites {profileName}',
    addToRejected                        : 'Reject',
    removeFromRejected                   : 'Restore',
    blockContact                         : 'Block contact',
    unblockContact                       : 'Unblock contact',
    meetingRequest                       : 'Meeting request',
    selectFreeSlotFirst                  : 'Select at least one timeslot first',
    meetingRequestDescription            : 'Select the date and time of the meeting and click "Send"',
    chatRequest                          : 'Chat request',
    chatSendConfirmation                 : 'Create a chat with a {profileName}?',
    blockContactConfirmation             : 'Are you sure you want to block a contact {profileName}?',
    unblockContactConfirmation           : 'Are you sure you want to unblock a contact {profileName}?',
    fullNameSearch                       : 'Full name search',
    forbidden                            : 'Forbidden',
    orderIsEmpty                         : 'Order is empty',
    contractInvoiceSalesDisabled         : 'Sales of badges by invoice-contracts are closed',
    salesDisabled                        : 'Sales closed',
    pay                                  : 'Pay',
    retryPayment                         : 'Try to pay again',
    paymentError                         : 'Payment error',
    searchFavorites                      : 'Show favorites',
    upload                               : 'Upload',
    download                             : 'Download',
    clearFilters                         : 'Clear filters',
    search                               : 'Search',
    sortBy                               : 'Sort by',
    fromAtoZ                             : 'From A to Z',
    fromZtoA                             : 'From Z to A',
    file                                 : 'File',
    cancel                               : 'Cancel',
    close                                : 'Close',
    success                              : 'Success',
    type                                 : 'Type',
    number                               : 'Number',
    date                                 : 'Date',
    time                                 : 'Time',
    sum                                  : 'Sum',
    title                                : 'Title',
    description                          : 'Description',
    status                               : 'Status',
    dataIsLoading                        : 'Loading data...',
    noDataAvailable                      : 'There is no data available',
    operator                             : 'operator',
    exhibitor                            : 'exhibitor',
    coExhibitor                          : 'Co-exhibitor',
    absentee                             : 'Absentee exhibitor',
    developer                            : 'Developer',
    participantB                         : 'Business program participant',
    hasErrorsInForm                      : 'There are errors in the form',
    documentSuccessfullyUploaded         : 'Document uploaded successfully',
    documentUploadModalTitle             : 'Document upload',
    loggedInAs                           : 'You are logged in as',
    logIn                                : 'Login',
    logout                               : 'Logout',
    logoutConfirm                        : 'Are you sure you want to log out?',
    save                                 : 'Save',
    filePlaceholder                      : 'Choose file...',
    fileDropPlaceholder                  : 'Drag file here...',
    send                                 : 'Send',
    successfullySent                     : 'Successfully sent',
    sendError                            : 'An error occurred while sending. Try again later or contact technical support.',
    back                                 : 'Back',
    backToSite                           : 'Back to exhibition site',
    desktop                              : 'Desktop',
    country                              : 'Country',
    zip                                  : 'Postal code',
    inn                                  : 'TIN',
    count                                : 'Quantity',
    countChildren                        : '+ children',
    create                               : 'Create',
    created                              : 'Created at',
    createVisitorAug24                   : 'Create a Visitor for August 24',
    createVisitorAug25                   : 'Create a Visitor for August 25',
    createVisitorAug24_27                : 'Create a Visitor for August 24-27',
    section                              : 'Exhibition section',
    dataUpdated                          : 'Data has been updated',
    allRightsReserved                    : 'All rights reserved',
    personalAccount                      : 'Personal account',
    user                                 : 'User',
    password                             : 'Password',
    stand                                : 'Stand',
    fio                                  : 'Full name',
    position                             : 'Position',
    phoneNumber                          : 'Phone number',
    registrar                            : 'Registrar',
    member                               : 'Member',
    members                              : 'Members',
    organizationName                     : 'Organization name',
    organizationNameForBadge             : 'Organization name for badge',
    photo                                : 'Photo',
    badge                                : 'Badge',
    badgeQuota                           : 'Badge quota',
    used                                 : 'Used',
    quantity                             : 'Quantity',
    delegateBadges                       : 'Delegate badges',
    delegate                             : 'Delegate',
    delegated                            : 'Delegated',
    buyBadges                            : 'Buy badges',
    buy                                  : 'Buy',
    purchased                            : 'Purchased',
    confirmOrder                         : 'Confirm order',
    pdfPrevPage                          : 'Previous',
    pdfNextPage                          : 'Next',
    print                                : 'Print',
    commonError                          : 'An error occurred while performing the action. '
                                           + 'Try to repeat the action after a while or contact technical support.',
    limitExceeded                        : 'Limit is exceeded',
    organizationNotFound                 : 'Организация не найдена',
    noBadgesToDelegate                   : 'You don\'t have badges that can be delegated',
    noSpecifiedNumberOfBadges            : 'You do not have the specified number of badges that can be delegated',
    accessDenied                         : 'Access denied',
    registration                         : 'Registration',
    speakers                             : 'Speakers',
    moderator                            : 'Moderator',
    moderators                           : 'Moderators',
    clickToViewDetails                   : 'Click to view details',
    noEventsFound                        : 'No events found',
    noSpeakersFound                      : 'No speakers found',
    noPhoto                              : 'No photo',
    noEventsOnSpecifiedDate              : 'No events on the specified date',
    eventNoLongerAvailable               : 'The selected event is no longer available',
    listOfEvents                         : 'List of events',
    program                              : 'Program',
    partOfTheName                        : 'Part of the name',
    lastName                             : 'Last name',
    firstName                            : 'First name',
    middleName                           : 'Middle name',
    surname                              : 'Surname',
    name                                 : 'Name',
    email                                : 'Email',
    phone                                : 'Phone',
    company                              : 'Company',
    thisWillReflectedOnBadge             : '(this will be reflected on the badge)',
    sendBadgeToThisEmail                 : '(we will send a badge to this mail)',
    sendBadgesToThisEmail                : '(we will send a badges to this mail)',
    locationAtExhibition                 : 'Location at the exhibition',
    interestInExhibition                 : 'Interest in the exhibition',
    positionInCompany                    : 'Please indicate your position in the company/organization',
    companyFunctional                    : 'Specify your function in the company/organization',
    purposeOfVisiting                    : 'Specify your purpose of visiting the exhibition',
    visitedExhibitionBefore              : 'Have you visited the exhibition before?',
    youShouldSaveProfileFirst            : 'You don\'t have a profile yet. You must save the data in the profile edit in order to create it.',
    exhibitorDetails                     : 'Exhibitor details',
    startMakingBusinessContacts          : 'Start making business contacts',
    startShowingInterests                : 'Start showing "interests" to build connections',
    confProfileCalendarDescription       : 'Select a time when you are not available for meetings at the event.<br>'
                                           + 'All time slots are shown in time <b>Europe/Moscow</b>',
    markWholeDayAs                       : 'Mark the whole day like',
    busy                                 : 'Busy',
    free                                 : 'Free',
    available                            : 'Available',
    notAvailable                         : 'Not available',
    category                             : 'Category',
    allowAppointments                    : 'Allow me to make appointments',
    allowSendMessages                    : 'Allow me to send messages',
    allowChat                            : 'Allow me to chat',
    active                               : 'Active',
    notActive                            : 'Not active',
    noAvailableSlots                     : 'No available slots',
    new                                  : 'New',
    approved                             : 'Approved',
    rejected                             : 'Rejected',
    approve                              : 'Approve',
    decline                              : 'Decline',
    showDeletedAndPast                   : 'Show deleted and past',
    change                               : 'Change',
    meeting                              : 'Meeting',
    changeMeetingStatus                  : 'Changing the status of a meeting',
    confirmChangeMeetingStatus           : 'Are you sure you want to change the meeting status to "{status}"',
    notification                         : 'Notification',
    hideChat                             : 'Hide chat',
    showChat                             : 'Show chat',
    byDay                                : 'By day',
    byProgram                            : 'By program',
    byTheme                              : 'By theme',
    broadcast                            : 'Broadcast',
    profileInvitesYouToChat              : 'You have been invited to chat with {profile}',
    toEventsList                         : 'To events list',
    downloadBusinessProgram              : 'Download program',
    selectAnOption                       : 'Select an option',
    fillPayerDetailsFirstText            : 'First, fill in the details of the paying company to create an order',
    buyPromoterQuotasFirst               : 'To create a promoter, first pay for the required amount of accreditation',
    accountCreatedSuccessfully           : 'Account created successfully',
    accountCreatedSuccessfullyDescription: '<p>Thank you for registering. Your data has been successfully saved. It will be sent to the e-mail address you provided'
                                           + ' instruction on further actions.</p>'
                                           + '<p>If you haven\'t received an email, we recommend that you check your Junk Mail folder or contact your operator'
                                           + ' Exhibitions <a href="mailto:ovr@rotexpo.moscow">ovr@rotexpo.moscow</a></p>',
    pageNotFound                         : 'Page not found',
    pageCatalogInfo                      : 'Catalog info',
    pageCatalog                          : 'Catalog',
    pagePayerDetails                     : 'Payer details',
    pageMyDetails                        : 'My details',
    pageSpaceOrdering                    : 'Space ordering',
    pageMyOrders                         : 'My orders',
    pageDocuments                        : 'Documents',
    pageLogistics                        : 'Logistics',
    pageAdditionalServicesOrdering       : 'Additional services ordering',
    pageAdsOrdering                      : 'Advertising opportunities',
    pagePaidVisitorOrdering              : 'Visitor badge order',
    pageConditions                       : 'General conditions of participation',
    pageBusinessProgram                  : 'Business program',
    pageCoExhibitors                     : 'Co-exhibitors',
    pageParticipantsAndBadges            : 'My participants and badges',
    pageParticipantsAndBadgesCreate      : 'Create participant',
    pageParticipantsAndBadgesUpdate      : 'Update participant',
    pageMountingPass                     : 'Mounting pass',
    pageMountingPassCreate               : 'Create mounting pass',
    pageMountingPassUpdate               : 'Update mounting pass',
    pagePromoter                         : 'Promoters',
    pagePromoterCreate                   : 'Create promoter',
    pagePromoterUpdate                   : 'Update promoter',
    pageStandVisitors                    : 'Stand visitors',
    pageStandVisitorsCreate              : 'Create stand visitor',
    pageStandVisitorsUpdate              : 'Update stand visitor',
    pageInvite                           : 'Send an invitation',
    pageInviteDescription                : 'A link to register the person you invite will be sent to the email address below.',
    pageExhibitorsGuide                  : 'Exhibitors guide',
    pageConferenceTable                  : 'Conference table',
    pageProfile                          : 'Profile',
    pageProfileView                      : 'View profile',
    pageProfileEdit                      : 'Edit profile',
    pageProfileCalendarManagement        : 'Calendar management',
    pageProfileNotificationManagement    : 'Notification management',
    pageProfileEditAccount               : 'Edit account',
    pageRecommendedContacts              : 'Recommended contacts',
    pageFavoriteContacts                 : 'Favorite contacts',
    pageRejectedContacts                 : 'Rejected contacts',
    pageMeetingCalendar                  : 'Meeting calendar',
    pageInstruction                      : 'Instruction',
    pagePaidVisitorBadge                 : 'Badges',
    pagePaidVisitorBadgeCreate           : 'Create visitor',
    pagePaidVisitorBadgeUpdate           : 'Update visitor',
    pageAccountEdit                      : 'Edit account',
    pagePowerOfAttorneySample            : 'Power of attorney sample',
    pageLetterForImportExport            : 'Letter for import/export',
    confProfileView                      : 'View profile',
    confProfileEditForm                  : 'Edit profile',
    confProfileCalendarManagement        : 'Calendar management',
    confProfileNotificationManagement    : 'Notification management',
    inviteCoExhibitorInputDescription    : 'CO-EXHIBITOR - an exhibition participant who places his goods and (or) services at your stand, involving his own staff for this',
    inviteDeveloperInputDescription      : 'DEVELOPER - the company that will build the stand',
    sidebarMenu                          : {
      exhibitor                 : 'EXHIBITOR',
      exhibition                : 'EXHIBITION',
      serviceOrders             : 'SERVICE ORDERS',
      contacts                  : 'CONTACTS',
      participant               : 'PARTICIPANT',
      catalogInfo               : 'Catalog info',
      catalog                   : 'Catalog',
      placeAnOrder              : 'Place an order',
      spaceOrdering             : 'Space ordering',
      createdOrders             : 'Created orders',
      myDetails                 : 'My details',
      payerDetails              : 'Payer details',
      myOrders                  : 'My orders',
      inviteCoExhibitor         : 'Invite co-exhibitor',
      inviteDeveloper           : 'Invite developer',
      documents                 : 'Documents',
      coExhibitors              : 'Co-exhibitors',
      conditionsOfParticipation : 'General conditions of participation',
      logistics                 : 'Logistics',
      additionalServicesOrdering: 'Additional services ordering',
      adsOrdering               : 'Advertising opportunities',
      participantsAndBadges     : 'My participants and badges',
      mountingPasses            : 'Mounting passes',
      promoters                 : 'Promoters',
      standVisitors             : 'Stand visitors',
      businessProgram           : 'Business program',
      exhibitorsGuide           : 'Exhibitor\'s guide',
      conferenceTable           : 'CONFERENCE TABLE',
      profile                   : 'Profile',
      recommendedContacts       : 'Recommended contacts',
      favoriteContacts          : 'Favorite contacts',
      rejectedContacts          : 'Rejected contacts',
      meetingCalendar           : 'Meeting calendar',
      instruction               : 'Instruction',
      badges                    : 'Badges',
      accountEdit               : 'Edit account',
      powerOfAttorneySample     : 'Power of attorney sample',
      letterForImportExport     : 'Letter for import/export',
    },
    buyOnline                            : {
      paymentByBankCard                : 'Payment by bank card',
      paymentByInvoice                 : 'Payment by invoice',
      businessDaysTitle                : 'Badge for business days',
      businessDaysDescription          : '<p>The cost of the "Visitor" badge is:</p>'
                                         + '<p>August 24 or 25:</p>'
                                         + '<ul>'
                                         + '<li>on the site - 3,000 rubles</li>'
                                         + '<li>at the box office of the exhibition - 5,000 rubles</li>'
                                         + '</ul>'
                                         + '<p>August 24-27:</p>'
                                         + '<ul>'
                                         + '<li>on the site - 7,000 rubles</li>'
                                         + '<li>at the box office of the exhibition - 12,000 rubles</li>'
                                         + '</ul>'
                                         + '<p><b>Attention! Visiting the exhibition on August 24 and 25 by persons under 18 years of age is prohibited.</b></p>'
                                         + '<p>Specify the method of purchasing the badge:</p>',
      checkoutDescription              : 'Fill out the form and click the "Pay" button in the badge category you are interested in',
      bpcBankResult                    : 'Order payment result',
      paymentSuccess                   : 'Order successfully paid',
      paymentSuccessText               : 'Order №{orderId} paid successfully',
      paymentSuccessDescription        : '<p><b>Thank you for your purchase!</b></p> '
                                         + '<p>Your badge has been sent to your email address.</p> '
                                         + '<p>If you haven\'t received a letter, we recommend checking your Junk Mail folder or contacting the Exhibition operator'
                                         + ' <a href="mailto:ovr@rotexpo.moscow">ovr@rotexpo.moscow</a></p>',
      paymentFailed                    : 'Payment failed',
      paymentFailedText                : 'Payment for order №{orderId} failed',
      checkoutInvoiceText              : '<p>Your personal account will be created, in which you need to specify the details of the paying company, place an order and'
                                         + ' indicate the dates of the visit, make a payment and fill in the data for the visitor to generate a badge.</p>'
                                         + '<p>Pay attention! The category "Visitor" does not provide for attending business program events'
                                         + ' Exhibitions!</p>'
                                         + '<p>If you have any questions, please contact the Exhibition operator <a href="mailto:ovr@rotexpo.moscow">ovr@rotexpo.moscow</a>'
                                         + '</p>',
      createAccount                    : 'Create account',
      openRoadDaysTitle                : 'Badge for open road days',
      personalDataAgreementError       : 'To proceed with the purchase, you must accept the terms of personal data processing',
      personalDataAgreementErrorAccount: 'To continue creating a personal account, you must accept the terms of personal data processing',
    },
    payerDetails                         : {
      description                          : 'The information in this section will be used to generate contracts, '
                                             + 'invoices, and other documents. Be careful when filling it out.',
      blockBasicInfo                       : 'Basic information',
      blockLegalAddress                    : 'Legal address',
      blockPostalAddress                   : 'Postal address',
      blockBankDetails                     : 'Bank details',
      blockSigner                          : 'Signer',
      payerCompanyFullName                 : 'Name of the payer with indication of legal form',
      addressLabel                         : 'Address (Province, district, city, street, house)',
      addressDescription                   : 'Address in the format " Province, district, city, street, house". For example: Moscow, Leninskie Gory St., 1',
      postalAddressEqualsLegalAddress      : 'Postal address is the same as the legal address',
      innLabel                             : 'Taxpayer Identification Number (TIN) of the paying company',
      innDescription                       : 'Taxpayer Identification Number',
      kppLabel                             : 'Tax Registration Reason Code (KPP) of paying company',
      kppDescription                       : 'If the participant is not from Russia, put 0',
      payerCompanyCheckingAccountLabel     : 'Bank account',
      payerCompanyBankNameLabel            : 'Bank name',
      payerCompanyBankBicLabel             : 'Bank BIC',
      payerCompanyCorrespAccountLabel      : 'Correspondent account of the bank',
      emailLabel                           : 'Email address for the contract',
      phoneLabel                           : 'Phone number',
      phoneDescription                     : 'The number is in international format, starting with +. '
                                             + 'For example: +79998887766. After the correct entry, the number format will be set automatically.',
      payerCompanyBossSurnameLabel         : 'Last name',
      payerCompanyBossNameLabel            : 'First name',
      payerCompanyBossMiddleNameLabel      : 'Patronymic',
      payerCompanyBossPositionLabel        : 'Position',
      payerCompanyBossBasisOfAuthorityLabel: 'Acting on the basis of',
    },
    spaceOrdering                        : {
      expositionPlace                             : 'Exposition location',
      closeAreaType                               : 'Area type',
      areaSize                                    : 'Area size',
      areaBuildingType                            : 'Booth type',
      orderConfirmation                           : 'Order confirmation',
      nextStep                                    : 'Next',
      prevStep                                    : 'Previous',
      submitAndReturnToFirstStep                  : 'Accept and select another exposition location',
      submit                                      : 'Confirm order',
      areaSizeMustBeNumberError                   : 'Area size must be a positive integer',
      areaSizeMin12Error                          : 'Minimum space size is 12 sq.m.',
      areaSizeNumberMin9Error                     : 'The size of area must be increased, a number greater than or equal to 9',
      areaSizeRailStepError                       : 'Value in steps of 1 m.',
      unconfirmedOrderTitle                       : 'You have an unconfirmed order',
      unconfirmedOrderMessage                     : 'You can confirm it or delete it, then start creating a new one.',
      unconfirmedOrderOkMessage                   : 'Confirm the order and start creating a new one',
      unconfirmedOrderCancelMessage               : 'Delete an order and start creating a new one',
      orderSuccessfullyCreated                    : 'Order successfully created',
      orderCreationError                          : 'Failed to complete space order. Please try again later or contact '
                                                    + 'technical support.',
      selectExpositionPlaceLabel                  : 'Specify the location of your exhibit',
      selectCloseAreaTypeLabel                    : 'Specify area type',
      selectAreaSizeRailDescription               : 'Specify the size of the railroad track in r.m.',
      selectAreaSizePavilionDescription           : 'Specify the size of the booth area in sq.m.',
      selectAreaSizePavilionMinSizeDescription    : 'Minimum space size is {minSize} sq.m.',
      selectAreaBuildingTypeLabel                 : 'Specify booth type',
      selectAreaBuildingTypeIndependentDescription: 'Be sure to tell the Exhibition Operator the name of your developer company',
      selectAreaBuildingTypeExclusiveDescription  : 'The cost is determined depending on the configuration of the stand',
      orderConfirmationTotalSumLabel              : 'Total sum including VAT',
      orderConfirmationTotalSumVatLabel           : 'Including VAT 20%',
      orderConfirmationServiceNameColumn          : 'Service name',
      orderConfirmationRentalPriceColumn          : 'Rental price per sq.m./r.m.',
      orderConfirmationQuantityColumn             : 'Quantity of sq.m./r.m.',
      orderConfirmationCostColumn                 : 'Sum, including VAT 20%',
    },
    additionalServiceOrdering            : {
      extraMountingTimeDescription      : 'Note! When ordering an additional assembly/dismantling time, an additional vehicle pass must be ordered, valid during the early assembly and late dismantling periods.',
      cantCreateOrderWarning            : 'You cannot create an order for additional services. There are no paid areas yet.',
      selectStandLabel                  : 'Select a previously booked booth',
      orderConfirmationServiceNameColumn: 'Title',
      orderConfirmationRentalPriceColumn: 'Price',
      orderConfirmationQuantityColumn   : 'Quantity',
      orderConfirmationCostColumn       : 'Sum, including VAT 20%',
    },
    myDetails                            : {
      description        : 'User details',
      blockBasicInfo     : 'Basic information',
      blockContactDetails: 'Contact details',
      surnameLabel       : 'Last name',
      nameLabel          : 'First name',
      middleNameLabel    : 'Patronymic',
      positionLabel      : 'Position',
      emailLabel         : 'Email',
      phoneLabel         : 'Phone number',
      phoneDescription   : 'The number is in international format, starting with +. For example: +79998887766. '
                           + 'After the correct entry, the number format will be set automatically.',
    },
    documents                            : {
      uploadSignedScan: 'Upload signed scan',
    },
    // ##################
    catalogInfo          : {
      description                          : 'Information from this section will go to the electronic catalog on the website and to the printed edition of the catalog',
      yourStands                           : 'Your stands',
      blockBasicInfo                       : 'Basic information',
      logoLabel                            : 'Logo',
      logoPlaceholder                      : 'Choose file...',
      logoDropPlaceholder                  : 'Drag file here...',
      opfEngLabel                          : 'The legal form of the company',
      opfEngDescription                    : 'For example: JSC, LLC, GmbH',
      nameEngLabel                         : 'Exhibiting company',
      nameEngDescription                   : 'Filled in without specifying the legal form',
      descriptionEngLabel                  : 'Description of the company\'s activities ({length})',
      sectionsLabel                        : 'Sections of participation in the exhibition',
      blockContactDetails                  : 'Contact details',
      phoneLabel                           : 'Phone number',
      phoneDescription                     : 'The number is in international format, starting with +. For example: +79998887766. '
                                             + 'After the correct entry, the number format will be set automatically.',
      faxLabel                             : 'Fax number',
      faxDescription                       : 'The number is in international format, starting with +. For example: +79998887766. '
                                             + 'After the correct entry, the number format will be set automatically.',
      emailLabel                           : 'Email for catalog',
      siteLabel                            : 'Web site',
      addressEngLabel                      : 'District, town, street, house',
      addressEngDescription                : 'For example: Moscow, Leninskie Gory St., 1',
      blockPresentationVideo               : 'Presentation video about the company',
      youtubeUrlLabel                      : 'Link to video',
      youtubeUrlDescription                : 'The full path (url) of the video. Links from youtube.com and rutube.ru will be displayed as an embedded player. Links to other resources are displayed as regular links.',
      blockPresentationMaterials           : 'Presentation materials',
      blockPresentationMaterialsDescription: 'You can upload up to 5 images',
      slideNameLabel                       : 'Slide name',
      slideDescriptionLabel                : 'Short description',
      slideFileContentLabel                : 'Image',
      slideFileContentDescription          : '.png, .jpg, .jpeg, .gif size no more than 1Mb and height no more than 200px for correct display in the catalog',
      saveSlide                            : 'Save slide',
    },
    catalog              : {
      placementAtTheExhibition: 'Placement at the exhibition',
      noExhibitorsYet         : 'There are no exhibitors in the catalog yet',
      presentationMaterials   : 'Presentation materials',
      presentationVideo       : 'Presentation video about the company',
    },
    participantsAndBadges: {
      createParticipant         : 'Create participant',
      badgeOrganizationNameLabel: 'Organization name for the badge',
      photoLabel                : 'Photo',
      wrongImageDimensions      : 'The photo must be a square with sides greater than or equal to 600x600 pixels',
      newParticipantDescription : '<p>Click on browse to upload new photo</p><ul>'
                                  + '<li>photo must be in color</li>'
                                  + '<li>photo should be no older than 6 months, ideally as fresh as possible</li>'
                                  + '<li>photo should reflect your current appearance</li>'
                                  + '<li>photo shape - rectangular, 3x4, minimum resolution is 450x600px</li>'
                                  + '<li>face image should take up 70-80%</li>'
                                  + '<li>extension - JPEG, size - no more than 2MB</li>'
                                  + '<li>gaze should be clearly at the camera, facial expression natural and neutral, eyes open</li>'
                                  + '<li>Scans of photos from other documents are not allowed</li>'
                                  + '<li>any post-processing (anti-aliasing and filters)</li></ul>',

    },
    standVisitors        : {
      createStandVisitor        : 'Create stand visitor',
      badgeOrganizationNameLabel: 'Organization name for the badge',
      photoLabel                : 'Photo',
      wrongImageDimensions      : 'The photo must be a square with sides greater than or equal to 600x600 pixels',
      newStandVisitorDescription: '<p>Click on browse to upload new photo</p><ul>'
                                  + '<li>photo must be in color</li>'
                                  + '<li>photo should be no older than 6 months, ideally as fresh as possible</li>'
                                  + '<li>photo should reflect your current appearance</li>'
                                  + '<li>photo shape - rectangular, 3x4, minimum resolution is 450x600px</li>'
                                  + '<li>face image should take up 70-80%</li>'
                                  + '<li>extension - JPEG, size - no more than 2MB</li>'
                                  + '<li>gaze should be clearly at the camera, facial expression natural and neutral, eyes open</li>'
                                  + '<li>Scans of photos from other documents are not allowed</li>'
                                  + '<li>any post-processing (anti-aliasing and filters)</li></ul>',

    },
    // ##################
    login                             : {
      wrongLoginOrPassword: 'Invalid login or password',
    },
    organizationHasNoQuotas           : 'You have no quotas left',
    validations                       : {
      enterUsername            : 'Enter username/email',
      enterPassword            : 'Enter password',
      required                 : 'Required field',
      tooShort                 : 'Value must be at least {min} characters',
      tooLong                  : 'Value must be no more than {max} characters',
      email                    : 'The value must be a valid email address',
      url                      : 'The value must be a valid url',
      phone                    : 'Please enter a valid number',
      maxLengthWithoutSpaces   : 'Description must be no more than 1000 characters, excluding spaces',
      kppEqualsZeroOrLengthNine: 'KPP must consist of 9 digits or equal to 0 if the organization is not from the Russian Federation',
      innLength10or12          : 'TIN of the Russian Federation must consist of 10 (legal entity) or 12 (individual) digits',
      emailValidator           : 'The email address can contain only latin letters, numbers and symbols "@", "-", "_" and a dot.',
      fileTooBig               : 'The file is too large. Maximum size {max}',
      emailExistsError         : 'The specified email address already exists. Choose another.',
      sameAsPassword           : 'Passwords must be identical.',
    },
    personRegistrationTitle           : {
      default           : 'Participants registration',
      organizer         : 'Registration in the category "Organizer"',
      'developer-a'     : 'Registration in the category "Builder A"',
      security          : 'Registration in the category "Security"',
      staff             : 'Registration in the category "Personnel"',
      'press-b'         : 'Media registration',
      'free-dod-visitor': 'Registration in the category "Free visitor DOD"',
      'russian-railways': 'Visitor Questionnaire',
      'cfto'            : 'Анкета регистрации участника мероприятия ЦФТО',
      'mzdr'            : 'Анкета регистрации сотрудников Музея железных дорог России',
    },
    exhibitorRegistrationTitle        : 'Exhibitor registration form',
    coExhibitorRegistrationTitle      : 'Co-exhibitor registration form',
    absenteeExhibitorRegistrationTitle: 'Registration form for an absentee exhibitor',
    developerRegistrationTitle        : 'Registration form for the developer',
    participantBRegistrationTitle     : 'Participation in the business program',
    registrationForm                  : {
      commonError                      : 'Failed to complete registration. Please check the details in the form and try to register again. If the error persists, contact technical support.',
      mainTitle                        : 'Registration',
      step2title                       : 'Data for the contract',
      step3title1                      : 'Bank details',
      step3title2                      : 'Head of company',
      step4title1                      : 'Billing address',
      step4title2                      : 'Shipping address',
      step5title                       : 'Information for the online catalog',
      step6title                       : 'Sections of participation in the exhibition',
      step7title                       : 'Information about the contact person',
      nextStepBtn                      : 'Next',
      prevStepBtn                      : 'Previous',
      registerBtn                      : 'Register',
      innLabel                         : 'TIN',
      innPlaceholder                   : 'Tax Identification Number (TIN)',
      innChecking                      : 'Checking TIN ...',
      innExistsError                   : 'A company with this TIN is already registered in the database. Please contact the exhibition operator for more details.',
      innCheckError                    : 'TIN check failed',
      nameLabel                        : 'Your company name',
      namePlaceholder                  : 'Your company name',
      nameDescription                  : 'To be filled in English',
      opfLabel                         : 'The legal form of the company',
      opfPlaceholder                   : 'The legal form of the company',
      opfDescription                   : 'To be filled in English. Ex: JSC, LLC, GmbH',
      phoneLabel                       : 'Company phone',
      phoneDescription                 : 'Number in international format, starting with +. For example: +79998887766. After the correct input, the number format will be set automatically.',
      faxLabel                         : 'Company fax',
      faxDescription                   : 'Number in international format, starting with +. For example: +79998887766. After the correct input, the number format will be set automatically.',
      siteLabel                        : 'Company web site',
      sitePlaceholder                  : 'Company web site',
      siteDescription                  : 'Please enter a valid URL. For example: http://example.com',
      emailLabel                       : 'Company email',
      emailPlaceholder                 : 'Company email',
      emailDescription                 : 'Please enter a valid email. For example: user@example.com',
      payerCompany                     : {
        fullNameLabel                  : 'Full name of the paying company',
        fullNamePlaceholder            : 'Full name of the paying company',
        fullNameDescription            : 'Full name of the paying company',
        bankNameLabel                  : 'Bank name',
        bankNamePlaceholder            : 'Bank name',
        bankNameDescription            : 'Bank name',
        checkingAccountLabel           : 'Checking account',
        checkingAccountPlaceholder     : 'Checking account',
        checkingAccountDescription     : 'Checking account',
        correspAccountLabel            : 'Correspondent account',
        correspAccountPlaceholder      : 'Correspondent account',
        correspAccountDescription      : 'Correspondent account',
        bankBicLabel                   : 'SWIFT BIC',
        bankBicPlaceholder             : 'SWIFT BIC',
        bankBicDescription             : 'SWIFT BIC',
        bossPositionLabel              : 'Position',
        bossPositionPlaceholder        : 'Position',
        bossPositionDescription        : 'Position',
        bossSurnameLabel               : 'Last name',
        bossSurnamePlaceholder         : 'Last name',
        bossSurnameDescription         : 'Last name',
        bossNameLabel                  : 'First name',
        bossNamePlaceholder            : 'First name',
        bossNameDescription            : 'First name',
        bossMiddleNameLabel            : 'Middle name',
        bossMiddleNamePlaceholder      : 'Middle name',
        bossMiddleNameDescription      : 'Middle name',
        bossBasisOfAuthorityLabel      : 'Basis of authority',
        bossBasisOfAuthorityPlaceholder: 'Basis of authority',
        bossBasisOfAuthorityDescription: 'Basis of authority',
      },
      addressCountryLabel              : 'Country',
      addressCountryPlaceholder        : 'Country',
      addressZipLabel                  : 'Postal code',
      addressZipPlaceholder            : 'Postal code',
      addressLabel                     : 'District, town, street, house',
      addressPlaceholder               : 'District, town, street, house',
      postalAddressSameAsLegal         : 'Shipping address is the same as billing',
      exhibitor                        : {
        descriptionLabel      : 'Activity description, characters residue',
        descriptionPlaceholder: 'Activity description',
        descriptionDescription: 'Description must be no more than 1000 characters, excluding spaces',
        useAddressCheckbox    : 'For placing in the catalog use the following address',
        selectedAddress       : 'Selected address',
        logoLabel             : 'Logo',
        logoPlaceholder       : 'Drag logo or click here (*.png,*.jpg < 1Mb)',
      },
      alfaContact                      : {
        positionLabel        : 'Position',
        positionPlaceholder  : 'Position',
        positionDescription  : 'Position',
        surnameLabel         : 'Last name',
        surnamePlaceholder   : 'Last name',
        surnameDescription   : 'Last name',
        nameLabel            : 'First name',
        namePlaceholder      : 'First name',
        nameDescription      : 'First name',
        middleNameLabel      : 'Middle name',
        middleNamePlaceholder: 'Middle name',
        middleNameDescription: 'Middle name',
        phoneLabel           : 'Mobile phone',
        emailLabel           : 'E-mail',
        emailPlaceholder     : 'The email address will be used as a login'
      },
      alfaContactEmailChecking         : 'Checking email...',
      alfaContactEmailExistsError      : 'A contact with this Email is already registered in the database. Please choose a different email.',
      alfaContactEmailCheckError       : 'Failed to verify email',
      alfaContactHasErrors             : 'Incorrect data of the Registrar',
      exhibitorHasErrors               : 'Incorrect data of the Exhibitor',
      organizationHasErrors            : 'Organization details are incorrect',
      personalDataAgreement            : 'I agree to the processing of personal data',
      personalDataAgreementExtended    : 'I agree to the processing of personal data. I hereby confirm that I have received prior consent from the Participant(s) to process their personal data and I am his (their) authorized representative of the subject(s) of personal data.',
      personalDataConditions           : 'Personal data processing conditions',
      shouldAcceptPersonalDataAgreement: 'To continue registration, you must accept the terms of personal data processing',
      successfullyRegistrationTitle    : 'Registration completed successfully',
      successfullyRegistrationBody     : '<p>Thank you for registering. Your data has been successfully saved. An instructions on further actions will be sent to your email address.</p>'
                                         + '<p>We recommended to check Spam folder in case you have not received an e-mail or contact the Exhibition operator. info@railwayexpo.ru</p>',
    },
    checkingPromoCode                 : 'Checking promo code...',
    promoCodeNotFound                 : 'Invalid promo code entered',
    promoCodeAlreadyUsed              : 'The specified promo code has already been used',
    registrationPersonForm            : {
      successfullyRegistrationTitle        : 'Registration completed successfully',
      successfullyRegistrationBody         : '<p>Thank you for registering. An instructions on further actions will be sent to your email address.</p>'
                                             + '<p>We recommended to check Spam folder in case you have not received an e-mail or contact the Exhibition operator. ovr@rotexpo.moscow</p>',
      successfullyRegistrationWithBadgeBody: '<p>Thank you for registering. Badges have been sent to the specified email addresses.</p>'
                                             + '<p>If you haven\'t received an email, we recommend that you check your Junk Mail folder or contact your operator'
                                             + ' Exhibitions ovr@rotexpo.moscow</p>',
      successfullyRegistrationCfto         : '<p>Thank you for registering. The badge has been sent to your email address.</p>'
                                             + '<p>If you haven\'t received an email, we recommend that you check your Junk Mail folder or contact your operator'
                                             + ' Exhibitions ovr@rotexpo.moscow</p>',
      mediaAccreditationButton             : 'Mass media accreditation',
      mediaAccreditationText               : '<p>Accreditation order:</p>'
                                             + '<ul><li>To apply for your media accreditation, you must fill out a registration form.</li>'
                                             + '<li>The registration form is filled in for each participant separately.</li>'
                                             + '<li>After confirmation of your accreditation, you will be sent a separate letter with a badge in the "Press" category.'
                                             + '</li></ul>'
                                             + '<p>Please note that the confirmation of accreditation is the receipt of the badge to the mail you specified.</p>'
                                             + '<p>For ACCREDITATION AND TERMS OF COOPERATION PLEASE CONTACT:<br>'
                                             + 'Anna Kofman<br>'
                                             + 'Marketing and PR Manager<br>'
                                             + 'Tel: +7 (495) 909-08-18 ext. 71-786<br>'
                                             + 'E-mail: a.kofman@gudok.ru</p>',
      pressGreetingsText                   : '<p>Dear colleagues!</p>'
                                             + '<p>Thank you for your interest in International Salon of space 1520 '
                                             + 'PRO//Motion.EXPO. Applications for accreditation are accepted until 18.08.2023</p>',
      promoCodePlaceholder                 : 'Promo code',
      addContact                           : 'Add member',
      memberEmailDescription               : 'A personal badge will be sent to the specified address',
      memberPhotoDescription               : '<ul><li>Photo size 45 x 35 mm.</li>'
                                             + '<li>The size of the face must not be less than 80%</li>'
                                             + '<li>The image must be on a uniform light background</li>'
                                             + '<li>The photo must be taken from the front</li>'
                                             + '<li>Photo date must not exceed 6 months</li></ul>',
    },
    personalDataAgreement             : '<p><b>Consent to personal data processing</b></p>'
                                        + '    <p>I hereby, hereinafter referred to as the "Personal Data Subject", in accordance with Article 9 of the of Federal'
                                        + '      Law No. 152-FZ of July 27, 2006 "On Personal Data" for the purpose of registration of the Subject of Personal Data'
                                        + '      on the website http://railwayexpo. ru/ (hereinafter referred to as the Site) with subsequent forwarding of mail'
                                        + '      messages from "Gudok" Publishing House" JSC to the Subject of Personal Data, and for the purpose of confirming the'
                                        + '      identity of the Personal Data Subject I give my consent to "Gudok" Publishing House" JSC (TIN 7701660262, address:'
                                        + '      105066, Russia, Moscow, 38/2 Staraya Basmannaya street, building 3) (hereinafter referred to as the Company) and'
                                        + '      "ROTEX" LLC (TIN 7704787852, address: 115035, Russia, Moscow, 71 Sadovnicheskaya street, block 1, room 7, floor'
                                        + '      4), which was involved by the Company for organization of the relevant event, and which was charged with'
                                        + '      processing of personal data, as well as affiliates of the Company, in compliance with the requirements of the'
                                        + '      Federal Law No. 152-FZ of July 27, 2006 "On Personal Data" (with amendments and additions) for processing of my'
                                        + '      personal data: last name, first name, patronymic; date and place of birth, citizenship, education, address,'
                                        + '      position, contact information (phone, fax, email, postal address), specified during registration by filling out'
                                        + '      the web form on the Site, sent (filled in) via the Site.</p>'
                                        + '    <p>I consent to my personal data processing by the Company, that is, to perform, among other things, the following'
                                        + '      actions: processing, collection, systematization, accumulation, clarification, updating, change, use,'
                                        + '      distribution, transfer, including cross-border, depersonalization, blocking, destruction, indefinite storage,'
                                        + '      while a general description of the above data processing methods is given in the Federal Law No. 152-FZ of July'
                                        + '      27, 2006, as well as the transfer of such information to third parties, in cases established by regulatory'
                                        + '      documents of higher authorities and legislation.</p>'
                                        + '    <p>I consent to the use of my biometric personal data in the form of a photograph for the purposes of access to the'
                                        + '      venue of the International railway fair in the area 1520 "PRO//Motion.EXPO", business and other events held as'
                                        + '      part of it.</p>'
                                        + '    <p>The date of issue of consent to the processing of personal data of the Subject of Personal Data is the date of'
                                        + '      sending the registration web form on the Site. This consent to the processing of personal data is valid until the'
                                        + '      objectives of personal data processing are achieved, but not less than 1 (one) year from the date of registration'
                                        + '      on the Site, or until the day of its revoke.</p>'
                                        + '    <p>"Gudok" Publishing House" JSC takes the necessary legal, organizational and technical measures or ensures their'
                                        + '      adoption to protect personal data from unauthorized or accidental access to them, destruction, modification,'
                                        + '      blocking, copying, provision, distribution of personal data, as well as assumes the obligation to ensure'
                                        + '      confidentiality of personal data of the Personal Data Subject.</p>'
                                        + '    <p>I am aware that the consent to the processing of personal data can be revoked by me on the basis of a written'
                                        + '      application in any form, sent to the Company.</p>'
  },
}