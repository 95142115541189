import axios, {AxiosInstance} from 'axios';
import store from '@/store';
import {removeAuth} from 'Global/helpers/localStorage';
import {saveAs} from 'file-saver';
import Vue from 'vue';
import app from '@/main';
import router from "@/router";

/**
 * Создание запроса
 *
 * @type {AxiosInstance}
 */
const request = axios.create({
  baseURL: process.env.VUE_APP_ADMIN_API_URL
});

/**
 * Настройка перед отправкой запроса
 */
request.interceptors.request.use(config => {
  if (store.getters.user && store.getters.token) {
    config.headers['USER-EMAIL'] = store.getters.user;
    config.headers['AUTH-TOKEN'] = store.getters.token;
  }

  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }
  else {
    config.headers['Content-Type'] = 'application/json; charset=UTF-8';
  }

  let page = window.location.href;
  if (page) {
    config.headers['X-HWM-Page'] = page;
  }

  return config;
}, error => {
  // Do something with request error
  console.error(error); // for
  // debug
  return Promise.reject(error);
});

/**
 * Настройка действий после получения ответа
 */
request.interceptors.response.use(
  response => {
    return response.data;
  },
  async error => {
    let msg;
    let data;

    if (
      error.response &&
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {

      try {
        data = JSON.parse(await error.response.data.text());
        msg = data.message;
      }
      catch (e) {
        msg = 'Произошла непредвиденная ошибка';
        data = {
          message: msg
        };
      }
    }
    else if (error.response) {
      msg = error.response.data.message;
      data = error.response.data;
    }
    else {
      msg = error.message;
      data = error.message;
    }

    if (error.response.status === 401) {
      removeAuth();

      if (router.currentRoute.name !== 'login') {
        window.location.reload();
      }
    }
    else if (error.response.status === 403) {
      return Promise.reject(data);
    }
    else {
      let msgArray = [];
      if (msg instanceof Object) {
        Object.keys(msg)
          .forEach(key => {
            if (Array.isArray(msg[key])) {
              msgArray.push(msg[key].join('\n'));
            }
            else {
              msgArray.push(msg[key])
            }
          });
      }
      else {
        msgArray = [msg];
      }

      // app.$bvToast.toast(msgArray.join('\n'), {
      //   title  : `Ошибка запроса`,
      //   variant: 'danger',
      //   solid  : true,
      //   toaster: 'b-toaster-top-right'
      // });
    }

    return Promise.reject(data);
  });

/**
 * Экспорт экземпляра axios
 */
export {request};

/**
 * Список всех элементов
 *
 * @param route
 * @param filter
 * @param perPage
 * @param page
 * @param sort
 * @param action
 * @return {Promise<AxiosResponse<T>>}
 */
export function getItems(route, filter, perPage, page, sort, action = null) {
  let data = {
    filter  : filter || {},
    per_page: perPage || 20,
    page    : page || 1,
    sort    : sort || false
  };

  if (!action) {
    action = 'index';
  }

  return request.post(`/${route}/${action}`, data);
}

/**
 * Список всех элементов укороченной информацией [id, title]
 *
 * @param route
 * @param data
 * @param {string, array, null} fields
 * @param reorderBy
 * @return {Promise<AxiosResponse<T>>}
 */
export function getItemsList(route, data, fields = undefined, reorderBy = undefined) {
  let params;
  params = !Vue._.isObject(data) ? {query: data} : data;

  if (fields) {
    params['fields'] = fields;
  }

  if (reorderBy) {
    params['reorder_by'] = reorderBy;
  }

  return request.post(`/${route}/list`, params, {disableNotFound: true});
}

/**
 * Информация об элементе
 *
 * @param route
 * @param data
 * @param commonFieldsOnly
 * @return {Promise<AxiosResponse<T>>}
 */
export function getItem(route, data, commonFieldsOnly = false) {
  if (!Vue._.isObject(data)) {
    data = {id: data};
  }

  if (commonFieldsOnly === true) {
    data['common_fields_only'] = true;
  }

  return request.post(`/${route}/view`, data);
}

/**
 * Создание элемента
 *
 * @param route
 * @param data
 * @return {Promise<AxiosResponse<T>>}
 */
export function createItem(route, data) {
  return request.post(`/${route}/create`, data);
}

/**
 * Изменение элемента
 *
 * @param route
 * @param id
 * @param data
 * @return {Promise<AxiosResponse<T>>}
 */
export function updateItem(route, id, data) {
  return request.patch(`/${route}/${id}`, data);
}

/**
 * Удаление элемента
 *
 * @param route
 * @param id
 * @return {Promise<AxiosResponse<T>>}
 */
export function deleteItem(route, id) {
  return request.delete(`/${route}/${id}`);
}

/**
 * Клонирование элемента
 *
 * @param route
 * @param data
 * @return {Promise<AxiosResponse<T>>}
 */
export function cloneItem(route, data) {
  return request.post(`/${route}/clone`, data);
}

/**
 * Проверка на удаление
 *
 * @param route
 * @param data
 * @return {Promise<AxiosResponse<T>>}
 */
export function checkDeleteItem(route, data) {
  if (!Vue._.isObject(data)) {
    data = {id: data};
  }

  return request.post(`/${route}/check-delete`, data);
}

/**
 * Скачивание файла через POST-запрос и выдача в браузер для сохранения на файловую систему
 *
 * @param path
 * @param data
 * @param mimeType
 * @param filename
 * @return {Promise<any>}
 */
export async function downloadFile(path, data, mimeType, filename) {
  return new Promise((fulfill, reject) => {
    request.post(path, data, {responseType: 'blob'})
      .then((pdfData) => {
        let blob = new Blob([pdfData], {type: mimeType});
        saveAs(blob, filename);
        fulfill();
      })
      .catch(() => {
        reject();
      });
  });
}
