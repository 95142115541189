import {downloadFile, request} from '@/helpers/request';

const controllerPath = 'site/organization';

export function savePayerDetails(data) {
  return request.post(`${controllerPath}/save-payer-details`, data);
}

export function saveContactDetails(data) {
  return request.patch(`${controllerPath}/save-contact-details`, data);
}

export function saveCatalogInfo(data) {
  return request.post(`${controllerPath}/save-catalog-info`, data);
}

export function saveCatalogInfoSlide(data) {
  return request.post(`${controllerPath}/save-catalog-info-slide`, data);
}

export function deleteCatalogInfoSlide(id) {
  return request.post(`${controllerPath}/delete-catalog-info-slide`, {id});
}

export function sendInvitation(data) {
  return request.post(`${controllerPath}/send-invitation`, data);
}

export function getParticipantsAndBadges(data) {
  return request.post(`${controllerPath}/get-participants-and-badges`, data);
}

export function getParticipant(data) {
  return request.post(`${controllerPath}/get-participant`, data);
}

export function createParticipant(data) {
  return request.post(`${controllerPath}/create-participant`, data);
}

export function updateParticipant(id, data) {
  return request.post(`${controllerPath}/${id}/update-participant`, data);
}

export function deleteParticipant(id) {
  return request.post(`${controllerPath}/${id}/delete-participant`);
}

// Stand visitors
export function getStandVisitors(data) {
  return request.post(`${controllerPath}/get-stand-visitors`, data);
}

export function getStandVisitor(data) {
  return request.post(`${controllerPath}/get-stand-visitor`, data);
}

export function createStandVisitor(data) {
  return request.post(`${controllerPath}/create-stand-visitor`, data);
}

export function updateStandVisitor(id, data) {
  return request.post(`${controllerPath}/${id}/update-stand-visitor`, data);
}

export function deleteStandVisitor(id) {
  return request.post(`${controllerPath}/${id}/delete-stand-visitor`);
}

// END stand visitors

// Mounting passes
export function getMountingPassContacts(data) {
  return request.post(`${controllerPath}/get-mounting-passes`, data);
}

export function getMountingPassContact(data) {
  return request.post(`${controllerPath}/get-mounting-pass`, data);
}

export function createMountingPassContact(data) {
  return request.post(`${controllerPath}/create-mounting-pass`, data);
}

export function updateMountingPassContact(id, data) {
  return request.post(`${controllerPath}/${id}/update-mounting-pass`, data);
}

export function deleteMountingPassContact(id) {
  return request.post(`${controllerPath}/${id}/delete-mounting-pass`);
}

// END Mounting passes

// Promoters
export function getPromoters(data) {
  return request.post(`${controllerPath}/get-promoters`, data);
}

export function getPromoter(data) {
  return request.post(`${controllerPath}/get-promoter`, data);
}

export function createPromoter(data) {
  return request.post(`${controllerPath}/create-promoter`, data);
}

export function updatePromoter(id, data) {
  return request.post(`${controllerPath}/${id}/update-promoter`, data);
}

export function deletePromoter(id) {
  return request.post(`${controllerPath}/${id}/delete-promoter`);
}

export function getPromoterQuotas(id) {
  return request.post(`${controllerPath}/get-promoter-quotas`);
}

// END Promoters

export function getBadgeQuotas() {
  return request.post(`site/badge/get-quotas`);
}

export function getPaidVisitorBadges(data) {
  return request.post(`${controllerPath}/get-paid-visitor-badges`, data);
}

export function getPaidVisitorQuotas() {
  return request.post(`site/badge/get-paid-visitor-quotas`);
}

export function getPaidVisitor(data) {
  return request.post(`${controllerPath}/get-paid-visitor`, data);
}

export function createPaidVisitor(data) {
  return request.post(`${controllerPath}/create-paid-visitor`, data);
}

export function updatePaidVisitor(id, data) {
  return request.post(`${controllerPath}/${id}/update-paid-visitor`, data);
}

export function deletePaidVisitor(id) {
  return request.post(`${controllerPath}/${id}/delete-paid-visitor`);
}

export function downloadBadge(contact) {

  const filename = `${contact.surname}_${contact.name}`
    .replace(/[^а-яА-Яa-zA-Z0-9]/gi, '_').toLowerCase();

  return downloadFile(
    `site/badge/${contact.id}/download`,
    {},
    'application/pdf',
    `${filename}.pdf`
  );
}