import * as API from '@/helpers/request';

const controllerPath = 'site/exhibitor';

export function getCoExhibitors() {
  return API.request.post(`${controllerPath}/get-co-exhibitors`)
}

export function getByProfile(profileId) {
  return API.request.post(`${controllerPath}/get-by-profile/${profileId}`)
}

export function delegateBadges(data) {
  return API.request.post(`site/badge/delegate`, data)
}

export function buyBadges(data) {
  return API.request.post(`site/badge/buy`, data)
}
