const getters = {
  sidebar         : state => state.app.sidebar,
  organization    : state => state.organization.organization,
  exhibitor       : state => state.organization.exhibitor,
  userInfo        : state => state.organization.userInfo,
  profile         : state => state.organization.profile,
  contact         : state => state.app.contact,
  breadcrumb      : state => state.app.breadcrumbVisible,
  showGlobalScroll: state => state.app.showGlobalScroll,
  footer          : state => state.app.footerVisible,
  formIsChanged   : state => state.app.formIsChanged,
  showLoader      : state => state.app.showLoader,
  language        : state => state.app.language,
  constants       : state => state.app.constants,
  token           : state => state.user.token,
  user            : state => state.user.user,
  userId          : state => state.user.id,
  appId           : state => state.app.constants['Global::APP_ADMIN'],
  isSuperadmin    : state => Boolean(state.user.superadmin),
  errorLogs       : state => state.errorLog.logs,
  checkPermission : state => (perm, isNot = false, positiveSuperadmin = false) => {
    if (!perm || (perm instanceof String && typeof perm !== 'string')) {
      return !!isNot;
    }

    if (state.user.superadmin === true) {
      return positiveSuperadmin ? true : !isNot;
    }

    if (!Array.isArray(perm)) {
      perm = [perm];
    }

    if (perm[0].toUpperCase() === 'OR') {
      perm.shift();

      return perm.some(value => {
        let res = state.user.permissions.includes(value);
        return isNot ? !res : res;
      });
    }

    return perm.every(value => {
      let res = state.user.permissions.includes(value);
      return isNot ? !res : res;
    });
  },
  checkRole       : state => role => {
    if (!role || (role instanceof String && typeof role !== 'string')) {
      return false;
    }

    if (state.user.superadmin === true) {
      return true;
    }

    if (!Array.isArray(role)) {
      role = [role];
    }

    if (role[0].toUpperCase() === 'OR') {
      role.shift();

      return role.some(value => {
        return state.user.roles.includes(value);
      });
    }

    return role.every(value => {
      return state.user.roles.includes(value);
    });
  },

  badge          : state => state.badge,
  isMobile       : state => state.app.isMobile,
  conferenceTable: state => state.conferenceTable
};

export default getters;
