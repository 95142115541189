import * as API from '@/helpers/request';

const controllerPath = 'site/conference-table';

export function getInterestInExhibitionList(data) {
  return API.request.post(`${controllerPath}/get-interest-in-exhibition-list`, data);
}

export function getPositionInCompanyList(data) {
  return API.request.post(`${controllerPath}/get-position-in-company-list`, data);
}

export function getCompanyFunctionalList(data) {
  return API.request.post(`${controllerPath}/get-company-functional-list`, data);
}

export function getPurposeOfVisitingList(data) {
  return API.request.post(`${controllerPath}/get-purpose-of-visiting-list`, data);
}

export function getVisitedExhibitionBeforeList(data) {
  return API.request.post(`${controllerPath}/get-visited-exhibition-before-list`, data);
}

export function saveProfile(data) {
  return API.request.post(`${controllerPath}/save-profile`, data);
}

export function saveCalendar(data) {
  return API.request.post(`${controllerPath}/save-calendar`, data);
}

export function getRecommendedContacts(filter, perPage, page) {
  return API.getItems(controllerPath, filter, perPage, page, false, 'get-recommended-contacts');
}

export function getFavoriteContacts(filter, perPage, page) {
  return API.getItems(controllerPath, filter, perPage, page, false, 'get-favorite-contacts');
}

export function getRejectedContacts(filter, perPage, page) {
  return API.getItems(controllerPath, filter, perPage, page, false, 'get-rejected-contacts');
}

export function getCountryList(data) {
  return API.request.post(`${controllerPath}/country-list`, data);
}

export function getSectionList(data) {
  return API.request.post(`${controllerPath}/section-list`, data);
}

export function addToFavorites(data) {
  return API.request.post(`${controllerPath}/add-to-favorites`, data);
}

export function addToRejected(data) {
  return API.request.post(`${controllerPath}/add-to-rejected`, data);
}

export function meetingStatusList(locale) {
  return API.request.post(`${controllerPath}/meeting-status-list`, {locale});
}

export function sendMeetingRequest(profileId, data) {
  return API.request.post(`${controllerPath}/send-meeting-request/${profileId}`, data);
}

export function changeMeetingStatus(id, status) {
  return API.request.post(`${controllerPath}/change-meeting-status/${id}`, {status});
}

export function sendChatRequest(profileId) {
  return API.request.post(`${controllerPath}/send-chat-request/${profileId}`);
}

export function getChatRooms(profileId) {
  return API.request.post(`${controllerPath}/get-chat-rooms/${profileId}`);
}

export function getChatMessages(roomId) {
  return API.request.post(`${controllerPath}/get-chat-messages/${roomId}`);
}