<template>
  <div id="admin-client">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'admin-client',
  async mounted() {
    await this.$store.dispatch('mobileAndTabletsCheck')

    addEventListener("resize", () => {
      this.$store.dispatch('mobileAndTabletsCheck')
    });
  }
};
</script>

<style>
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
  url(../public/fonts/montserrat.ttf) format("truetype");
}
</style>
