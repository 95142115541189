import {getValue} from './SettingsStore';

export default class SettingsProxyFactory {
	/**
	 * Возвращает прокси для работы с настройками
	 */
	static getProxy(settings) {
		return new Proxy(settings, {
			// Геттер для модулей
			get(target1, moduleName, receiver1) {
				const moduleSettings = Reflect.get(target1, moduleName, receiver1);
				if (typeof moduleSettings === 'undefined') {
					console.error('Обращение к настройкам несуществующего модуля ', {
						target1,
						name1 : moduleName,
						receiver1
					});
					return undefined;
				}

				// Геттер для приложений
				return new Proxy(moduleSettings, {
					get(target2, appName, receiver2) {
						const appSettings = Reflect.get(target2, appName, receiver2);

						if (typeof appSettings === 'undefined') {
							console.error('Обращение к настройкам несуществующего приложения ', {
								target2,
								name2 : appName,
								receiver2
							});
							return undefined;
						}

						// Геттер для значений
						return new Proxy(appSettings, {
							get(target, settingName, receiver) {
								let rv = Reflect.get(target, settingName, receiver);

								if (typeof rv === 'undefined') {
									console.error('Обращение к несуществующей настройке', {
										moduleName,
										appName,
										settingName
									});
									return undefined;
								}

								return getValue(moduleName, appName, settingName);
							}
						});

					}
				});

			}
		});
	}
}
