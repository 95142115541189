import * as API from '@/helpers/request';
import {AxiosResponse} from 'axios';

/**
 * Авторизация пользователя
 *
 * @param user
 * @param pass
 * @returns {Promise<AxiosResponse<T>>}
 */
export function login(user, pass) {
  return API.request.post('/login', {
    email   : user,
    password: pass
  });
}

/**
 * Выход пользователя
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function logout() {
  return API.request.post('/logout');
}

/**
 * Получение списка прав
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getPermissions() {
  return API.request.post('/permissions');
}

/**
 * Получения массива бокового меню
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getSidebarMenu(organizationType) {
  return API.request.post('/site/sidebar-menu', {organizationType});
}

/**
 * Получения организации
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getOrganization() {
  return API.request.post('/site/get-organization');
}

export function getExhibitor() {
  return API.request.post('/site/get-exhibitor');
}

/**
 * Получения контакта
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getContact() {
  return API.request.post('/site/get-contact');
}

/**
 * Получения user
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getUserInfo() {
  return API.request.post('/site/get-user-info');
}

/**
 * Получения user
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getProfile() {
  return API.request.post('/site/get-profile');
}

/**
 * Получение настроек приложения
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getSettings() {
  return API.request.post('/settings');
}

/**
 * Получение констант
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getConstants() {
  return API.request.post('/constants');
}

export function getBookingTime() {
  return API.request.post('/site/get-booking-time');
}