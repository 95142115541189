const IdKey               = 'id';
const UserKey             = 'user';
const TokenKey            = 'token';
const CartKey             = 'cart';
const CartCounterpartyKey = 'cart_counterparty';
const SalesChannelKey     = 'sales_channel';

/**
 * Установка параметра
 *
 * @param name  - название
 * @param value - значение
 */
export function setLocalStorageParam(name, value) {
	value = value !== null && value !== undefined ? value : '';

	if (typeof value === 'string' && (value === '{' || value === '[')) {
		value = value.substring(1);
	}

	if (typeof value === 'object') {
		value = JSON.stringify(value);
	}

	window.localStorage.setItem(name, value);
}

/**
 * Получение параметра
 *
 * @param name         - название
 * @param defaultValue - значение п умолчанию
 */
export function getLocalStorageParam(name, defaultValue) {
	let result = window.localStorage.getItem(name) || defaultValue || null;

	if (result && typeof result === 'string' && (result[0] === '{' || result[0] === '[')) {
		try {
			result = JSON.parse(result);
		}
		catch (e) {
			result = defaultValue || null;
		}
	}

	return result;
}

/**
 * Удаление параметра
 *
 * @param name - название
 */
export function removeLocalStorageParam(name) {
	window.localStorage.removeItem(name);
}

/**
 * Получить ID пользователя
 *
 * @returns {string}
 */
export function getId() {
	return window.localStorage.getItem(IdKey);
}

/**
 * Установить ID пользователя
 *
 * @param id
 */
export function setId(id) {
	window.localStorage.setItem(IdKey, id);
}

/**
 * Удалить ID пользователя
 */
export function removeId() {
	window.localStorage.removeItem(IdKey);
}

/**
 * Получить token пользователя
 *
 * @returns {string}
 */
export function getToken() {
	return window.localStorage.getItem(TokenKey);
}

/**
 * Установить token пользователя
 *
 * @param token
 */
export function setToken(token) {
	window.localStorage.setItem(TokenKey, token);
}

/**
 * Удалить token пользователя
 */
export function removeToken() {
	window.localStorage.removeItem(TokenKey);
}

/**
 * Получить имя пользователя
 *
 * @returns {string}
 */
export function getUser() {
	return window.localStorage.getItem(UserKey);
}

/**
 * Установить имя пользователя
 *
 * @param user
 */
export function setUser(user) {
	window.localStorage.setItem(UserKey, user);
}

/**
 * Удалить имя пользователя
 */
export function removeUser() {
	window.localStorage.removeItem(UserKey);
}

/**
 * Получить авторизационную информацию о пользователе
 *
 * @returns {{id: string, token: string, user: string}}
 */
export function getAuth() {
	return {
		id    : getId(),
		token : getToken(),
		user  : getUser()
	};
}

/**
 * Установить авторизационную информацию о пользователе
 *
 * @param id
 * @param user
 * @param token
 */
export function setAuth(id, user, token) {
	setId(id);
	setUser(user);
	setToken(token);
}

/**
 * Удалить авторизационную информацию о пользователе
 */
export function removeAuth() {
	removeId();
	removeUser();
	removeToken();
	setShiftlessMode(false);
}

/**
 * Получить ID корзины
 *
 * @returns {string}
 */
export function getCartId() {
	return window.localStorage.getItem(CartKey);
}

/**
 * Установить ID корзины
 *
 * @param id
 */
export function setCartId(id) {
	window.localStorage.setItem(CartKey, id);
}

/**
 * Удалить ID корзины
 */
export function removeCartId() {
	window.localStorage.removeItem(CartKey);
}

/**
 * Получить ID канала продаж
 *
 * @returns {string}
 */
export function getSalesChannelUID() {
	return getLocalStorageParam(SalesChannelKey);
}

/**
 * Установить ID канала продаж
 *
 * @param id
 */
export function setSalesChannelUID(id) {
	setLocalStorageParam(SalesChannelKey, id);
}

/**
 * Удалить ID канала продаж
 */
export function removeSalesChannelUID() {
	window.localStorage.removeItem(SalesChannelKey);
}

/**
 * Устанавливает режим shiftlessMode (вход в интерфейс без открытия смены)
 *
 * @param enabled
 */
export function setShiftlessMode(enabled) {
	if (enabled) {
		window.localStorage.setItem('shiftlessMode', 'true');
	}
	else {
		window.localStorage.removeItem('shiftlessMode');
	}
}

/**
 * Выясняет, включен ли режим входа в интерфейс без открытия смены (shiftlessMode)
 *
 * @returns {boolean}
 */
export function getShiftlessMode() {
	return window.localStorage.getItem('shiftlessMode') === 'true';
}