import * as API from '@/helpers/request';

const controllerPath = 'setting';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItem(data) {
  return API.getItem(controllerPath, data)
}

export function nowGreaterOrEqualThenValue(id) {
  return API.request.post(`${controllerPath}/now-greater-or-equal-then-value/${id}`)
}

export function nowBetweenPeriod(id) {
  return API.request.post(`${controllerPath}/now-between-period/${id}`)
}

export function downloadFile(id, mime, filename) {
  return API.downloadFile(
    `${controllerPath}/download-file`,
    {id},
    mime,
    filename
  );
}