const schemeEditor = {
	state     : {
		showGrid               : false,
		showToaster            : true,
		showBorder             : true,
		rounded                : false,
		showNumber             : true,
		showRowNumber          : true,
		selectedTool           : 'PAN_TOOL',
		selectedSeats          : [],
		action                 : null,
		newElement             : null,
		selectedElements       : [],
		selectedElementsEdited : null,
		info                   : {},
		preSelectedSeats       : [],
		selectedSeatsApproved  : []
	},
	mutations : {
		SET_TOOL                     : (state, tool) => {
			state.selectedTool = tool;
		},
		SHOW_GRID                    : (state, value) => {
			state.showGrid = value;
		},
		SHOW_TOASTER                 : (state, value) => {
			state.showToaster = value;
		},
		SHOW_BORDER                  : (state, value) => {
			state.showBorder = value;
		},
		SHOW_NUMBER                  : (state, value) => {
			state.showNumber = value;
		},
		SET_SELECTED_SEATS           : (state, selectedSeats) => {
			state.selectedSeats = selectedSeats;
		},
		SET_ACTION                   : (state, action) => {
			state.action = action;
		},
		SET_NEW_ELEMENT              : (state, newElement) => {
			state.newElement = newElement;
		},
		SET_SELECTED_ELEMENTS        : (state, selectedElements) => {
			state.selectedElements = selectedElements;
		},
		SET_SELECTED_ELEMENTS_EDITED : (state, selectedElementsEdited) => {
			state.selectedElementsEdited = selectedElementsEdited;
		},
		SET_INFO                     : (state, info) => {
			state.info = info;
		},
		SET_PRESELECTED_SEATS        : (state, preSelectedSeats) => {
			state.preSelectedSeats = preSelectedSeats;
		},
		SET_SELECTED_SEATS_APPROVED  : (state, selectedSeatsApproved) => {
			state.selectedSeatsApproved = selectedSeatsApproved;
		},
		SHOW_ROW_NUMBER              : (state, showRowNumber) => {
			state.showRowNumber = showRowNumber;
		}
	},
	actions   : { }
};

export default schemeEditor;
