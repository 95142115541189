import * as API from '@/helpers/request';
import {request} from "@/helpers/request";

const controllerPath = 'email-template';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export function getItem(data) {
  return API.getItem(controllerPath, data);
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}

export function getSystemEventList() {
  return request.post(controllerPath + '/system-event-list');
}
