import Vue                                                                        from 'vue';
import searchItem                                                                 from '../helpers/cartItems';
import { removeCartId, setCartId }                                                from '../helpers/localStorage';
import { request }                                                                from '@/helpers/request';
import { clear as clearCart, getItems as getCartItems, setItems as setCartItems } from '../api/cart';

const cartStore = {
	state     : {
		/**
		 * Хранит порядковый номер состояния корзины
		 */
		stateSerial                : 0,
		count                      : 0,
		countTickets               : 0,
		countCommodities           : 0,
		personCount                : 0,
		countWithChild             : 0,
		limit                      : null,
		validityEnd                : null,
		validity                   : null,
		validityMinutes            : null,
		validitySeconds            : null,
		validityNotifyTime         : 60 * 3,
		sum                        : 0,
		sumTickets                 : 0,
		sumCommodities             : 0,
		items                      : [],
		errors                     : [],
		error_items                : [],
		saveTimer                  : null,
		changing                   : false,
		saving                     : false,
		validityTimer              : null,
		promo_code                 : null,
		orderNumber                : null,
		counterparty               : {
			id                            : null,
			title                         : null,
			inn                           : null,
			disallow_booking_zero_balance : null
		},
		has_pushkincard_items      : false,
		can_be_paid_by_pushkincard : false,
		checkCounterparty          : true,
		returnToMain               : { label : 'Вернуться на главную', route : { path : '/' } },
		loaded                     : false,
		queue                      : []
	},
	getters   : {
		cartCounterparty           : state => state.counterparty,
		cartValidity               : state => state.validity,
		cartValidityMinutes        : state => state.validityMinutes,
		cartValiditySeconds        : state => state.validitySeconds,
		cartSaving                 : state => state.saving,
		cartChanging               : state => state.changing,
		cartLimit                  : state => state.limit,
		cartIsLimitReached         : (state) => {
			return state.limit !== null && state.count !== null && (+state.limit) <= state.count;
		},
		cartOrderNumber            : state => state.orderNumber,
		cartLoaded                 : state => state.loaded,
		cartError                  : (state, getters) => {
			let error = false;

			state.items.every(item => {
				if (item.sales_status && item.sales_status !== getters.constants['Global::SALES_STATUS__AVAILABLE']) {
					error = true;
					return false;
				}

				return true;
			});

			return error;
		},
		cartPromoCode              : state => state.promo_code,
		cartItems                  : state => state.items,
		cartFindItem               : (state, getters) => (item, childItem = null) => searchItem(getters, {
			items       : state.items,
			item,
			childItem,
			noCreate    : true,
			forContract : Boolean(state.counterparty.id)
		}),
		cartCount                  : state => state.count,
		cartCommodityCount         : state => state.countCommodities,
		cartTicketCount            : state => state.countTickets,
		cartPersonCount            : state => state.personCount,
		cartCountWithChild         : state => state.countWithChild,
		cartSum                    : state => state.sum,
		cartCommoditySum           : state => state.sumCommodities,
		cartTicketSum              : state => state.sumTickets,
		cartSumOriginal            : (state, getters) => {
			let sum = 0;

			Vue._.each(getters.cartItems, (cartItem) => {
				sum += +cartItem.count * (cartItem.hasOwnProperty('original_price') ? +cartItem.original_price : +cartItem.price);

				if (cartItem.child_items) {
					Vue._.each(cartItem.child_items, childItem => {
						sum += +childItem.count * (childItem.hasOwnProperty('original_price') ? +childItem.original_price : +childItem.price);
					});
				}
			});

			return sum;
		},
		cartContractAmount         : state => (counterpartyId, contractId) => {
			let amount = 0;

			if (state.checkCounterparty && state.counterparty.id != counterpartyId) {
				return amount;
			}

			Vue._.each(state.items, cartItem => {
				if (!cartItem.count) {
					return true;
				}

				if (!contractId || cartItem.contract_id == contractId) {
					amount += (+cartItem.count || 1) * (+cartItem.price);
				}

				if (cartItem.child_items && cartItem.child_items.length) {
					Vue._.each(cartItem.child_items, childItem => {
						if (!childItem.count) {
							return true;
						}

						if (!contractId || childItem.contract_id == contractId) {
							amount += (+childItem.count) * (+childItem.price);
						}
					});
				}
			});

			return amount;
		},
		groupedCartItems           : state => {
			let ret = {};

			Vue._.each(state.items, item => {
				if (!item.count) {
					return true;
				}

				let index = `${item.event_id || 'NULL'}_${item.abonement_id || 'NULL'}_${item.excursion_id || 'NULL'}_${item.service_id || 'NULL'}`;

				if (!ret[index]) {
					ret[index] = {
						info  : item,
						count : 0,
						total : 0
					};
				}

				let categoryItem = {
					title : item.social_category_title,
					count : +item.count,
					price : +item.price,
					total : (+item.price) * (+item.count)
				};

				ret[index].count += categoryItem.count;
				ret[index].total += categoryItem.total;

				let childItems = null;
				if (item.child_items && item.child_items.length) {
					Vue._.each(item.child_items, childItem => {
						if (!childItems) {
							childItems = [];
						}

						childItems.push({
							title : childItem.social_category_title,
							count : +childItem.count,
							price : +childItem.price,
							total : (+childItem.price) * (+childItem.count)
						});

						ret[index].count += childItem.count;
						ret[index].total += childItem.total;
					});
				}

				if (item.date) {
					let dateIndex = `${item.date || 'NULL'}_${item.time || 'NULL'}`;

					if (!ret[index]['dates']) {
						ret[index].dates = {};
					}

					if (!ret[index].dates[dateIndex]) {
						ret[index].dates[dateIndex] = {
							date       : item.date || null,
							time       : item.time || null,
							count      : 0,
							total      : 0,
							categories : []
						};
					}

					ret[index].dates[dateIndex].count += categoryItem.count;
					ret[index].dates[dateIndex].total += categoryItem.total;
					ret[index].dates[dateIndex].categories.push(categoryItem);

					if (childItems) {
						if (!ret[index].dates[dateIndex]['child_items']) {
							ret[index].dates[dateIndex]['child_items'] = {
								count      : 0,
								total      : 0,
								categories : []
							};
						}

						Vue._.each(childItems, childItem => {
							ret[index].dates[dateIndex].child_items.count += childItem.count;
							ret[index].dates[dateIndex].child_items.total += childItem.total;
							ret[index].dates[dateIndex].child_items.categories.push(childItem);
						});
					}
				}
				else {
					if (!ret[index].categories) {
						ret[index]['categories'] = [];
					}

					ret[index].categories.push(categoryItem);
				}
			});

			Vue._.each(ret, (item, index) => {
				if (item.dates) {
					ret[index].dates = Object.values(item.dates);
				}
			});

			ret = Object.values(ret);

			return ret;
		},
		cartHasPushkincardItems    : state => state.has_pushkincard_items,
		cartCanBePaidByPushkincard : state => state.can_be_paid_by_pushkincard
	},
	mutations : {
		/**
		 * Увеличение порядкового номера версии состояния
		 * Должно вызываться каждый раз, когда меняется состояние корзины
		 *
		 * @param state
		 * @constructor
		 */
		INCREASE_SERIAL : (state) => {
			state.stateSerial++;
		},
		/**
		 * Установка флага сохранения корзины
		 *
		 * @param state
		 * @param value
		 * @constructor
		 */
		SET_CART_SAVING : (state, value) => {
			state.saving = value === true;
		},
		/**
		 * Установка флага изменения
		 *
		 * @param state
		 * @param value
		 * @constructor
		 */
		SET_CART_CHANGING : (state, value) => {
			state.changing = value === true;
		},
		/**
		 * Добавляет в очередь указанный stateSerial
		 *
		 * @param state
		 * @param serial
		 * @constructor
		 */
		QUEUE_PUSH : (state, serial) => {
			state.queue.push(serial);
		},
		/**
		 * Сдвигает очередь на одну позицию
		 *
		 * @param state
		 * @constructor
		 */
		QUEUE_SHIFT : (state) => {
			state.queue.shift();
		},
		/**
		 * Установка лимита корзины
		 *
		 * @param state
		 * @param value
		 * @constructor
		 */
		SET_CART_LIMIT : (state, value) => {
			state.limit = value;
		},
		/**
		 * Установка флага загруженности корзины
		 *
		 * @param state
		 * @param value
		 * @constructor
		 */
		SET_STATE_LOADED : (state, value) => {
			state.loaded = value;
		},
		/**
		 * Установка номера заказа при редактировании
		 *
		 * @param state
		 * @param value
		 * @constructor
		 */
		SET_CART_ORDER_NUMBER : (state, value) => {
			state.orderNumber = value;
		},
		/**
		 * Установка элементов
		 *
		 * @param state
		 * @param getters
		 * @param items
		 * @constructor
		 */
		SET_CART_ITEMS : (state, { getters, items }) => {
			let count            = 0;
			let personCount      = 0;
			let countWithChild   = 0;
			let sum              = 0;
			let countTickets     = 0;
			let sumTickets       = 0;
			let sumCommodities   = 0;
			let countCommodities = 0;
			Vue._.each(items, value => {
				value.phone          = !value.phone ? '' : value.phone;
				value.count          = value.count ? +value.count : 1;
				value.person_count   = value.person_count ? +value.person_count : 1;
				value.price          = value.price ? +value.price : 0;
				value.original_price = value.original_price ? +value.original_price : 0;

				count += value.count;
				personCount += value.count * value.person_count;
				countWithChild += value.count;
				sum += value.price * value.count;
				if (value.module_id === getters.constants['Global::MODULE__COMMODITY']) {
					countCommodities += value.count;
					sumCommodities += value.price * value.count;
				}
				else {
					countTickets += value.count;
					sumTickets += value.price * value.count;
				}

				if (value.child_items && value.child_items.length) {
					Vue._.each(value.child_items, childItem => {
						childItem.count          = childItem.count ? +childItem.count : 1;
						childItem.person_count   = childItem.person_count ? +childItem.person_count : 1;
						childItem.price          = childItem.price ? +childItem.price : 0;
						childItem.original_price = childItem.original_price ? +childItem.original_price : 0;

						countWithChild += childItem.count;
						sum += childItem.price * childItem.count;

						if (childItem.module_id === getters.constants['Global::MODULE__COMMODITY']) {
							sumCommodities += childItem.price * childItem.count;
						}
						else {
							sumTickets += childItem.price * childItem.count;
						}
					});
				}
			});

			state.count            = count;
			state.countTickets     = countTickets;
			state.countCommodities = countCommodities;
			state.personCount      = personCount;
			state.countWithChild   = countWithChild;
			state.sum              = sum;
			state.sumTickets       = sumTickets;
			state.sumCommodities   = sumCommodities;
			state.items            = items;
		},

		/**
		 * Обновление цен - необходимо в случае активного промокода
		 *
		 * @param state
		 * @param getters
		 * @param items
		 * @param vue
		 * @constructor
		 */
		UPDATE_CART_ITEM_PRICES : (state, { getters, items, vue }) => {
			for (const item of items) {
				if (item.child_items && item.child_items.length) {
					for (const childItem of item.child_items) {
						const findItem = searchItem(getters, {
							items       : state.items,
							item,
							childItem   : childItem,
							noCreate    : true,
							forContract : Boolean(state.counterparty.id)
						});

						if (findItem) {
							findItem.item.price = item.price;

							if (findItem.childItem) {
								findItem.childItem.price = childItem.price;
							}
						}
					}
				}
				else {
					const findItem = searchItem(getters, {
						items       : state.items,
						item,
						noCreate    : true,
						forContract : Boolean(state.counterparty.id)
					});
					if (findItem) {
						findItem.item.price = item.price;
					}
				}
			}

			// Запускаем событие для того, чтобы event мог перечитать свои цены
			vue.$GlobalBus.$emit('updateCartItemPrices');
		},
		/**
		 * Добавление элемента
		 *
		 * @param state
		 * @param getters
		 * @param item
		 * @param count
		 * @constructor
		 */
		UPDATE_CART_ITEM_INFO : (state, {
			getters, item, name, phone, languageId, countryId, comment, dateBookingExpiration
		}) => {
			const findObj = searchItem(getters, {
				items       : state.items,
				item,
				forContract : Boolean(state.counterparty.id)
			});

			let findItem = findObj.item;

			if (findItem) {
				findItem.name                    = name || null;
				findItem.phone                   = phone || '';
				findItem.language_id             = languageId || null;
				findItem.country_id              = countryId || null;
				findItem.comment                 = comment || null;
				findItem.date_booking_expiration = dateBookingExpiration || null;
			}
		},
		/**
		 * Добавление элемента
		 *
		 * @param state
		 * @param getters
		 * @param item
		 * @param count
		 * @param childItem
		 * @constructor
		 */
		ADD_CART_ITEM : (state, { getters, item, childItem, count }) => {
			const findObj = searchItem(getters, {
				items       : state.items,
				item,
				childItem,
				forContract : Boolean(state.counterparty.id)
			});

			let findItem      = findObj.item;
			let findChildItem = findObj.childItem;
			if (!count) {
				count = 1;
			}

			count          = +count;
			let childCount = count;

			if (findChildItem && findChildItem.is_new) {
				if (!findItem.child_items) {
					Vue.set(findItem, 'child_items', []);
				}

				findItem.child_items.push(findChildItem);
			}

			if (findItem.excursion_type === getters.constants['ExcursionJournal::TYPE__ORGANIZED'] && findChildItem) {
				count = 1;
			}

			findItem.price          = item.price ? +item.price : 0;
			findItem.original_price = item.original_price ? +item.original_price : 0;
			if (findItem.module_id !== getters.constants['Global::MODULE__COMMODITY']) {
				findItem.social_category_id    = item.social_category_id || null;
				findItem.social_category_title = item.social_category_title || null;
				findItem.person_count          = item.person_count ? +item.person_count : 1;
			}

			if (findItem.is_new) {
				state.items.push(findItem);
			}

			// Проверка на то, что билет с местом не может быть в количестве больше 1.
			if (
				(
					!findItem.hall_scheme_seat_id &&
					findItem.excursion_type !== getters.constants['ExcursionJournal::TYPE__ORGANIZED']
				) ||
				!+findItem.count
			) {
				findItem.count += count;
				state.count += count;
				state.personCount += findItem.person_count * count;
				state.countWithChild += count;
				state.sum += findItem.price * count;
				if (findItem.module_id === getters.constants['Global::MODULE__COMMODITY']) {
					state.countCommodities += count;
					state.sumCommodities += findItem.price * count;
				}
				else {
					state.countTickets += count;
					state.sumTickets += findItem.price * count;
				}
			}

			if (findChildItem) {
				findChildItem.price          = childItem.price ? +childItem.price : 0;
				findChildItem.original_price = childItem.original_price ? +childItem.original_price : 0;
				findChildItem.person_count   = childItem.person_count ? +childItem.person_count : 1;

				findChildItem.count += childCount;
				state.countWithChild = (+state.countWithChild) + (+childCount);
				state.sum += findChildItem.price * childCount;

				if (findChildItem.module_id === getters.constants['Global::MODULE__COMMODITY']) {
					state.sumCommodities += findChildItem.price * childCount;
				}
				else {
					state.sumTickets += findChildItem.price * childCount;
				}
			}
		},
		/**
		 * Удаление элемента
		 *
		 * @param state
		 * @param getters
		 * @param vue
		 * @param item
		 * @param childItem
		 * @param count
		 * @constructor
		 */
		DELETE_CART_ITEM : (state, { getters, vue, item, childItem, count }) => {
			let findObj = searchItem(getters, {
				items       : state.items,
				item,
				childItem,
				forContract : Boolean(state.counterparty.id)
			});

			let findItem      = findObj.item;
			let findChildItem = findObj.childItem;
			let processDelete = (curItem, curCount, curChildItem) => {
				if (!curCount) {
					curCount = 1;
				}

				// Если нужно удалить все, count === true
				if (curCount === true) {
					curCount = curItem.count;
				}

				curCount = +curCount;
				if (curItem.count - curCount < 0) {
					curCount = curCount - Math.abs(curItem.count - curCount);
				}

				curItem.count -= curCount;
				state.sum -= curItem.price * curCount;
				if (curItem.module_id === getters.constants['Global::MODULE__COMMODITY']) {
					state.sumCommodities -= curItem.price * curCount;
				}
				else {
					state.sumTickets -= curItem.price * curCount;
				}

				if (curChildItem === true) {
					return;
				}

				state.count -= curCount;
				state.personCount -= curCount * curItem.person_count;
				state.countWithChild -= curCount;
				if (curItem.module_id === getters.constants['Global::MODULE__COMMODITY']) {
					state.countCommodities -= curCount;
				}
				else {
					state.countTickets -= curCount;
				}

				if (curChildItem) {
					curChildItem.count -= curCount;
					state.countWithChild -= curCount;
					state.sum -= curChildItem.price * curCount;

					if (curChildItem.module_id === getters.constants['Global::MODULE__COMMODITY']) {
						state.sumCommodities -= curChildItem.price * curCount;
					}
					else {
						state.sumTickets -= curChildItem.price * curCount;
					}
				}
			};

			if (findItem) {
				findItem.name        = item.name || null;
				findItem.phone       = item.phone || '';
				findItem.language_id = item.language_id || null;
				findItem.country_id  = item.country_id || null;
				findItem.comment     = item.comment || null;

				if (findItem.excursion_type === getters.constants['ExcursionJournal::TYPE__ORGANIZED']) {
					if (findChildItem) {
						processDelete(findChildItem, count, true);
					}
					else {
						if (findItem.child_items && findItem.child_items.length) {
							vue._.each(findItem.child_items, childItem => {
								processDelete(childItem, true, true);
							});
						}

						processDelete(findItem, count);
					}
				}
				else {
					processDelete(findItem, count, findChildItem);
				}
			}
		},

		/**
		 * Установка времени жизни корзины
		 *
		 * @param state
		 * @param obj
		 * @constructor
		 */
		SET_CART_VALIDITY : (state, obj) => {
			obj.time = obj.time !== null && obj.time !== undefined ? Number(obj.time) : null;

			if (obj.time && obj.time > 0 && !state.validityTimer) {
				state.validity        = obj.time;
				state.validityEnd     = Math.floor(Date.now() / 1000) + obj.time;
				state.validityMinutes = Math.floor(obj.time / 60);
				state.validitySeconds = Math.floor(obj.time % 60);

				state.validityTimer = window.setInterval(() => {
					state.validity = state.validityEnd - Math.floor(Date.now() / 1000);

					state.validityMinutes = state.validity > 0 ? Math.floor(state.validity / 60) : 0;
					state.validitySeconds = state.validity > 0 ? Math.floor(state.validity % 60) : 0;

					if (state.validityNotifyTime && state.validity === state.validityNotifyTime) {
						obj.vue.$GlobalBus.$emit('notification', 'Корзина', 'Через три минуты срок действия корзины истечет и билеты будут удалены', 'warning');
					}

					if (state.validity <= 0) {
						window.clearTimeout(state.validityTimer);

						removeCartId();
						state.validityTimer = null;
						state.validity      = null;

						obj.vue.$GlobalBus.$emit('cartValidityEnd', JSON.parse(JSON.stringify(state.items)));
						obj.vue.$GlobalBus.$emit('notification', 'Корзина', 'Срок действия корзины истек', 'warning');

						// Событие для полноэкранного обработчика
						obj.vue.$GlobalBus.$emit('fullscreenNotification',
							'Время бронирования билетов истекло',
							'Срок действия корзины истек',
							[
								state.returnToMain
							]
						);

						state.items            = [];
						state.count            = 0;
						state.countCommodities = 0;
						state.countTickets     = 0;
						state.personCount      = 0;
						state.countWithChild   = 0;
						state.sum              = 0;
						state.sumCommodities   = 0;
						state.sumTickets       = 0;
					}
				}, 1000);
			}
			else if (obj.time !== null && obj.time <= 0) {
				window.clearTimeout(state.validityTimer);

				removeCartId();
				state.validityTimer   = null;
				state.validity        = null;
				state.validityEnd     = null;
				state.validityMinutes = null;
				state.validitySeconds = null;

				obj.vue.$GlobalBus.$emit('cartValidityEnd', JSON.parse(JSON.stringify(state.items)));
				obj.vue.$GlobalBus.$emit('notification', 'Корзина', 'Срок действия корзины истек', 'warning');

				// Событие для полноэкранного обработчика
				obj.vue.$GlobalBus.$emit('fullscreenNotification',
					'Время бронирования билетов истекло',
					'Срок действия корзины истек',
					[
						state.returnToMain
					]
				);

				state.items            = [];
				state.count            = 0;
				state.countCommodities = 0;
				state.countTickets     = 0;
				state.personCount      = 0;
				state.countWithChild   = 0;
				state.sum              = 0;
				state.sumCommodities   = 0;
				state.sumTickets       = 0;
			}
			else if (obj.time === null) {
				window.clearTimeout(state.validityTimer);
				state.validityTimer   = null;
				state.validity        = null;
				state.validityEnd     = null;
				state.validityMinutes = null;
				state.validitySeconds = null;
			}
		},
		/**
		 * Установка таймера сохранения
		 *
		 * @param state
		 * @param timer
		 * @constructor
		 */
		SET_CART_TIMER : (state, timer) => {
			state.saveTimer = timer;
		},
		/**
		 * Отчистка таймера сохранения
		 *
		 * @param state
		 * @constructor
		 */
		CLEAR_CART_TIMER : (state) => {
			window.clearTimeout(state.saveTimer);
			state.saveTimer = null;
		},
		/**
		 * Установка промо-кода
		 *
		 * @param state
		 * @param promo_code
		 * @constructor
		 */
		SET_CART_PROMO_CODE : (state, promo_code) => {
			state.promo_code = promo_code;
		},
		/**
		 * Установка контрагента
		 *
		 * @param state
		 * @param id
		 * @param title
		 * @param inn
		 * @param disallowBookingZeroBalance
		 * @constructor
		 */
		SET_CART_COUNTERPARTY : (state, { id, title, inn, disallowBookingZeroBalance }) => {
			state.counterparty.id                            = id || null;
			state.counterparty.title                         = title !== undefined ? title : state.counterparty.title;
			state.counterparty.inn                           = inn !== undefined ? inn : state.counterparty.inn;
			state.counterparty.disallow_booking_zero_balance = disallowBookingZeroBalance !== undefined ? disallowBookingZeroBalance : state.counterparty.disallow_booking_zero_balance;
		},

		SET_CART_HAS_PUSHKINCARD_ITEMS      : (state, has_pushkincard_items) => {
			state.has_pushkincard_items = !!has_pushkincard_items;
		},
		SET_CART_CAN_BE_PAID_BY_PUSHKINCARD : (state, can_be_paid_by_pushkincard) => {
			state.can_be_paid_by_pushkincard = !!can_be_paid_by_pushkincard;
		},
		SET_CART_CHECK_COUNTERPARTY         : (state, value) => {
			state.checkCounterparty = value;
		}
	},
	actions   : {
		setCartId({ commit, state }, id) {
			if (!id) {
				removeCartId();
			}
			else {
				setCartId(id);
			}

			request.interceptors.request.use(config => {
				if (!id) {
					delete config.headers['X-HWM-Cart'];
				}
				else {
					config.headers['X-HWM-Cart'] = id;
				}

				return config;
			}, error => {
				// Do something with request error
				console.error(error); // for debug
				Promise.reject(error);
			});
		},

		setCartCounterpartyId({ commit, state }, id) {
			if (!id) {
				removeCartId();
			}
			else {
				setCartId(id);
			}

			request.interceptors.request.use(config => {
				if (!id) {
					delete config.headers['X-HWM-Cart'];
				}
				else {
					config.headers['X-HWM-Cart'] = id;
				}

				return config;
			}, error => {
				// Do something with request error
				console.error(error); // for debug
				Promise.reject(error);
			});
		},

		/**
		 * Получить корзину с сервера
		 *
		 * @param commit
		 * @param state
		 * @param dispatch
		 * @param getters
		 * @param withCheck
		 * @returns {Promise<any>}
		 */
		getCart({ commit, state, dispatch, getters }, withCheck = true) {
			return new Promise((resolve, reject) => {
				let getTimer = window.setInterval(() => {
					if (state.saveTimer) {
						return;
					}

					getCartItems(withCheck)
						.then(data => {
							let cartId = data.id || null;

							const cartHasItems    = (data.items && data.items.length > 0);
							const cartIsProtected = (!!data.promo_code && data.validity > 0);

							if (cartHasItems || cartIsProtected) {
								commit('SET_CART_COUNTERPARTY', {
									id                         : data.counterparty_id || null,
									title                      : data.counterparty_title || null,
									inn                        : data.counterparty_inn || null,
									disallowBookingZeroBalance : data.counterparty_disallow_booking_zero_balance || null
								});
								commit('SET_CART_ITEMS', { getters, items : data.items });
								commit('SET_CART_VALIDITY', { vue : this._vm, time : data.validity });
								commit('SET_CART_PROMO_CODE', data.promo_code);
								commit('SET_CART_ORDER_NUMBER', data.order_number || null);
								commit('SET_CART_HAS_PUSHKINCARD_ITEMS', data.has_pushkincard_items);
								commit('SET_CART_CAN_BE_PAID_BY_PUSHKINCARD', data.can_be_paid_by_pushkincard);
							}
							else {
								// Получена пустая корзина
								dispatch('clearCart', true);
								cartId = null;
							}

							commit('SET_CART_LIMIT', data.limit);
							dispatch('setCartId', cartId);

							if (data.error_items && data.error_items.length) {
								this._vm.$GlobalBus.$emit('cartErrorItems', data.error_items);
							}

							// Обновление глобальной корзины, если она есть
							if (window.HWMGlobalCart) {
								window.HWMGlobalCart.update(data);
							}

							commit('SET_STATE_LOADED', true);

							resolve();
						})
						.catch(error => {
							reject(error);
						});

					window.clearInterval(getTimer);
					getTimer = null;
				}, 200);
			});
		},

		/**
		 * Добавить элемент
		 *
		 * @param commit
		 * @param state
		 * @param getters
		 * @param dispatch
		 * @param item
		 * @param counterpartyId
		 * @param withCheck
		 * @param count
		 * @param childItem
		 */
		async addCartItem({ commit, state, getters, dispatch }, {
			item, childItem, counterpartyId, withCheck, count
		}) {
			commit('SET_CART_CHANGING', true);
			if (!count) {
				count = 1;
			}

			if (state.checkCounterparty && state.counterparty.id != counterpartyId && (state.items && state.items.length)) {
				await dispatch('clearCart');
			}

			commit('SET_CART_COUNTERPARTY', { id : counterpartyId });
			commit('ADD_CART_ITEM', { getters, item, childItem, count });
			commit('INCREASE_SERIAL');
			dispatch('saveCartItems', withCheck);
		},

		/**
		 * Добавить элемент
		 *
		 * @param commit
		 * @param state
		 * @param getters
		 * @param item
		 * @param name
		 * @param phone
		 * @param languageId
		 * @param countryId
		 * @param comment
		 * @param withCheck
		 */
		updateCartItemInfo({ commit, getters, dispatch }, {
			item, name, phone, languageId, countryId, comment, withCheck
		}) {
			commit('SET_CART_CHANGING', true);

			commit('UPDATE_CART_ITEM_INFO', { getters, item, name, phone, languageId, countryId, comment });
			dispatch('saveCartItems', withCheck);
		},

		/**
		 * Удалить элемент
		 *
		 * @param commit
		 * @param state
		 * @param getters
		 * @param dispatch
		 * @param item
		 * @param withCheck
		 * @param count
		 * @param childItem
		 */
		deleteCartItem({ commit, state, getters, dispatch }, { item, withCheck, count, childItem }) {
			commit('SET_CART_CHANGING', true);
			if (!count) {
				count = 1;
			}

			commit('DELETE_CART_ITEM', { vue : this._vm, getters, item, count, childItem });
			commit('INCREASE_SERIAL');
			dispatch('saveCartItems', withCheck);
		},

		/**
		 * Удалить элемент полностью, со всем количеством
		 *
		 * @param commit
		 * @param state
		 * @param getters
		 * @param dispatch
		 * @param item
		 * @param withCheck
		 * @param childItem
		 */
		deleteCartItemAll({ commit, state, getters, dispatch }, { item, withCheck, childItem }) {
			commit('SET_CART_CHANGING', true);
			commit('DELETE_CART_ITEM', { vue : this._vm, getters, item, count : true, childItem });
			commit('INCREASE_SERIAL');
			dispatch('saveCartItems', withCheck);
		},

		/**
		 * Сохранение корзины
		 *
		 * @param commit
		 * @param state
		 * @param getters
		 * @param dispatch
		 * @param withCheck
		 */
		saveCartItems({ commit, state, getters, dispatch }, withCheck) {
			commit('CLEAR_CART_TIMER');
			const stateSerial = state.stateSerial;
			const items       = Vue._.cloneDeep(state.items, true);
			commit('SET_CART_TIMER', window.setTimeout(async () => {
				if (!state.saveTimer) {
					return;
				}
				// становимся в очередь
				commit('QUEUE_PUSH', stateSerial);
				// ожидаем своей очереди
				await this.dispatch('waitForYouTurn', stateSerial);

				if (stateSerial !== state.stateSerial) {
					// Несовпадение serial - значит, эти данные надо пропустить и дождаться следующих
					commit('QUEUE_SHIFT');
					return;
				}

				commit('SET_CART_SAVING', true);
				setCartItems(items, withCheck, state.counterparty.id, stateSerial)
					.then(data => {
						let cartId = data.id || null;
						if (cartId) {
							// если пришел cartId - устанавливаем сразу
							dispatch('setCartId', cartId);
						}

						if (data.state_serial != state.stateSerial) {
							// Несовпадение serial - значит, эти данные надо пропустить и дождаться следующих
							commit('QUEUE_SHIFT');
							return;
						}

						commit('SET_CART_COUNTERPARTY', {
							id                         : data.counterparty_id || null,
							title                      : data.counterparty_title || null,
							inn                        : data.counterparty_inn || null,
							disallowBookingZeroBalance : data.counterparty_disallow_booking_zero_balance || null
						});

						if (data.items && data.items.length) {
							commit('SET_CART_ITEMS', { getters, items : data.items });
							// Обновление цен
							if (state.promo_code) {
								commit('UPDATE_CART_ITEM_PRICES', { vue : this._vm, getters, items : data.items });
							}

							commit('SET_CART_VALIDITY', { vue : this._vm, time : data.validity });
							commit('SET_CART_HAS_PUSHKINCARD_ITEMS', data.has_pushkincard_items);
							commit('SET_CART_CAN_BE_PAID_BY_PUSHKINCARD', data.can_be_paid_by_pushkincard);
						}
						else {
							dispatch('clearCart', true);
							cartId = null;
						}

						commit('SET_CART_LIMIT', data.limit);
						commit('SET_CART_ORDER_NUMBER', data.order_number || null);

						dispatch('setCartId', cartId);
						let hasError = false;
						let errorMsg = [];

						if (data.errors && data.errors.length) {
							hasError = true;

							errorMsg = data.errors;
						}

						if (data.error_items && data.error_items.length) {
							hasError = true;
							for (let item of data.error_items) {
								errorMsg.push(item.error);
							}

							this._vm.$GlobalBus.$emit('cartErrorItems', data.error_items);
						}

						commit('CLEAR_CART_TIMER');
						if (!hasError) {
							this._vm.$GlobalBus.$emit('cartItemsSaved', data.items);
						}
						else {
							console.warn('Errors', data);
							this._vm.$GlobalBus.$emit('notification', 'Ошибка корзины', errorMsg.join('\n'), 'danger');
						}

						// Обновление глобальной корзины, если она есть
						if (window.HWMGlobalCart) {
							window.HWMGlobalCart.update(data);
						}

						commit('SET_CART_SAVING', false);
						commit('SET_CART_CHANGING', false);
						commit('QUEUE_SHIFT');
					})
					.catch(() => {
						commit('CLEAR_CART_TIMER');
						commit('SET_CART_SAVING', false);
						commit('SET_CART_CHANGING', false);
						commit('QUEUE_SHIFT');
					});
			}, 1000));
		},
		/**
		 * Отчистка корзины
		 *
		 * @param commit
		 * @param getters
		 * @param local
		 */
		async clearCart({ commit, getters }, local) {
			if (!local) {
				await clearCart();
			}

			removeCartId();
			await commit('SET_CART_COUNTERPARTY', { counterpartyId : null });
			await commit('SET_CART_ITEMS', { getters, items : [] });
			await commit('SET_CART_PROMO_CODE', null);
			await commit('SET_CART_VALIDITY', { vue : this._vm, time : null });
			await commit('SET_CART_ORDER_NUMBER', null);
			await commit('SET_CART_LIMIT', null);
			await commit('SET_CART_HAS_PUSHKINCARD_ITEMS', false);
			await commit('SET_CART_CAN_BE_PAID_BY_PUSHKINCARD', false);
			commit('INCREASE_SERIAL');
		},

		/**
		 * Устанавливает промокод
		 * @param commit
		 * @param dispatch
		 * @param code
		 * @param cart_id
		 */
		setCartPromoCode({ commit, dispatch }, { code, cart_id }) {
			commit('SET_CART_PROMO_CODE', code);
			dispatch('setCartId', cart_id);
		},

		/**
		 * Ожидает полного сохранения корзины, резолвит тогда, когда корзина полностью сохранена
		 *
		 * @param state
		 * @returns {Promise<unknown>}
		 */
		async ensureCartItemsSaved({ state }) {
			return new Promise((resolve => {
				let waitInterval = setInterval(async () => {
					// Ждем, пока корзина не перестанет быть в состоянии "меняюсь"
					if (!state.changing) {
						clearInterval(waitInterval);
						resolve();
					}
				}, 50);
			}));
		},
		/**
		 * Ожидает, пока заданный номер не окажется первым в очереди
		 *
		 * @param state
		 * @param serial
		 * @returns {Promise<unknown>}
		 */
		async waitForYouTurn({ state }, serial) {
			return new Promise((resolve => {
				let waitInterval = setInterval(async () => {
					if (state.queue[0] === serial) {
						clearInterval(waitInterval);
						resolve();
					}
				}, 50);
			}));
		}
	}
};

export default cartStore;
