/**
 * Инициализирует все тестовые методы, которые нужны для выполнения тестов
 *
 * @param store
 */
export default function initVuexTestMethods(store) {
	/**
	 * Отладочный метод для получения значения из Vuex через window (используется в тестах)
	 * @param getterName
	 * @returns {*}
	 * @private
	 */
	window.__getVuexGetterValue = function (getterName) {
		return store.getters[getterName];
	};

	/**
	 * Проверяет, можно ли получать данные из getter'ов
	 *
	 * @returns {boolean}
	 * @private
	 */
	window.__isVuexReady = function () {
		try {
			// Проверяем, что getter appId возвращает что-то не пустое.
			// Подразумевается, что appId определен во всех приложениях.
			return !!store.getters.appId;
		}
		catch (e) {
			return false;
		}
	};
}
