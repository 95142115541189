import * as API from '@/helpers/request';
import Vue      from 'vue';

/**
 * Получение состояния корзины
 *
 * @returns {Promise<Response<any>>}
 */
export function getItems(withCheck) {
	withCheck = withCheck === true ? { withCheck : true } : undefined;

	return API.request.post('/cart/get', withCheck);
}

/**
 * Управление корзиной
 *
 * @param items
 * @param withCheck
 * @param counterpartyId
 * @param stateSerial
 * @returns {Promise<Response<any>>}
 */
export function setItems(items, withCheck, counterpartyId, stateSerial) {
	let params = {};

	params['items'] = Vue._.isEmpty(items) ? null : items;

	if (withCheck === true) {
		params['withCheck'] = true;
	}

	if(counterpartyId) {
		params['counterparty_id'] = counterpartyId;
	}

	params['state_serial'] = stateSerial;

	return API.request.post('/cart/index', params);
}

/**
 * Получение настроек пользователя
 *
 * @returns {Promise<Response<any>>}
 */
export function clear() {
	return API.request.post('/cart/clear');
}