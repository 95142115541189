import moment from 'moment';

const urlParser = document.createElement('a');

export function domain(url) {
	urlParser.href = url;
	return urlParser.hostname;
}

export function count(arr) {
	return arr.length;
}

export function prettyDate(date) {
	const a = new Date(date);
	return a.toDateString();
}

export function formatDate(date, format) {
	if(!format) {
		format = 'DD.MM.YYYY HH:mm:ss'
	}

	return moment(String(date)).format(format)
}

/**
 * склоняем в множественное число
 *
 * пример: {{i}} {{i | pluralize(['место', 'места', 'мест']) }}
 *
 * @param n
 * @param titles
 * @returns {*}
 *
 * @url http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html?id=l10n/pluralforms
 */
export function pluralize(n, titles) {
	return titles[(n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2)];
}
