import Vue from 'vue';

Vue.directive('ifPermission', {
	inserted(el, binding, vnode, old) {
		let ok = false;
		if (vnode.context.$root.$store.getters.checkPermission === undefined) {
			console.error('Отсутствует метод checkPermission в store/getters');
		}
		else {
			ok = vnode.context.$root.$store.getters.checkPermission(binding.value);
		}

		if (!ok) {
			vnode.context.$nextTick(() => {
				if(vnode.elm.parentElement) {
					vnode.elm.parentElement.removeChild(vnode.elm);
				}
			});
		}
	}
});

Vue.directive('ifNotPermission', function (el, binding, vnode) {
	let ok = true;
	if (vnode.context.$root.$store.getters.checkPermission === undefined) {
		console.error('Отсутствует метод checkPermission в store/getters');
	}
	else {
		ok = vnode.context.$root.$store.getters.checkPermission(binding.value, true, true);
	}

	if (!ok) {
		vnode.context.$nextTick(() => {
			if(vnode.elm.parentElement) {
				vnode.elm.parentElement.removeChild(vnode.elm);
			}
		});
	}
});

Vue.directive('ifRole', {
	inserted(el, binding, vnode, old) {
		let ok = false;
		if (vnode.context.$root.$store.getters.checkRole === undefined) {
			console.error('Отсутствует метод checkPermission в store/getters');
		}
		else {
			ok = vnode.context.$root.$store.getters.checkRole(binding.value);
		}

		if (!ok) {
			vnode.context.$nextTick(() => {
				if(vnode.elm.parentElement) {
					vnode.elm.parentElement.removeChild(vnode.elm);
				}
			});
		}
	}
});

Vue.directive('ifNotRole', function (el, binding, vnode) {
	let ok = true;
	if (vnode.context.$root.$store.getters.checkRole === undefined) {
		console.error('Отсутствует метод checkPermission в store/getters');
	}
	else {
		ok = !vnode.context.$root.$store.getters.checkRole(binding.value);
	}

	if (!ok) {
		vnode.context.$nextTick(() => {
			if(vnode.elm.parentElement) {
				vnode.elm.parentElement.removeChild(vnode.elm);
			}
		});
	}
});
