/**
 * This file is generated, do not edit
 */
import SettingsProxyFactory from './SettingsProxyFactory';
import SettingsStructure    from '@/generated/SettingsStructure';

/**
 * @type SettingsStructure
 */
const settings = SettingsProxyFactory.getProxy(SettingsStructure);
export default settings;
