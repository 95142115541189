import * as common from '@/api/common';
import * as app from "@/main";

const organization = {
  state: {
    organization: null,
    exhibitor   : null,
    userInfo    : null,
    profile     : null,
  },

  mutations: {
    SET_ORGANIZATION: (state, organization) => {
      state.organization = organization;
    },
    SET_EXHIBITOR   : (state, exhibitor) => {
      state.exhibitor = exhibitor;
    },
    SET_USER_INFO   : (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_PROFILE     : (state, profile) => {
      state.profile = profile;
    },
  },

  actions: {
    /**
     * @param commit
     * @param dispatch
     * @returns {Promise<any>}
     */
    async getOrganization({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        common.getOrganization()
          .then(data => {
            commit('SET_ORGANIZATION', data);

            app.i18n.locale = data.locale

            if (data.locale === 'en') {
              require('moment/locale/en-ca')
            }

            dispatch('mobileAndTabletsCheck').then(() => {
              dispatch('getSidebarMenu', {organizationType: data.type})
            })

            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async getExhibitor({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        common.getExhibitor()
          .then(data => {
            commit('SET_EXHIBITOR', data);

            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async getUserInfo({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        common.getUserInfo()
          .then(data => {
            commit('SET_USER_INFO', data);

            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async getProfile({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        common.getProfile()
          .then(data => {
            commit('SET_PROFILE', data);

            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};

export default organization;
