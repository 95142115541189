import * as API from '@/helpers/request';
import {request} from '@/helpers/request';
import _ from "lodash";

const controllerPath = 'contact';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  try {
    let contact = await API.getItem(controllerPath, data)

    if (contact.organization) {
      contact.organization = contact.organization.id
    }

    contact.participation_categories = _.map(contact.participation_categories, 'id')

    contact.status = contact.status.id

    return contact
  }
  catch (e) {
    console.log(e)
  }
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}

export function changeLogin(id, data) {
  return request.post(`${controllerPath}/${id}/change-login`, data);
}

export function changePassword(id, data) {
  return request.post(`${controllerPath}/${id}/change-password`, data);
}
