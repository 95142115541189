import * as API from '@/helpers/request';

export function getItems(filter, perPage, page, sort) {
  return API.getItems('site/orders', filter, perPage, page, sort);
}

export function getExpositionPlaceList(locale) {
  return API.getItemsList('exposition-place', {condition: {locale}});
}

export function getCloseAreaTypeList(locale) {
  return API.getItemsList('close-area-type', {condition: {locale}});
}

export function getCloseAreaTypePrices(data) {
  return API.request.post('site/order/get-close-area-type-prices', data);
}

export function getAreaBuildingTypeList(data) {
  return API.getItemsList('area-building-type', data);
}

export function getAreaBuildingTypePrices(data) {
  return API.request.post('site/order/get-area-building-type-prices', data);
}

export function createNewAreaOrder(data) {
  return API.request.post('site/order/create-new-area-order', data)
}

export function createNewApplicationOrder(data) {
  return API.request.post('site/order/create-new-application-order', data)
}

export function getOrderWithCreationStatus() {
  return API.request.post('site/order/get-order-with-creation-status')
}

export function confirmOrder(id) {
  return API.request.post('site/order/confirm-order', {id: id})
}

export function removeOrder(id) {
  return API.request.post('site/order/remove-order', {id: id})
}

export function getStands() {
  return API.request.post('site/order/get-stands')
}

export function createAndPay(data, isDemoPayment) {
  data.isDemoPayment = isDemoPayment
  
  return API.request.post('site/order/create-and-pay', data)
}

export function createAndPayForOpenRoad(data, isDemoPayment) {
  data.isDemoPayment = isDemoPayment

  return API.request.post('site/order/open-road/create-and-pay', data)
}

export function getItem(id) {
  return API.request.post(`site/order/view/${id}`)
}

export function retryPayment(id) {
  return API.request.post(`site/order/retry-payment/${id}`)
}

export function createDemoPayment(orderId) {
  return API.request.post(`demo-bank/create-payment/${orderId}`)
}