import * as common from './common';
import * as country from './country';
import * as setting from './setting';
import * as user from './user';
import * as userLog from './userLog';
import * as organization from './organization';
import * as exhibitor from './exhibitor';
import * as section from './section';
import * as participationCategory from './participationCategory';
import * as status from './status';
import * as contact from './contact';
import * as emailTemplate from './emailTemplate';
import * as mailer from './mailer';
import * as unit from './unit';
import * as productCategory from './productCategory';
import * as currency from './currency';
import * as product from './product';
import * as price from './price';
import * as order from './order';
import * as orderItem from './orderItem';
import * as registrationForm from './registrationForm';
import * as registrationPersonForm from './registrationPersonForm';
import * as document from './document';
import * as catalog from './catalog';
import * as businessProgram from './businessProgram';
import * as conferenceTable from './conferenceTable';

const API = {
  common,
  country,
  setting,
  user,
  userLog,
  organization,
  exhibitor,
  section,
  participationCategory,
  status,
  contact,
  emailTemplate,
  mailer,
  unit,
  productCategory,
  currency,
  product,
  price,
  order,
  orderItem,
  registrationForm,
  registrationPersonForm,
  document,
  catalog,
  businessProgram,
  conferenceTable,
};

export default {
  install(Vue) {
    Vue.$API = API;
    Object.defineProperties(Vue.prototype, {
      $API: {
        get() {
          return API;
        }
      }
    });
  }
};
