export default {
    "ALL": {
        "ALL": {
            "auth.password.pattern": "/^(.*)+$/",
            "cache_flush_version": 106,
            "counterparty.user.password_length": 8,
            "counterparty.user.username_prefix": "partner",
            "extended_log_entry_lifetime": 0,
            "mail.smtp_server": "smtp.yandex.ru",
            "mail.smtp_username": "test@example.net",
            "mail.use_smtp": true,
            "order.link.time_expiration": 10800,
            "pdf.format": "A4",
            "pdf.margin.bottom": 0,
            "pdf.margin.left": 6,
            "pdf.margin.right": 6,
            "pdf.margin.top": 0,
            "pdf.orientation": "P",
            "print_transpose": false,
            "print_transpose_coeff": 1,
            "print_transpose_height": 550,
            "print_transpose_width": 1250,
            "ticket_form_number_digits_count": 6,
            "ticket_limit.compare_new_with_old": false,
            "ticket_limit.use_new": true,
            "ticket_multiline_max_chars": 34,
            "timezone": "Europe/Moscow",
            "enable_refund_rule": false,
            "allowed_order_custom_fields": "country, crossborder",
            "print_ticket_on_receipt.organization_title": null,
            "print_ticket_on_receipt.organization_address": null,
            "print_ticket_on_receipt.organization_inn": null,
            "print_ticket_on_receipt.organization_kpp": null,
            "print_ticket_on_receipt.organization_sno": "ОСН",
            "check_limit_by_ticket_persons": false,
            "admin_emails_for_order_paid_message_copy": null,
            "report21_enable_short_ticket_forms_view": false
        },
        "admin": {
            "ticket_form_box_size": 10,
            "ticket_form_package_size": 500
        },
        "cashier": {
            "cashier_shiftless_default_sales_channel_id": null,
            "cashier_url": null
        }
    },
    "PushkinCard": {
        "ALL": {
            "pushkin_card_sales_disabled": false
        }
    },
    "Event": {
        "ALL": {
            "counterparty_sale_only_by_journal_grid": false
        }
    },
    "SalesChannelCash": {
        "ALL": {
            "cart_limit": 10,
            "enable_fiscal_printer": false,
            "enable_zero_price": false,
            "refund_only_current_work_shift": false,
            "select_day_limit": 30,
            "show_in_past": true,
            "time_booking_close": -10800,
            "time_booking_expiration": 259200,
            "time_cart_expiration": 900,
            "time_payment_expiration": 1200,
            "time_refund_close": 0,
            "time_sale_close": 0,
            "time_sale_open": null,
            "waste_control.current_work_shift_only": false,
            "work_shift_duration": 24,
            "work_shift_not_after": "20:00:00",
            "work_shift_not_before": "9:00:00",
            "simplified_receipts": true
        },
        "cashier": {
            "enable_checklist_action_close_in_progress__cash_removed": true,
            "enable_checklist_action_close_in_progress__kkt_shift_closed": true,
            "enable_checklist_action_close_in_progress__pos_reconcile": true,
            "enable_checklist_action_close_in_progress__ticket_forms_removed": true,
            "enable_checklist_action_open_in_progress__cash_added": true,
            "enable_checklist_action_open_in_progress__hardware_checked": true,
            "enable_checklist_action_open_in_progress__kkt_shift_opened": true,
            "enable_checklist_action_open_in_progress__ticket_forms_loaded": true,
            "enable_checklist_action_open_in_progress__x_report_printed": true,
            "enable_checklist_action_open_in_progress__sync_kkt_time": false
        }
    },
    "SalesChannelSite": {
        "ALL": {
            "cart_limit": 10,
            "enable_cashless": false,
            "enable_zero_price": false,
            "select_day_limit": 30,
            "show_in_past": true,
            "time_booking_close": -10800,
            "time_booking_expiration": 1200,
            "time_cart_expiration": 900,
            "time_payment_expiration": 1200,
            "time_refund_close": 0,
            "time_sale_close": 0,
            "time_sale_open": null,
            "simplified_receipts": true
        },
        "site": {
            "google_analytics_client_id": null,
            "yandex_oauth_token": null,
            "integration_css": ""
        }
    },
    "SalesChannelContract": {
        "ALL": {
            "enable_zero_price": false,
            "select_day_limit": 30,
            "ticket_limit": 30,
            "time_booking_close": -10800,
            "time_booking_expiration": 259200,
            "time_payment_expiration": 1200,
            "time_refund_close": 0,
            "time_sale_close": 0,
            "time_sale_open": null,
            "cart_limit": 100,
            "time_cart_expiration": 1800
        }
    },
    "SalesChannelAgent": {
        "ALL": {
            "cart_limit": 10
        }
    },
    "SalesChannelTerminal": {
        "ALL": {
            "simplified_receipts": true
        }
    },
    "Agent": {
        "ALL": {
            "user.username_prefix": "agent",
            "user.password_length": 8
        }
    },
    "Validator": {
        "api": {
            "entry_timeout": 1800
        },
        "ALL": {
            "time_valid_close": 1800,
            "time_valid_open": -1800
        }
    },
    "FondKino": {
        "ALL": {
            "login": null,
            "org_id": null,
            "password": null,
            "url": "https://ekinobilet.ru/ekbs/upload.aspx"
        }
    },
    "Report": {
        "ALL": {
            "reports.organization_title": "",
            "report21.submitted_by": "Кассир",
            "report21.approved_by": "Старший кассир"
        }
    },
    "Commodity": {
        "ALL": {
            "default_tariff_id": null
        }
    },
    "GooglePayForPasses": {
        "ALL": {
            "service_account_email": null,
            "service_account_file": "{}",
            "application_name": null,
            "issuer_id": null,
            "origins": null
        }
    },
    "FileStorage": {
        "ALL": {
            "file_storage_selectel_domain": null,
            "file_storage_selectel_bucket": null
        }
    }
}