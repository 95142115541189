import * as API from '@/helpers/request';
import {request} from '@/helpers/request';

export function getItems(filter, perPage, page, sort) {
  return API.getItems('site/documents', filter, perPage, page, sort);
}

export function getNumberings(filter, perPage, page, sort) {
  return API.getItems('site/document/numberings', filter, perPage, page, sort);
}

export function getTypeList(locale) {
  return request.post('site/document/type-list', {locale});
}

export function upload(data) {
  return request.post('site/document/upload', data);
}

export function remove(id) {
  return request.delete('site/document/delete/' + id);
}